import React, { useState } from "react";
import RequestService from "../../services/request.service";
import AuthService from "../../features/auth/auth.service";


import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import { SERVER_ } from  '../../config';



import { rol } from '../../services/rol'


import ModalReproducirVideo from './ModalReproducirVideo'

  const currentUser  = AuthService.getCurrentUser();

const FAQ = () =>{




  const [ show_ModalReproducirVideo, setModalReproducirVideo ] = useState<boolean>(false)
  const [ modulo_seleccionado, setModuloSeleccionado ] = useState<any>(null)
  const handleShow_ModalReproducirVideo = (modulo:string) => {
    setModuloSeleccionado(modulo)
    setModalReproducirVideo(true)
  }
  const handleClose_ModalReproducirVideo = () => {
    setModalReproducirVideo(false)
  }



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Preguntas Frecuentes y Capacitación de Operación del Software</h4>
                    <hr/>




                          <React.Fragment>

                            <h5>Módulos del Uso</h5>
                            <hr/>

                            <div className="row align-items-center">



                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-gears"/> Administración</h5>
                                      <p className="card-text">Gestión de la plataforma</p>
                                      <a href="./administracion" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION' ,'ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-archive"/> Reportes</h5>
                                      <p className="card-text">Reportes y Analíticas</p>
                                      <a href="./reportes_analiticas" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RECURSOS_MATERIALES'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-tags"/> Inventario</h5>
                                      <p className="card-text">Control de Inventario</p>
                                      <a href="./inventario" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-truck"/> Flotillas</h5>
                                      <p className="card-text">Control Vehicular</p>
                                      <a href="./vehiculos" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Visualizar Calendario</h5>
                                      <p className="card-text">Roll de Operación</p>
                                      <a href="./calendario_preview" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION','ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Calendario de Trabajo</h5>
                                      <p className="card-text">Visualización de la Operación</p>
                                      <a href="./calendario" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_ATENCION_CLIENTES'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-ticket"/> Tickets</h5>
                                      <p className="card-text">Gestión de Tickets de Atención</p>
                                      <a href="./tickets" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_CLIENTE','ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-ticket"/> Tickets</h5>
                                      <p className="card-text">Tickets de Atención</p>
                                      <a href="./mis_tickets" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION','ROLE_RESPONSABLE_OPERACION','ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-cubes"/>Operación</h5>
                                      <p className="card-text">Control de la Operación</p>
                                      <a href="./operacion" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


 
                      {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-list-ul"/> Tareas Diarias</h5>
                                      <p className="card-text">Listado de Tareas del Día</p>
                                      <a href="./tareas" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-list-ul"/> Mis Reportes</h5>
                                      <p className="card-text">Reporte de Tareas Asignadas </p>
                                      <a href="./mi_reporte" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 
 

                       {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION' ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-ticket"/> Tickets</h5>
                                      <p className="card-text">Gestión de Tickets de Atención</p>
                                      <a href="./mis_tickets" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                       {
                        (['ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-ticket"/> Tickets</h5>
                                      <p className="card-text">Gestión de Tickets de Atención</p>
                                      <a href="./mis_tickets" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


 
                      {
                        (['ROLE_TECNICO','ROLE_LIMPIEZA','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Mi Calendario</h5>
                                      <p className="card-text">Mis tareas asignadas</p>
                                      <a href="./mi_calendario" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 
 


 
                      {
                        (['ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar"/> Visitas Programadas </h5>
                                      <p className="card-text">Propuesta de Visitas</p>
                                      <a href="./calendario/propuesta" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 
 



                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION','ROLE_RESPONSABLE_PRESUPUESTO'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-building"/> Clientes</h5>
                                      <p className="card-text">Panel de Clientes</p>
                                      <a href="./clientes" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

 
                      {
                        (['ROLE_CLIENTE','ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-desktop"/> Panel de Cliente</h5>
                                      <p className="card-text">Mis Servicios</p>
                                      <a href="./mi_panel" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_BOUTIQUE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-shopping-basket"/> Insumos</h5>
                                      <p className="card-text">Material, artículos y Repuestos </p>
                                      <a href="./cliente/refaccionamiento" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }





                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-user-secret"/> Proveedores</h5>
                                      <p className="card-text">Gestión de Proveedores </p>
                                      <a href="./proveedores/lista" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }







                      {
                        (['ROLE_ADMIN','ROLE_RESPONSABLE_PRESUPUESTO','ROLE_RESPONSABLE_LIMPIEZA','ROLE_RESPONSABLE_TECNICOS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Presupuestos</h5>
                                      <p className="card-text">Costes de Obra y/o Servicio</p>
                                      <a href="./operacion/presupuestos" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-check-circle"/> Presupuestos</h5>
                                      <p className="card-text">Aprobación de Obra/Servicio</p>
                                      <a href="./administracion/presupuestos" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      
                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RESPONSABLE_OPERACION','RESIDENTE_OBRA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-shopping-basket"/> Requisiciones</h5>
                                      <p className="card-text">Aprobación de Requisiciones</p>
                                      <a onClick={(e:any)=>{handleShow_ModalReproducirVideo('requisiciones')}} className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }

                      {
                        (['ROLE_ADMIN','ROLE_COMPROBACIONES'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-search"/> Comprobaciones</h5>
                                      <p className="card-text">Comprobacion de egresos</p>
                                      <a href="./comprobaciones_egresos" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_COMPRAS'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-shopping-basket"/> Compras</h5>
                                      <p className="card-text">Compra con Proveedores</p>
                                      <a href="./compras" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_CONTABILIDAD'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Pago Requisiciones</h5>
                                      <p className="card-text">Depositos de egresos</p>
                                      <a href="./deposito_egresos" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_TECNICO','ROLE_CONSERVACION'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body text-white bg-primary">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Mis Presupuestos</h5>
                                      <p className="card-text">Costes de Obra y/o Servicio</p>
                                      <a href="./tecnico/presupuestos" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        ([
                          'ROLE_ADMIN', 
                          'ROLE_RESPONSABLE_TECNICOS', 
                          'ROLE_RESPONSABLE_LIMPIEZA', 
                          'ROLE_RESPONSABLE_OPERACION',
                          'ROLE_RECURSOS_HUMANOS',
                          'ROLE_CONTABILIDAD',
                          'ROLE_RESPONSABLE_PRESUPUESTO',
                          'ROLE_SISTEMAS',
                          'ROLE_RECURSOS_MATERIALES',
                          'ROLE_ATENCION_CLIENTES',
                          'ROLE_MARKETING',
                          'ROLE_RECLUTAMIENTO',
                          'ROLE_JEFE_ADMIN',
                          'ROLE_COMPROBACIONES',
                          'ROLE_COMPRAS',

                          'RESIDENTE_OBRA',
                          'AUXILIAR_OBRA',
                          // 'ROLE_OBRA',
                          'ROLE_SHEI',

                          ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-money"/> Mis Requisiciones</h5>
                                      <p className="card-text">Pagos, Viáticos, y Reembolsos</p>
                                      <a onClick={(e:any)=>{handleShow_ModalReproducirVideo('mis_requisiciones')}} className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_CONTABILIDAD'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-calendar-o"/> Pagos</h5>
                                      <p className="card-text">Programación de Pagos</p>
                                      <a href="./programacion_pagos" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Mis Presupuestos</h5>
                                      <p className="card-text">Costes de Obra y/o Servicio</p>
                                      <a href="./tecnico/presupuestos" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }





                      {
                        (['ROLE_RESPONSABLE_TECNICOS','ROLE_RESPONSABLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-eye"/> Reportes</h5>
                                      <p className="card-text">Reporte de Obra y/o Servicio</p>
                                      <a href="./reporte_servicios" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_CLIENTE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Presupuestos</h5>
                                      <p className="card-text">Costes de Obra y/o Servicio</p>
                                      <a href="./cliente/presupuestos" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_RECURSOS_HUMANOS','ROLE_RECLUTAMIENTO'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-star"/> Reporte Incidencias</h5>
                                      <p className="card-text">Reporte de la incidencias</p>
                                      <a href="./incidencias" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_CONTABILIDAD'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-money"/> Contabilidad</h5>
                                      <p className="card-text">Registro y Control de Pagos</p>
                                      <a href="./contabilidad" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_CONTABILIDAD'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-dollar"/> Cobros</h5>
                                      <p className="card-text">Registro y Control de Cobros</p>
                                      <a href="./cobro_servicios" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      



                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN','ROLE_RECURSOS_HUMANOS','ROLE_RECLUTAMIENTO'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-users"/> Recursos Humanos</h5>
                                      <p className="card-text">Gestión de Accesos y Privilegios</p>
                                      <a href="./recursos_humanos" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }




                      {
                        (['ROLE_ADMIN','ROLE_JEFE_ADMIN' ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-newspaper-o"/> Circulares</h5>
                                      <p className="card-text">Informes Corporativos</p>
                                      <a href="./circulares" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }


                      {
                        (['ROLE_BOUTIQUE','ROLE_CLIENTE'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-info">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-newspaper-o"/> Circulares</h5>
                                      <p className="card-text">Informes Corporativos</p>
                                      <a href="./circulares" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }






                      {
                        ([
                            'ROLE_RECURSOS_HUMANOS',
                            'ROLE_RECLUTAMIENTO',

                            'ROLE_RESPONSABLE_TECNICOS',
                            'ROLE_RESPONSABLE_LIMPIEZA',

                            'ROLE_RESPONSABLE_OPERACION',
                            
                            'ROLE_RESPONSABLE_PRESUPUESTO',

                              'ROLE_ADMIN',

                              'ROLE_CONTABILIDAD',

                              'ROLE_OBRA',
                              'ROLE_SISTEMAS',
                              'ROLE_RECURSOS_MATERIALES',
                              'ROLE_SHEI',
                              'ROLE_ATENCION_CLIENTES',

                              'ROLE_MARKETING',
                              'ROLE_RECLUTAMIENTO',
                              'ROLE_JEFE_ADMIN',

                              'RESIDENTE_OBRA',
                              'AUXILIAR_OBRA',
                              'ROLE_OBRA',
                              'ROLE_SHEI',

                            ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-building"/> Mapa de Clientes</h5>
                                      <p className="card-text">Clientes del Corporativo</p>
                                      <a href="./corporativo_clientes" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 





                      {
                        ([
                            'ROLE_RECURSOS_HUMANOS',
                            'ROLE_RECLUTAMIENTO',

                            'ROLE_RESPONSABLE_TECNICOS',
                            'ROLE_RESPONSABLE_LIMPIEZA',

                            'ROLE_RESPONSABLE_OPERACION',
                            
                            'ROLE_RESPONSABLE_PRESUPUESTO',

                              'ROLE_ADMIN',

                              'ROLE_CONTABILIDAD',

                              'ROLE_OBRA',
                              'ROLE_SISTEMAS',
                              'ROLE_RECURSOS_MATERIALES',
                              'ROLE_SHEI',
                              'ROLE_ATENCION_CLIENTES',

                              'ROLE_MARKETING',
                              'ROLE_RECLUTAMIENTO',
                              'ROLE_JEFE_ADMIN',

                              'RESIDENTE_OBRA',
                              'AUXILIAR_OBRA',
                              'ROLE_OBRA',
                              'ROLE_SHEI',

                            ].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-wrench"/> Herramientas</h5>
                                      <p className="card-text">Instrumentos asignados</p>
                                      <a href="./mis_herramientas" className="btn btn-primary btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }



                      {
                        (['ROLE_TECNICO','ROLE_CONSERVACION','ROLE_LIMPIEZA'].indexOf(currentUser.rol) > -1) && (


                              <div className="col-sm-3">
                                  <div className="card text-white bg-primary">
                                    <div className="card-body">
                                      <h5 className="card-title"><i className="fa fa-wrench"/> Herramientas</h5>
                                      <p className="card-text">Instrumentos asignados</p>
                                      <a href="./mis_herramientas" className="btn btn-light btn-sm">Ver <i className="fa fa-play" /></a>
                                    </div>
                                  </div>
                              </div>

                        )
                      }
 

 


 

                      


 


                            </div>

                          </React.Fragment>

                    
                </div>
              </div>

            </div>





  {
    show_ModalReproducirVideo && (
      <ModalReproducirVideo show={show_ModalReproducirVideo} handleClose={handleClose_ModalReproducirVideo} modulo={modulo_seleccionado} />
      )

  }





          </>

      );
  

}

export default FAQ
