import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { sumarMontosTotales, sumarMontosTotalesNoAprobados,  sumarMontosTotalesAprobados, tipoDeElementoRequisicion, formatearNumero, formatearFecha, formatearFechaHora, formatearFechaEstandar, obtenerUnidadMedida } from '../../services/funcionesPublicas'


import { Col, Row } from 'react-bootstrap';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Dialog from '../../services/Dialogs';


import { SERVER_ } from '../../config'


const ModalElementoCargaComprobantes = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, requisicion } = params

  const [ requisicion_detalle, setRequisicion_detalle ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarRequisicion_detalle = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`requisicion/detalles/${requisicion.uuid_requisicion}`)
      setRequisicion_detalle(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarRequisicion_detalle()
    cargarTipo_unidad_medida()
  }, []);


  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  const [ elemento_seleccionado, setElementoSeleccionado ] = useState<any>(null)


  const [ show_ModalRequisicionDetalles, setModalRequisicionDetalles ] = useState<boolean>(false)
  const handleShow_ModalRequisicionDetalles = (elemento:any) => {
    setElementoSeleccionado(elemento)
    setModalRequisicionDetalles(true)
  }
  const handleClose_ModalRequisicionDetalles = () => {
        cargarRequisicion_detalle()
    setModalRequisicionDetalles(false)
  }


  const dialog = new Dialog();

  const mensajes_validacion_generico = "Este campo es obligatorio";
  const schema = yup.object().shape({
    archivo: yup.mixed()
      .required('Es obligatorio subir un archivo')
      .test(
        'fileFormat',
        'Formato de archivo no soportado. Solo se permiten JPG y PNG.',
        value => value 
      ),
    uuid_elemento : yup.string()
  });


  const enviarFormulario = async (registro:any, event:any) => {
    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro?',
      target: event.target,
      template: ''
    });
    let dialogo = await dialog.waitForUser();
    if (dialogo) {
      try {
        setSeEnvio(true);
        let res_ = await RequestService.update(`requisicion/elemento/comprobar_gasto_previo/${registro.uuid_elemento}`, { ...registro });

        if (res_) {
          toast.success('El comprobante se subió correctamente');
          setTimeout(() => {
            cargarRequisicion_detalle();
            handleClose_ModalRequisicionDetalles();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e:any) {
        toast.error(e.response.data);
      }
    }
  };

  const handleFileChange = (event:any, setFieldValue:any) => {
    const file = event.currentTarget.files[0];
    console.log(file)
    if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'|| file.type === 'application/pdf')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue('archivo', reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFieldValue('archivo', '');
    }
  };



  const eliminarEvidencia = async (uuid_elemento:any, event:any) => {
    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro?',
      target: event.target,
      template: ''
    });
    let dialogo = await dialog.waitForUser();
    if (dialogo) {
      try {
        setSeEnvio(true);
        let res_ = await RequestService.update(`requisicion/elemento/eliminar_evidencia/${uuid_elemento}`, { });

        if (res_) {
          toast.warning('El comprobante se eliminó correctamente');
          setTimeout(() => {
            cargarRequisicion_detalle();
            handleClose_ModalRequisicionDetalles();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e:any) {
        toast.error(e.response.data);
      }
    }
  };





  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Carga de Comprobantes</Modal.Title>
        </Modal.Header>
              <div className={`text-center estatus_requisicion_${requisicion_detalle?.idestatus_requisicion?.id}`} style={{padding:'10px 0px',marginBottom:0}}>
                {
                  // JSON.stringify(requisicion_detalle.idestatus_requisicion)
                }
                      <h6 className="">Estatus</h6>
                      <small>{requisicion_detalle?.idestatus_requisicion?.estatus}</small>

              </div>


            {
              requisicion_detalle.para_compras && (
                  <div className="text-center pasara_por_compras" style={{marginTop:0}}>
                  🛍️ Esta requisición será atendida por el Departamento de Compras
                  </div>
                )
            }

        <Modal.Body>
          {
            // JSON.stringify(requisicion)
          }



                      <h6 className="mt-0">Elementos de la Requisición</h6>

                        <table className="table" style={{fontSize:10}}>
                          <thead>
                            <tr>
                              {
                                !requisicion_detalle.compra_proveedor && (<th scope="col">Factura</th>)
                              }
                              
                              <th scope="col">Tipo</th>
                              <th scope="col"></th>
                              <th scope="col">Concepto</th>
                              <th scope="col">Monto</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              requisicion_detalle?.requisiciones_listado?.map(( elemento:any, elemento_indice:number )=>
                                          <tr className={`${ [2].indexOf(elemento?.idestatus_elemento?.id) > -1 ? 'disabled-row' : '' }`} key={elemento_indice}>
                                              { 
                                                  !requisicion_detalle.compra_proveedor && (
                                                      <td className="v" style={{"width":"20px",textAlign:'center'}}>
                                                        { elemento.con_factura && <i className="fa fa-warning" />}
                                                      </td>
                                                  ) 
                                              }
                                            <td className="v" style={{"width":"100px"}}>
                                              <b>{tipoDeElementoRequisicion(elemento.tipo)}</b>
                                            </td>
                                            <td className="v" style={{"width":"50px"}}>
                                                { !elemento?.idestatus_elemento && (<>⌛</>) }
                                                { [1].indexOf(elemento?.idestatus_elemento?.id) > -1 && (<>✅</>) }
                                                { [2].indexOf(elemento?.idestatus_elemento?.id) > -1 && (<>❌</>) }
                                            </td>
                                            <td className="v" style={{'wordBreak': 'break-word'}}>
                                              {elemento.concepto} {' '}
                                              {
                                                !elemento?.idestatus_elemento?.id && (<i className="fa fa-clock-o" />) 
                                              }

                                              {
                                                // JSON.stringify(elemento?.meta_data.detalles_deposito)
                                              }

                                              {
                                                elemento?.meta_data?.detalles_deposito && (
                                                    <>
                                                      <p className="nm"><small>Detalles</small></p>
                                                      <p className="nm"><b>{elemento?.meta_data?.detalles_deposito}</b></p>
                                                    </>
                                                  )
                                              }

                                              {
                                                [5].indexOf(elemento?.idestatus_elemento?.id) > -1 && (
                                                    <>
                                                      ✅
                                                    </>
                                                  )
                                              }



                                              {
                                                elemento.idegreso && (
                                                    <>
                                                    <br/>
                                                      <small>Ćomprobación de Depósito</small>
                                                      <p className="nm"><button onClick={()=>{ window.open(`${SERVER_}contabilidad/archivo/${elemento?.idegreso?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir</button></p>
                                                    </>
                                                  )
                                              }


                                               {
                                                 [5].indexOf(elemento?.idestatus_elemento?.id) > -1 && (
                                                     <>
                                                       <small>Ćomprobación de Evidencia del Gasto</small>
                                                       <p className=""><button onClick={()=>{ window.open(`${SERVER_}comprobaciones/evidencia/archivo/${elemento?.uuid}`) }} className="btn btn-sm btn-link nm">Abrir</button></p>

                                                       <small>Monto Comprobado</small>
                                                       <h6 className="">$ {formatearNumero(elemento?.monto_comprobado)}</h6>

                                                       <p className="nm">
                                                         <i className="fa fa-check-square" /> Comprobado
                                                       </p>
                                                     </>
                                                   )
                                               }


                                                {
                                                  elemento.documento_compra !== null && (
                                                        <>
                                                        <p className="nm">
                                                            <small className="pointer" onClick={()=>{ window.open(`${SERVER_}compra/evidencia/archivo/${elemento?.uuid}`) }}> ⭐ Ćomprobación de Evidencia de la compra</small> 
                                                            </p>
                                                        </>
                                                    )
                                                }


                                            </td>

                                            
                                                  <td className="v" style={{"width":"80px"}}>$ {formatearNumero((elemento.monto_total*1))}</td>


                                                    <td className="v text-right" style={{"width":"40px"}}>

                                                        {
                                                          elemento.documento_evidencia !== null && (
                                                                          <>
                                                                             <button onClick={()=>{ window.open(`${SERVER_}comprobaciones/evidencia/archivo/${elemento?.uuid}`) }} className="btn btn-sm btn-link nm">Evidencia</button>
                                                                          </>
                                                                      )
                                                        }

                                                        {
                                                          elemento.documento_evidencia === null && (
                                                                          <button type="button" onClick={(e:any)=>{handleShow_ModalRequisicionDetalles(elemento)}} className="btn btn-sm btn-warning"><i className="fa fa-upload"/></button>
                                                                      )
                                                        }
                                                        
                                                    </td>
                                                    <td className="v text-left" style={{"width":"5px"}}>
                                                        {
                                                          elemento.documento_evidencia !== null && (
                                                                          <>
                                                                             <button onClick={(e:any)=>{eliminarEvidencia(elemento?.uuid,e)}} className="btn btn-xs btn-link nm"><i className="fa fa-times"/></button>
                                                                          </>
                                                                      )
                                                        }
                                                                             
                                                    </td>


                                                  
                                            
                                          </tr>
                                )
                            }



                          </tbody>
                        </table>



        </Modal.Body>
      </Modal>







            {
              show_ModalRequisicionDetalles && (

                    <Modal show={show_ModalRequisicionDetalles} onHide={handleClose_ModalRequisicionDetalles}>
                      <Modal.Header closeButton>
                        <Modal.Title>Carga de Comprobante</Modal.Title>
                      </Modal.Header>

                      <Modal.Body>

                        <p className="nm"><b>Concepto</b></p>
                        <p className="">{elemento_seleccionado.concepto}</p>

                        <p className="nm"><b>Concepto</b></p>
                        <p className="">$ {formatearNumero((elemento_seleccionado.monto_total*1))}</p>

                          {
                            // JSON.stringify(elemento_seleccionado)
                          }



                      {
                        elemento_seleccionado.documento_evidencia === null && (
                        <>
                          <div className="card mt-4">
                            <div className="card-body">
                                <Formik
                                  validationSchema={schema}
                                  onSubmit={(values, e) => {
                                    enviarFormulario(values, e);
                                  }}
                                  initialValues={{
                                    archivo: '',
                                    uuid_elemento: elemento_seleccionado.uuid,
                                  }}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                  }) => (
                                    <Form id={`my-form`} noValidate onSubmit={handleSubmit}>
                                      <div className="form-group">
                                        <label htmlFor={`archivo`}>
                                          Subir archivo (JPG, PNG o PDF)
                                        </label>
                                        <input
                                          id={`archivo`}
                                          name="archivo"
                                          type="file"
                                          className={`form-control ${
                                            touched.archivo && errors.archivo ? 'is-invalid' : ''
                                          }`}
                                          onChange={(event:any) => handleFileChange(event, setFieldValue)}
                                          accept=".jpg,.jpeg,.png,.pdf"
                                        />
                                        <ErrorMessage name="archivo" component="div" className="invalid-feedback" />
                                      </div>

                                      <Button
                                        variant="success"
                                        className="mt-2"
                                        form={`my-form`}
                                        type="submit"
                                      >
                                        Guardar <i className="fa fa-save" />
                                      </Button>
                                    </Form>
                                  )}
                                </Formik>
                            </div>
                          </div>
                        </>
                      )
                    }

                      </Modal.Body> 
                    </Modal>

                )

            }





    </>
  );
}


export default ModalElementoCargaComprobantes;