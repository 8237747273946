import React, { useEffect, useState, useRef } from 'react';

import RequestService from "../../services/request.service";

import {Button, Modal, Row, Col, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';

import Select from 'react-select'


import Dialog from '../../services/Dialogs'


import L from 'leaflet';  // Importa la librería Leaflet
import 'leaflet/dist/leaflet.css';  // Asegúrate de importar los estilos de Leaflet



import {generarNombreAleatorio,generarNombreEmpresa,obtenerCadenaAleatoria} from '../../services/package_funciones_ayuda'

import municipiosListType from '../../interfaces/municipiosListType'

import '../../modales.scss'

const ModalEditarBoutique = ( params : any ) => {

 
  const { show, handleClose, boutique } = params

  const dialog = new Dialog();


  const cargarMunicipios = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todos_los_municipios')
      setEtiquetas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar todos_los_municipios')
    }
  }




  const [ subareas, setSubAreas ] = useState<any>([]);
  const cargarAreas = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todas_las_subareas')
      setSubAreas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar todos_los_municipios')
    }
  }
  



      const customIcon = L.icon({ iconUrl: '/img/marker.png' });


  const mapContainer = useRef<any>(null);  // Contenedor del mapa
  const [map, setMap] = useState<any>(null); // Estado para manejar el mapa
  const [marker, setMarker] = useState<any>(null);  // Estado para manejar el marcador

  useEffect(() => {
    // Inicializar el mapa
    if (mapContainer.current) {
      const initializedMap : any = L.map(mapContainer.current).setView([boutique.localizacion?.x,boutique.localizacion?.y], 13);  // Coordenadas iniciales del mapa
      
      // Añadir la capa del mapa
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(initializedMap);
      
      // Crear el marcador inicial en las coordenadas
      const initialMarker = L.marker([boutique.localizacion?.x,boutique.localizacion?.y],{ icon : customIcon }).addTo(initializedMap)
        // .bindPopup('<b>Marcador Inicial</b>')
        // .openPopup();

      // Guardar el mapa y el marcador en el estado
      setMap(initializedMap);
      setMarker(initialMarker);
    }
  }, []);

   const handleUpdateLocation = async () => {
    try {
      // Obtener el texto del portapapeles
      const clipboardText = await navigator.clipboard.readText();
      
      // Verificar que el texto tenga el formato adecuado
      const coordinates = clipboardText.split(',').map(coord => parseFloat(coord.trim()));

      if (coordinates.length === 2 && !isNaN(coordinates[0]) && !isNaN(coordinates[1])) {
        const [latitude, longitude] = coordinates;

        if (map && marker) {
          // Actualizar el mapa
          map.setView([latitude, longitude], 13);

          // Actualizar la posición del marcador
          marker.setLatLng([latitude, longitude]);
        }
      } else {
        toast.error('El formato de las coordenadas no es válido');
      }
    } catch (error) {
      console.error('Error al leer el portapapeles:', error);
      toast.error('No se pudo acceder al portapapeles');
    }
  };




  React.useEffect(() => {
    cargarAreas()
    cargarMunicipios()






  }, []);


  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>({"value":boutique.idmunicipio.id,"label":`${boutique.idmunicipio.municipio} - ${boutique.idestado.estado} `,"color":"#FFFFFF"})
  const [ etiquetasOptions, setEtiquetas ] = useState<municipiosListType[]>([]);




  const mensajes_validacion_generico = "Campo es obligatorio"
  const schema = yup.object().shape({

        acronimo_folio : yup.string().required(mensajes_validacion_generico),
        nombre_boutique : yup.string().required(mensajes_validacion_generico),
        // rfc : yup.string().required(mensajes_validacion_generico),
        // razon_social : yup.string().required(mensajes_validacion_generico),
        direccion : yup.string().required(mensajes_validacion_generico),
        cp : yup.string().required(mensajes_validacion_generico),
        telefono : yup.string().required(mensajes_validacion_generico),
        email : yup.string().required(mensajes_validacion_generico),
        nombre_contacto : yup.string().required(mensajes_validacion_generico),
        telefono_contacto : yup.string().required(mensajes_validacion_generico),
        idmunicipio : yup.number().required(mensajes_validacion_generico),

        activa : yup.boolean(),

        idsubarea : yup.string().required(mensajes_validacion_generico),
        numero_visitas : yup.number().required(mensajes_validacion_generico),

        servicio_conservacion : yup.boolean(),
        servicio_mantenimiento : yup.boolean(),
        servicio_limpieza : yup.boolean(),

        monto_iguala_mantenimiento: yup.number().when('servicio_mantenimiento', {
          is: true,
          then: yup.number().required(mensajes_validacion_generico),
          otherwise: yup.number(),
        }),

        monto_iguala_conservacion: yup.number().when('servicio_conservacion', {
          is: true,
          then: yup.number().required(mensajes_validacion_generico),
          otherwise: yup.number(),
        }),

        monto_iguala_limpieza: yup.number().when('servicio_limpieza', {
          is: true,
          then: yup.number().required(mensajes_validacion_generico),
          otherwise: yup.number(),
        }),


  });






const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: `
            <h6 class="text-center">Confirmar información</h6>
          <small>Nombre de la Boutique</small>
          <p class="nm">${registro.nombre_boutique} </p>

        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

          console.log(marker.getLatLng())

        setSeEnvio(true)
        let res_ = await RequestService.update( `boutiques/editar/${boutique.uuid}` , { ...registro, localizacion : { lat : marker.getLatLng().lat, lng : marker.getLatLng().lng }})

        if(res_){
          toast.success('La Boutique se agregó correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}






  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
              <div>
                <img className="middle" src={`../img/iconos/editar.png`} alt="link" width="32" height="32" />
                  {' '} Editar la información de la Boutique
              </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={
        {

            acronimo_folio : boutique.acronimo_folio,
            nombre_boutique : boutique.nombre_boutique,
            idmunicipio : boutique.idmunicipio.id,
            // rfc : boutique.rfc,
            // razon_social : generarNombreEmpresa(),
            direccion : boutique.direccion,
            cp : boutique.cp,
            telefono : boutique.telefono,
            email : boutique.email,
            nombre_contacto : boutique.nombre_contacto,
            telefono_contacto : boutique.telefono_contacto,

            activa : boutique.activa,

            idsubarea : boutique.idsubarea.uuid, 
            numero_visitas : boutique.numero_visitas, 

            servicio_conservacion : boutique.servicio_conservacion,
            servicio_mantenimiento : boutique.servicio_mantenimiento,
            servicio_limpieza : boutique.servicio_limpieza,


            monto_iguala_mantenimiento : boutique.monto_iguala_mantenimiento,
            monto_iguala_conservacion : boutique.monto_iguala_conservacion,
            monto_iguala_limpieza : boutique.monto_iguala_limpieza,

      }

// {"nombre_boutique":"MAXI CAMPECHE STORE CENTRO","idmunicipio":2325,"rfc":"GOMA010101HDFXXX01","razon_social":"MAXOS TOAS ASIOPD JAS","direccion":"DOMICILIO CONOCIDO","cp":"24400","telefono":"987654321","email":"boutique@gmail.com","nombre_contacto":"VICTOR MARTINEZ","telefono_contacto":"123123123132"}

    }
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>



{
  // JSON.stringify(boutique.idmunicipio)
}

{
  // JSON.stringify(boutique.idestado)
}


          <Row>

            <Col md="3" sm="3">

              <small>Boutique</small>

              <h4>{boutique.nombre_boutique}</h4>


            </Col>

              <Col md="3" sm="3">
                <small className="nm">Servicios</small>
                  <Form.Check type="switch" id="mantenimineto" label="Servicio de Mantenimiento" checked={values.servicio_mantenimiento} onChange={(e:any)=>{setFieldValue('servicio_mantenimiento',e.target.checked)}} />
                  <Form.Group className="mb-3" controlId="monto_iguala_mantenimiento">
                    <Form.Label>Monto Iguala</Form.Label>
                    <Form.Control
                      required
                      disabled={!values.servicio_mantenimiento}
                      name="monto_iguala_mantenimiento"
                      value={values.monto_iguala_mantenimiento}
                      onChange={(e)=>{ setFieldValue('monto_iguala_mantenimiento', e.target.value ) }}
                      isInvalid={!!errors.monto_iguala_mantenimiento}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.monto_iguala_mantenimiento}</>} </Form.Control.Feedback>
                  </Form.Group>
              </Col>
              <Col md="3" sm="3">
                <br/>
                <Form.Check type="switch" id="Conservación" label="Servicio de Conservación" checked={values.servicio_conservacion} onChange={(e:any)=>{setFieldValue('servicio_conservacion',e.target.checked)}} />
                  <Form.Group className="mb-3" controlId="monto_iguala_conservacion">
                    <Form.Label>Monto Iguala</Form.Label>
                    <Form.Control
                      required
                      disabled={!values.servicio_conservacion}
                      name="monto_iguala_conservacion"
                      value={values.monto_iguala_conservacion}
                      onChange={(e)=>{ setFieldValue('monto_iguala_conservacion', e.target.value ) }}
                      isInvalid={!!errors.monto_iguala_conservacion}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.monto_iguala_conservacion}</>} </Form.Control.Feedback>
                  </Form.Group>
              </Col>
              <Col md="3" sm="3">
                <br/>
                <Form.Check type="switch" id="limpieza" label="Servicio de Limpieza" checked={values.servicio_limpieza} onChange={(e:any)=>{setFieldValue('servicio_limpieza',e.target.checked)}} />
                    <Form.Group className="mb-3" controlId="monto_iguala_limpieza">
                      <Form.Label>Monto Iguala</Form.Label>
                      <Form.Control
                        required
                        disabled={!values.servicio_limpieza}
                        name="monto_iguala_limpieza"
                        value={values.monto_iguala_limpieza}
                        onChange={(e)=>{ setFieldValue('monto_iguala_limpieza', e.target.value ) }}
                        isInvalid={!!errors.monto_iguala_limpieza}
                      />
                      <Form.Control.Feedback type="invalid"> {<>{errors.monto_iguala_limpieza}</>} </Form.Control.Feedback>
                    </Form.Group>
              </Col>
          </Row>


          <Row>

            <Col md="6" sm="6">




          {
            JSON.stringify(boutique.localizacion)
          }





            <div className="row">
              <div className="col-sm">




              <Form.Group  className="mb-3" controlId="idsubarea">
                <Form.Label>Sub Zona</Form.Label>
                <Form.Select required value={values.idsubarea} onChange={(e)=>{ setFieldValue('idsubarea',e.target.value) }} isInvalid={!!errors.idsubarea} >
                  <option>Selecciona un tipo</option>
                      {
                        subareas.map((subarea:any,index_subareas:number)=>
                            <option key={index_subareas} value={subarea.uuid}>{subarea.subarea}</option>
                          )
                      }
                </Form.Select>
                <Form.Control.Feedback type="invalid">{<>{errors.idsubarea}</>} </Form.Control.Feedback>
              </Form.Group>





              </div>
              <div className="col-sm-4">

                  <Form.Group   className="mb-3" controlId="numero_visitas">
                    <Form.Label>Número de Visitas</Form.Label>
                    <Form.Control
                      required
                      name="numero_visitas"
                      value={values.numero_visitas}
                      onChange={(e)=>{ setFieldValue('numero_visitas', e.target.value ) }}
                      isInvalid={!!errors.numero_visitas}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.numero_visitas}</>} </Form.Control.Feedback>
                  </Form.Group>

              </div>
            </div>




            <div className="row">
              <div className="col-sm-8">



                  <Form.Group className="mb-3" controlId="nombre_boutique">
                    <Form.Label>Nombre de la Boutique</Form.Label>
                    <Form.Control
                      required
                      name="nombre_boutique"
                      value={values.nombre_boutique}
                      onChange={(e)=>{ setFieldValue('nombre_boutique', (e.target.value || "").toUpperCase() ) }}
                      isInvalid={!!errors.nombre_boutique}
                    />
                  </Form.Group>


              </div>
              <div className="col-sm-4">

                  <Form.Group className="mb-3" controlId="acronimo_folio">
                    <Form.Label>Acrónimo para Folios</Form.Label>
                    <Form.Control
                      required
                      name="acronimo_folio"
                      value={values.acronimo_folio}
                      onChange={(e)=>{ setFieldValue('acronimo_folio', (e.target.value || "").toUpperCase() ) }}
                      isInvalid={!!errors.acronimo_folio}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.acronimo_folio}</>} </Form.Control.Feedback>
                  </Form.Group>

              </div>
            </div>






            <Form.Group   className="mb-3" controlId="direccion">
              <Form.Label>Dirección de las Boutique</Form.Label>
              <Form.Control
                as="textarea"
                required
                rows={5}
                name="direccion"
                value={values.direccion}
                onChange={(e)=>{ setFieldValue('direccion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.direccion}
              />
            </Form.Group>







            <div className="row">
              <div className="col-sm-8">

{
  // JSON.stringify(etiquetas_seleccionadas)
}

                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Municipio</Form.Label>

                            <Select
                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt); setFieldValue('idmunicipio',opt.value) }}
                              isClearable={false}
                              name="colors"
                              defaultValue={etiquetas_seleccionadas||'Seleccionar'}
                              options={etiquetasOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>



              </div>
              <div className="col-sm-4">

                    <Form.Group className="mb-3" controlId="cp">
                      <Form.Label>Código Postal</Form.Label>
                      <Form.Control
                        required
                        name="cp"
                        value={values.cp}
                        onChange={(e)=>{ setFieldValue('cp', (e.target.value) ) }}
                        isInvalid={!!errors.cp}
                      />
                    </Form.Group>

              </div>
            </div>





            <div className="row">
              <div className="col-sm-6">
                <Form.Group   className="mb-3" controlId="telefono">
                  <Form.Label>Teléfono de Oficinas</Form.Label>
                  <Form.Control
                    required
                    name="telefono"
                    value={values.telefono}
                    onChange={(e)=>{ setFieldValue('telefono', (e.target.value) ) }}
                    isInvalid={!!errors.telefono}
                  />
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group   className="mb-3" controlId="telefono">
                  <Form.Label>Correo Electrónico</Form.Label>
                  <Form.Control
                    required
                    name="email"
                    value={values.email}
                    onChange={(e)=>{ setFieldValue('email', (e.target.value) ) }}
                    isInvalid={!!errors.email}
                  />
                </Form.Group>
              </div>
            </div>





            <Form.Group   className="mb-3" controlId="nombre_contacto">
              <Form.Label>Nombre del Contacto con la Boutique</Form.Label>
              <Form.Control
                required
                name="nombre_contacto"
                value={values.nombre_contacto}
                onChange={(e)=>{ setFieldValue('nombre_contacto', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.nombre_contacto}
              />
            </Form.Group>




            <Form.Group   className="mb-3" controlId="telefono_contacto">
              <Form.Label>Teléfono del Contacto</Form.Label>
              <Form.Control
                required
                name="telefono_contacto"
                value={values.telefono_contacto}
                onChange={(e)=>{ setFieldValue('telefono_contacto', (e.target.value) ) }}
                isInvalid={!!errors.telefono_contacto}
              />
            </Form.Group>







            </Col>
            <Col md="6" sm="6">

                        <Col >
                          <small className="nm">Posibilidad de Servicio</small>
                          <Form.Check type="switch" id="activa" label="Boutique Activa para Servicios" checked={values.activa} onChange={(e:any)=>{setFieldValue('activa',e.target.checked)}} />
                        </Col>
                
                <h6>Localización de la Boutique</h6>


                <div ref={mapContainer} style={{ height: '500px', width: '100%' }} ></div>

      <button className="btn btn-link" type="button" onClick={handleUpdateLocation}>
        Pegar Localización
      </button>


            </Col>

          </Row>


        </Form>
      )}
    </Formik>






        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarBoutique;