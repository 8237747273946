import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { rol } from '../../services/rol'

import { sumarMontosTotales, sumarMontosTotalesNoAprobados,  sumarMontosTotalesAprobados, tipoDeElementoRequisicion, formatearNumero, formatearFecha, formatearFechaHora, formatearFechaEstandar, obtenerUnidadMedida } from '../../services/funcionesPublicas'


import { SERVER_ } from '../../config'

import { Col, Row } from 'react-bootstrap'

// import { Formik, Field, ErrorMessage } from 'formik';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import Dialog from '../../services/Dialogs'

import moment from 'moment'



import { FormikHelpers } from 'formik';

interface FileUploadFormProps {
  onSubmit: (values: { documento_compra: File | null }, formikHelpers: FormikHelpers<{ documento_compra: File | null }>) => void;
  index : number
}



const ModalComprasFinalizar = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, requisicion } = params


  const [ requisicion_detalle, setRequisicion_detalle ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarRequisicion_detalle = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`requisicion/detalles/${requisicion.uuid_requisicion}`)
      setRequisicion_detalle(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarRequisicion_detalle()
  }, []);



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
      fecha_pago : yup.string().required(mensajes_validacion_generico),
  });


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `requisiciones/finalizar_compras/${requisicion.uuid_requisicion}` , { fecha_pago : moment(registro.fecha_pago).format('YYYY-MM-DD') })

          if(res_){
            toast.success('La fase de compras finalizó')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }




// const validationSchema = yup.object({
//   documento_compra: yup.mixed()
//     .required('Este campo es obligatorio')
//     .test('fileFormat', 'Formato no soportado', (value:any) => {
//       return value && ['image/png', 'image/jpeg', 'application/pdf'].includes(value.type);
//     }),
// });



// const FileUploadForm: React.FC<FileUploadFormProps> = ({ onSubmit, index } : any) => (
//   <Formik
//     initialValues={{ documento_compra: null }}
//     validationSchema={validationSchema}
//     onSubmit={onSubmit}
//   >
//     {({ setFieldValue }) => (
//       <Form id={`my-form-${index}`} noValidate>
//         <div className="form-group">
//           <label htmlFor="documento_compra">Subir documento (PNG, JPG, PDF)</label>
//           <input
//             id="documento_compra"
//             name="documento_compra"
//             type="file"
//             className="form-control"
//             accept=".jpeg,.jpg,.png,.pdf"
//             onChange={(event) => {
//               setFieldValue('documento_compra', event.currentTarget.files?.[0] || null);
//             }}
//           />
//           <ErrorMessage name="documento_compra" component="div" className="text-danger" />
//         </div>
//         <button type="submit"  className="btn btn-primary">Enviar</button>
//       </Form>
//     )}
//   </Formik>
// );


//   const enviarArchivo = async (values: { documento_compra: File | null }, formikHelpers: any) => {
//     const formData = new FormData();
//     formData.append('documento_compra', values.documento_compra as File);

//     try {
//       const response = await fetch('/tu-endpoint-en-sails', {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Error al subir el archivo');
//       }

//       const data = await response.json();
//       console.log('Respuesta del servidor:', data);

//       // Aquí podrías manejar lo que quieras hacer con la respuesta

//     } catch (error) {
//       console.error('Error:', error);
//       // Manejar el error, por ejemplo, mostrando un mensaje en la UI
//     } finally {
//       formikHelpers.setSubmitting(false);
//     }
//   };


  // const mensajes_validacion_generico = "Este campo es obligatorio";
  const schema_file = yup.object().shape({
    archivo: yup.mixed()
      .required('Es obligatorio subir un archivo')
      .test(
        'fileFormat',
        'Formato de archivo no soportado. Solo se permiten JPG y PNG.',
        value => value 
      ),
    uuid_elemento : yup.string()
  });

  const handleFileChange = (event:any, setFieldValue:any) => {
    const file = event.currentTarget.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png'|| file.type === "application/pdf")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue('archivo', reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setFieldValue('archivo', '');
    }
  };

  // const [envio_server, setSeEnvio] = useState(false);

  const enviarFormularioPruebaCompra = async (registro:any, uuid_elemento:string, event:any) => {
    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro?',
      target: event.target,
      template: ''
    });
    let dialogo = await dialog.waitForUser();
    if (dialogo) {
      try {
        setSeEnvio(true);
        let res_ = await RequestService.update(`requisicion/elemento/comprobar_compra/${uuid_elemento}`, { ...registro });

        if (res_) {
          toast.success('MENSAJE');
          setTimeout(() => {
            cargarRequisicion_detalle();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e:any) {
        toast.error(e.response.data);
      }
    }
  };


  const eliminarPruebaCompra = async (uuid_elemento:string, event:any) => {
    await dialog.open({
      accept: 'Si',
      cancel: 'Espera',
      dialogClass: '',
      message: '¿Estás seguro?',
      target: event.target,
      template: ''
    });
    let dialogo = await dialog.waitForUser();
    if (dialogo) {
      try {
        setSeEnvio(true);
        let res_ = await RequestService.delete(`compra/evidencia/archivo/${uuid_elemento}`);

        if (res_) {
          toast.warning('El comprobante fué eliminado');
          setTimeout(() => {
            cargarRequisicion_detalle();
          }, 0);
        } else {
          toast.error('Problemas...');
        }
      } catch (e:any) {
        toast.error(e.response.data);
      }
    }
  };


  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Finalizar Compra de Requisición</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }


            <h6 className="">Descripción de la Requisición</h6>
            <small>{requisicion_detalle.descripcion}</small>

            <div className="row mt-2">
              <div className="col-4">
                  <h6>Folio</h6>
                  <small>{requisicion_detalle.folio}</small>
              </div>
              <div className="col-4">
                  <h6 className="mt-3-">Fecha Límite</h6>
                  <small>{formatearFecha(requisicion_detalle.fecha_limite??new Date())}</small>
              </div>
              <div className="col-4">
                  <h6 className="mt-3-">Fecha de Creación</h6>
                  <small>{formatearFecha(requisicion_detalle.fecha_creacion??new Date())}</small>
              </div>
            </div>


 


















                      <h6 className="mt-3">Elementos de la Requisición</h6>

                        <table className="table" style={{fontSize:12}}>
                          <thead>
                            <tr>
                              {
                                // !requisicion_detalle.compra_true && (<th scope="col">Factura</th>)
                              }
                              
                              {/*<th scope="col">Tipo</th>*/}
                              <th scope="col">Concepto</th>
                              <th scope="col">Cantidad</th>
                              <th scope="col">Unidad Medida</th>
                              <th scope="col">Monto</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              requisicion_detalle?.requisiciones_listado?.map(( elemento:any, elemento_requisicion_indice:number )=>
                                          <tr className={`${ [2].indexOf(elemento?.idestatus_elemento?.id) > -1 ? 'hide-row' : '' }`} key={elemento_requisicion_indice}>
{/*                                            <td className="v" style={{"width":"100px"}}>
                                              <b>{tipoDeElementoRequisicion(elemento.tipo)}</b>
                                            </td>*/}
                                            <td className="v" style={{'wordBreak': 'break-word'}}>

                                              { 
                                                  !requisicion_detalle.compra_true && (
                                                      <p className="nm">
                                                        { elemento.con_factura && (<><i className="fa fa-warning" /> DEBE FACTURARSE  </>)}
                                                      </p>
                                                  ) 
                                              }

                                              {elemento.concepto} {' '}
                                              {
                                                !elemento?.idestatus_elemento?.id && (<i className="fa fa-clock-o" />) 
                                              }

                                              {
                                                // JSON.stringify(elemento)
                                              }

                                              {
                                                elemento?.meta_data?.detalles_deposito && (
                                                    <>
                                                      <p className="nm"><small>Detalles</small></p>
                                                      <p className="nm"><b>{elemento?.meta_data?.detalles_deposito}</b></p>
                                                    </>
                                                  )
                                              }



                                                {
                                                  elemento.documento_compra !== null && (
                                                        <>
                                                        <p className="nm">
                                                            <small className="pointer" onClick={()=>{ window.open(`${SERVER_}compra/evidencia/archivo/${elemento?.uuid}`) }}>Ćomprobación de Evidencia de la compra</small> 
                                                            <button onClick={(e:any)=>{eliminarPruebaCompra(elemento?.uuid,e)}} className="btn btn-sm btn-link nm"><i className="fa fa-trash-o"/></button>
                                                            </p>
                                                        </>
                                                    )
                                                }


                                                {
                                                  elemento.documento_compra === null && (
                                                    <>
                                                      <div className="alert alert-warning mt-1" style={{margin:0}}>

                                                          <Formik
                                                            validationSchema={schema_file}
                                                            onSubmit={(values, e) => {
                                                              enviarFormularioPruebaCompra(values, elemento.uuid , e);
                                                            }}
                                                            initialValues={{
                                                              archivo: '',
                                                              uuid_elemento: elemento.uuid,
                                                            }}
                                                          >
                                                            {({
                                                              handleSubmit,
                                                              handleChange,
                                                              setFieldValue,
                                                              handleBlur,
                                                              values,
                                                              touched,
                                                              isValid,
                                                              errors,
                                                            }) => (
                                                              <Form id={`my-form-${elemento_requisicion_indice}`} noValidate onSubmit={handleSubmit}>
                                                                <div className="form-group">
                                                                  <label htmlFor={`archivo-${elemento_requisicion_indice}`}>
                                                                    Subir archivo (JPG, PNG o PDF)
                                                                  </label>
                                                                  <input
                                                                    id={`archivo-${elemento_requisicion_indice}`}
                                                                    name="archivo"
                                                                    type="file"
                                                                    className={`form-control-sm form-control ${
                                                                      touched.archivo && errors.archivo ? 'is-invalid' : ''
                                                                    }`}
                                                                    onChange={(event:any) => handleFileChange(event, setFieldValue)}
                                                                    accept=".jpg,.jpeg,.png,.pdf"
                                                                  />
                                                                  <ErrorMessage name="archivo" component="div" className="invalid-feedback" />
                                                                </div>

                                                                <Button
                                                                  variant="warning"
                                                                  className="mt-2 mb-0 btn-sm"
                                                                  form={`my-form-${elemento_requisicion_indice}`}
                                                                  type="submit"
                                                                >
                                                                  Subir evidencia de compra <i className="fa fa-save" />
                                                                </Button>
                                                              </Form>
                                                            )}
                                                          </Formik>

                                                      </div>

                                                    </>
                                                )
                                            }




                                            </td>

                                            { 
                                              ["pago","viaticos","reembolso"].indexOf(elemento.tipo) > -1 && (
                                                <>
                                                  <td className="v text-center" style={{"width":"60px"}}><small>N/A</small></td>
                                                  <td className="v text-center" style={{"width":"120px"}}><small>N/A</small></td>
                                                </>
                                              ) 
                                            }

                                            { 
                                              ["compra"].indexOf(elemento.tipo) > -1 && (
                                                <>
                                                  <td className="v text-center" style={{"width":"60px"}}>{elemento.cantidad}</td>
                                                  <td className="v text-center" style={{"width":"120px"}}>
                                                    {
                                                      // JSON.stringify(elemento)
                                                    }
                                                    {
                                                      // obtenerUnidadMedida(tipo_unidad_medida,parseInt(elemento.idunidad_medida))
                                                    }
                                                    {elemento.idunidad_medida.unidad_medida}
                                                  </td>
                                                </>
                                              ) 
                                            }
                                            
                                                  <td className="v" style={{"width":"80px"}}>$ {formatearNumero((elemento.monto_total*1))}</td>
                                            
                                          </tr>
                                )
                            }

                            <tr>
                              {
                                !true && (<td></td>)
                              }
                              <td className="v text-right" colSpan={3}>
                                <h6 className="nm"><b>Monto Total del Gasto</b></h6>
                              </td>
                              <td className="v">
                                $ { requisicion_detalle?.requisiciones_listado ?  formatearNumero(sumarMontosTotalesAprobados(requisicion_detalle?.requisiciones_listado)) : 0 }
                              </td>
                            </tr>

                          </tbody>
                        </table>




























    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{
            fecha_pago : new Date(),
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

{/*            <div className="row mt-3">
              <div className="col-sm-6">
                  <div className="mb-3">
                    <small>Fecha de Pago a true</small>
                    <DatePicker selected={values.fecha_pago} className="form-control"  dateFormat="yyyy-MM-dd" onChange={(date) => setFieldValue('fecha_pago', date ) } />
                  </div>
              </div>
            </div>*/}

        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar Ventana
          </Button>
          <Button variant="success" form='my-form' type="submit">Finalizar Compra <i className="fa fa-save"/></Button>
        </Modal.Footer>

      </Modal>
    </>
  );
}


export default ModalComprasFinalizar;