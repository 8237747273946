import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Form, Button, Modal, DropdownButton, Dropdown, Badge } from 'react-bootstrap';

import { toast } from 'react-toastify'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import Select from 'react-select'

import Dialog from '../../services/Dialogs'

import moment from 'moment'


import { ordenarFechas } from '../../services/funcionesPublicas'


const ModalNuevaActividadLibre = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          indicaciones : yup.string().required(mensajes_validacion_generico),
          // fecha_programada : yup.string().required(mensajes_validacion_generico),
          idboutique : yup.string().required(mensajes_validacion_generico).nullable(),
          idresponsable : yup.string(), //.required(mensajes_validacion_generico),
          idtecnico : yup.string().required(mensajes_validacion_generico),
          idtipo_orden : yup.string().required(mensajes_validacion_generico),

  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const buscarPorUuid = (uuid:any, data:any) => {
    const categorias = ['tecnicos', 'conservacion', 'limpieza'];

    for (let categoria of categorias) {
        const encontrado = data[categoria].find((item:any) => item.uuid === uuid);
        if (encontrado) {
            return encontrado;
        }
    }

    return null; // Si no se encuentra el uuid en ningún arreglo
}

const buscarTipoOrden = (id:any, data:any) => {
    const encontrado = data.find((item:any) => item.id === id);
    return encontrado ? encontrado.tipo_orden : "ACTIVIDAD ?";
}

  const enviarFormulario = async (registro:any,event:any) =>{

    if(selectedDates.length === 0){
      toast.error('No podemos crear visitas, debes de seleccionar al menos una fecha')
      return
    }
    // console.log(tipo_orden_trabajo)
    const quien_atiende = buscarPorUuid(registro.idtecnico,lista_quien_atiende)
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: `
                      <small>
                        La visita será asignada a 
                        <b>${quien_atiende.nombres}</b> por 
                        <b>${buscarTipoOrden(parseInt(registro.idtipo_orden), tipo_orden_trabajo)}</b> 
                        para el día 
                        <dl class="nm">
                          <dt>Fecha Programada:</dt>
                          <dd>
                            ${selectedDates.length === 1 ? (
                              moment(selectedDates[0]).format('DD/MMM/YYYY').toUpperCase().replace('.', '')
                            ) : (
                              `<ul>
                                ${ordenarFechas(selectedDates).map((date:any, index:number) => (
                                  `<li key=${index}>${moment(date).format('dddd, DD/MMM/YYYY').toUpperCase().replace('.', '')}</li>`
                                )).join('')}
                              </ul>`
                            )}
                          </dd>
                        </dl>
                      </small>
                      <div class="alert alert-warning nm" role="alert">
                        <p class="nm"><small>A todas las visitas se asignarán la misma descripción, responsable y quién atiende</small></p>
                      </div>
                    `
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.create( `calendario/nueva_actividad_libre` , { visita : {...registro}, fechas_visita : ordenarFechas(selectedDates) })

          if(res_){
            toast.success('La actividad fue asignada correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            // toast.error(!e.response ? 'Problemas.....' : JSON.parse(e.response))
            toast.error(`No podemos crear la${selectedDates.length>0?'s':''} visita${selectedDates.length>0?'s':''}`)
            // console.log(e.response.data)
          } 
          
        }
       
  }




  React.useEffect(() => {
    cargarLista_responsables()
    cargarLista_tecnicos()
    cargarTipo_orden_trabajo()
    cargarBoutiques()
  }, []);




  const [ lista_responsables, setLista_responsables ] = useState<any>({
    mantenimiento : []
  })
  const cargarLista_responsables = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_responsables`)
      setLista_responsables(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }



  const [ lista_quien_atiende, setLista_tecnicos ] = useState<any>({
      tecnicos : [],
      conservacion : [],
      limpieza : [],
  })
  const cargarLista_tecnicos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`zonas/lista_tecnicos`)
      setLista_tecnicos(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }




  const [ tipo_orden_trabajo, setTipo_orden_trabajo ] = useState<any>([])
  const cargarTipo_orden_trabajo = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_orden_trabajo`)
      setTipo_orden_trabajo(response.data)
      if(user.rol==='ROLE_RESPONSABLE_LIMPIEZA'){
        setTipo_orden_trabajo([
            {"id":7,"tipo_orden":"SERVICIO DE LIMPIEZA"},
            {"id":99,"tipo_orden":"OTROS"}
          ])
      }
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }






  const [ opciones_seleccionadas, setOpcionesSeleccionadas ] = useState<any>({})
  const [ opcionesTodas, setTodasOpciones ] = useState<any[]>([]);

  const cargarBoutiques = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`todas_las_boutiques`)
      setTodasOpciones(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar las opciones')
    }
  }


  const [selectedDates, setSelectedDates] = useState<Date[]>([]); // Estado inicial con una fecha

  // Función que maneja la selección de fechas
  const onChange = (date: Date | null) => {
    if (!date) return; // Si no hay fecha, no hacer nada

    // Verificamos si la fecha ya está en el array, si es así no la agregamos
    const isDateAlreadySelected = selectedDates.some(
      (selectedDate) => selectedDate.getTime() === date.getTime()
    );

    if (isDateAlreadySelected) {
      // Si la fecha ya está seleccionada, la eliminamos (deseleccionamos)
      setSelectedDates(selectedDates.filter(
        (selectedDate) => selectedDate.getTime() !== date.getTime()
      ));
    } else {
      // Si no está en el array, la agregamos, asegurándonos de no agregar duplicados
      setSelectedDates([...selectedDates, date]);
    }
  };


  const updateSelectedDates = (dates: Date[]) => {
    setSelectedDates(dates);
  };

  // Función que maneja la lógica de fechas en función de la opción seleccionada
  const handleDateOption = (option: string) => {
    let newDates: Date[] = [];

    // Lógica para generar las fechas según la opción seleccionada
    switch (option) {
      case 'hoy':
          newDates = [new Date()]
        break
      case 'semana_actual':
        // La semana actual: desde el lunes hasta el domingo
        const startOfCurrentWeek = moment().startOf('isoWeek'); // Lunes de la semana actual
        const endOfCurrentWeek = moment(startOfCurrentWeek).endOf('isoWeek'); // Domingo de la semana actual
        for (let date = startOfCurrentWeek; date <= endOfCurrentWeek; date = date.add(1, 'days')) {
          newDates.push(date.toDate());
        }
        break;

      case 'semana_siguiente':
        // La semana siguiente: desde el lunes hasta el domingo
        const startOfNextWeek = moment().add(1, 'weeks').startOf('isoWeek'); // Lunes de la semana siguiente
        const endOfNextWeek = moment(startOfNextWeek).endOf('isoWeek'); // Domingo de la semana siguiente
        for (let date = startOfNextWeek; date <= endOfNextWeek; date = date.add(1, 'days')) {
          newDates.push(date.toDate());
        }
        break;

      case 'siguientes_15_dias':
        // Los siguientes 15 días
        for (let i = 1; i <= 15; i++) {
          newDates.push(moment().add(i, 'days').toDate());
        }
        break;

      case 'mes_actual':
        // El mes actual
        const startOfCurrentMonth = moment().startOf('month');
        const endOfCurrentMonth = moment().endOf('month');
        for (let date = startOfCurrentMonth; date <= endOfCurrentMonth; date = date.add(1, 'days')) {
          newDates.push(date.toDate());
        }
        break;

      case 'siguiente_mes':
        // El siguiente mes
        const startOfNextMonth = moment().add(1, 'month').startOf('month');
        const endOfNextMonth = moment(startOfNextMonth).endOf('month');
        for (let date = startOfNextMonth; date <= endOfNextMonth; date = date.add(1, 'days')) {
          newDates.push(date.toDate());
        }
        break;

      default:
        break;
    }

    updateSelectedDates(newDates); // Actualiza el estado con las fechas correspondientes
  };

  const quitarFecha = (fecha: Date) => {
    setSelectedDates((prevDates) => prevDates.filter((date) => date.getTime() !== fecha.getTime()));
  };

  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Nueva Actividad 🗓️</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <Formik
            validationSchema={schema}
            onSubmit={(values,e)=>{enviarFormulario(values, e)}}
            initialValues={{

                  indicaciones : "",
                  idboutique : "",
                  idresponsable : ["ROLE_ADMIN","ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1 ? "" : user.uuid,
                  idtecnico : "",
                  idtipo_orden : "",
                  // fecha_programada : new Date(),

            }}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form id='my-form' noValidate onSubmit={handleSubmit}>



              {
                // JSON.stringify(user.rol)
              }
              {
                // user.rol
              }


                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Selecciona una Boutique</Form.Label>
                            <Select
                              onChange={(opt:any,e:any)=>{ setOpcionesSeleccionadas(opt); setFieldValue('idboutique',opt.value) }}
                              isClearable={false}
                              name="colors"
                              defaultValue={opciones_seleccionadas||'Seleccionar'}
                              options={opcionesTodas}
                              className={` ${ (errors.idboutique?'is-invalid':'') }`}
                              styles={{ control: styles => ({ ...styles, borderColor: errors.idboutique ? 'red' : '#ced4da' }) }}
                              classNamePrefix="select"
                            />
                      <Form.Control.Feedback type="invalid">{<>{errors.idboutique}</>}</Form.Control.Feedback>
                    </Form.Group>





                        {
                          true && (

                              <>



                  <Form.Group className="mb-3" controlId="campo1">
                    <Form.Label>Indicaciones para la Actividad</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      required
                      name="indicaciones"
                      value={values.indicaciones}
                      onBlur={(e)=>{ setFieldValue('indicaciones', e.target.value.toUpperCase() ) }}
                      onChange={(e)=>{ setFieldValue('indicaciones', e.target.value ) }}
                      style={{textTransform:'uppercase'}}
                      isInvalid={!!errors.indicaciones}
                    />
                    <Form.Control.Feedback type="invalid"> {<>{errors.indicaciones}</>} </Form.Control.Feedback>
                  </Form.Group>


                  <div className="row">
                    <div className="col-sm-4">

                          <Form.Label>Fecha</Form.Label>
    <DatePicker
      selected={selectedDates.length > 0 ? selectedDates[0] : null} // Si hay fechas seleccionadas, mostramos la primera
      onChange={onChange} // Cambia el estado de las fechas seleccionadas
      inline // Mostrar el calendario en línea
      disabledKeyboardNavigation // Deshabilitar la navegación por teclado
      shouldCloseOnSelect={false} // No cerrar el calendario al seleccionar una fecha
      highlightDates={selectedDates} // Resaltar las fechas seleccionadas
    />

      <DropdownButton
        id="dropdown-custom-components"
        title="Seleccionar rango"
        variant="secondary"
        className="w-100 mt-1 mb-2 d-grid" // Para que el dropdown ocupe el 100% del ancho
        size="sm" // Hacemos el dropdown pequeño
      >
        <Dropdown.Item onClick={() => handleDateOption('hoy')}>Hoy</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDateOption('semana_actual')}>La semana actual</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDateOption('semana_siguiente')}>La semana siguiente</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDateOption('siguientes_15_dias')}>Los siguientes 15 días</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDateOption('mes_actual')}>El mes actual</Dropdown.Item>
        <Dropdown.Item onClick={() => handleDateOption('siguiente_mes')}>El siguiente mes</Dropdown.Item>
      </DropdownButton>

                    </div>
                    <div className="col-sm-8">
                        <div className="mb-3">
                          {/*<DatePicker selected={values.fecha_programada} className="form-control"  dateFormat="yyyy-MM-dd"  onChange={(date) => setFieldValue('fecha_programada', date ) } />*/}
                          <Form.Label>Lista de días seleccionados</Form.Label>
{
  // JSON.stringify(selectedDates)
}

                            {
                              selectedDates.length === 0 && (
                                    <div className="alert alert-warning" role="alert">
                                      <p className="nm">🗒 No olvides agregar por lo menos una fecha de visita</p>
                                    </div>
                                )
                            }


                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                  {ordenarFechas(selectedDates).map((date:any, index:number) => (
                                    <Badge
                                      key={index}
                                      pill
                                      bg="primary"
                                      className="m-1"
                                      style={{ display: 'inline-block', cursor: 'pointer' }}
                                      onClick={() => quitarFecha(date)} // Permitir eliminar la fecha al hacer clic
                                    >
                                      {moment(date).format('dddd, MMMM Do YYYY')}
                                      <i className="fa fa-trash ms-2" style={{ cursor: 'pointer' }} />
                                    </Badge>
                                  ))}
                                </div>

                        </div>
                    </div>
                  </div>




                         <Form.Group className="mb-3" controlId="idtipo_orden">
                          <Form.Label>Tipo de Orden de Trabajo</Form.Label>
                          <Form.Select required name="idtipo_orden" value={values.idtipo_orden} onChange={(e)=>{ setFieldValue('idtipo_orden', e.target.value ); setFieldValue('idtecnico',""); if(["ROLE_ADMIN","ROLE_RESPONSABLE_OPERACION"].indexOf(user.rol) > -1) setFieldValue('idresponsable',"") }} isInvalid={!!errors.idtipo_orden}>
                            <option value="">SELECCIONA UN TIPO DE ORDEN</option>
                              {
                                tipo_orden_trabajo.map((tipo_orden:any,index_tipo_orden:number)=>
                                    <React.Fragment key={ index_tipo_orden }>
                                    {
                                      [10,11,1,8,9,4].indexOf(tipo_orden.id) === -1 && (
                                          <option  value={tipo_orden.id}>{tipo_orden.tipo_orden}</option>
                                        )
                                      
                                    }
                                    </React.Fragment>
                                    
                                  )
                              }
                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idtipo_orden}</>} </Form.Control.Feedback>
                        </Form.Group>

                        {
                          // JSON.stringify(user.rol)
                        }

                        {
                          // JSON.stringify(values)
                        }

                        <h5>Responsables de la Actividad</h5>


                        {

                          ["ROLE_RESPONSABLE_OPERACION","ROLE_ADMIN"].indexOf(user.rol) > -1 && (
                              <React.Fragment>

                                   <Form.Group className="mb-3" controlId="idresponsable">
                                    <Form.Label>Selecciona un Responsable</Form.Label>
                                    <Form.Select required name="idresponsable" value={values.idresponsable} onChange={(e)=>{ setFieldValue('idresponsable', e.target.value ) }} isInvalid={!!errors.idresponsable}>
                                      <option value="">SELECCIONA UN RESPONSABLE</option>



                                      {
                                        ["1","2","3","8","99"].indexOf(values.idtipo_orden) > -1 && (
                                            <>
                                                {
                                                  lista_responsables.mantenimiento.map((responsable:any,index_responsables:number)=>
                                                      <option key={index_responsables} value={responsable.uuid}>{responsable.nombres} {responsable.paterno} {responsable.materno} </option>
                                                    )
                                                }
                                            </>
                                          )
                                      }


                                      {
                                        ["7"].indexOf(values.idtipo_orden) > -1 && (
                                            <>
                                              {
                                                lista_responsables.limpieza.map((tecnico:any,index_responsables_l:number)=>
                                                    <option key={ index_responsables_l } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                                  )
                                              }
                                            </>
                                          )
                                      }


                                      {
                                        ["4","5","6"].indexOf(values.idtipo_orden) > -1 && (
                                            <>
                                                {
                                                  lista_responsables.mantenimiento.map((responsable:any,index_responsables_m:number)=>
                                                      <option key={ index_responsables_m } value={responsable.uuid}>{responsable.nombres} {responsable.paterno} {responsable.materno} </option>
                                                    )
                                                }
                                            </>
                                          )
                                      }

                                    </Form.Select>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid"> {<>{errors.idresponsable}</>} </Form.Control.Feedback>
                                  </Form.Group>


                              </React.Fragment> 
                            )

                        }



                            {
                              // JSON.stringify(values.idtipo_orden)
                            }

                         <Form.Group className="mb-3" controlId="idtecnico">
                          <Form.Label>Selecciona quien realizará la Actividad</Form.Label>
                          <Form.Select required name="idtecnico" value={values.idtecnico} onChange={(e)=>{ setFieldValue('idtecnico', e.target.value ) }} isInvalid={!!errors.idtecnico}>
                            <option value="">SELECCIONA UNA OPCIÓN</option>

                            {
                              ["1","2","3","8","99"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.tecnicos.map((tecnico:any,index_tec:number)=>
                                          <option key={ index_tec } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }

                            {
                              ["7"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.limpieza.map((tecnico:any,index_tec:number)=>
                                          <option key={ index_tec } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }

                            {
                              ["4","5","6"].indexOf(values.idtipo_orden) > -1 && (
                                  <>
                                    {
                                      lista_quien_atiende.conservacion.map((tecnico:any,index_tec:number)=>
                                          <option key={ index_tec } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }

                                    {
                                      lista_quien_atiende.tecnicos.map((tecnico:any,index_tec:number)=>
                                          <option key={ index_tec } value={tecnico.uuid}>{tecnico.nombres} {tecnico.paterno} {tecnico.materno} </option>
                                        )
                                    }
                                  </>
                                )
                            }



                          </Form.Select>
                          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid"> {<>{errors.idtecnico}</>} </Form.Control.Feedback>
                        </Form.Group>





                            {
                              // JSON.stringify(values.idtecnico)
                            }

                              </>

                            )
                        }






              </Form>
            )}
          </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalNuevaActividadLibre;