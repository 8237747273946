import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'




import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import Dialog from '../../services/Dialogs'

import moment from 'moment'



import { SERVER_ } from  '../../config';



const ModalReporteRefaccionamiento = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose } = params


  

  const [ clientes, setClientes ] = useState<any>([])
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarClientes = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`lista_clientes/cobranza`)
      setClientes(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarClientes()
  }, []);








  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          uuid_cliente : yup.string().required(mensajes_validacion_generico),
  });


  const enviarFormulario = async (registro:any,event:any) =>{

      window.open(`${SERVER_}cobranza/reporte_refaccionamiento/${registro.uuid_cliente}/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`)

        // await dialog.open({
        //   accept: 'Si',
        //   cancel: 'Espera',
        //   dialogClass: '',
        //   message: '¿Estás seguro de la información?',
        //   target: event.target,
        //   template: ''
        // })
        // let dialogo : any = await dialog.waitForUser()
        // if(dialogo){
        //   try{

        //   setSeEnvio(true)
        //   let res_ = await RequestService.update( `URL_REQUEST` , { ...registro, campo_fecha : moment(registro.campo_fecha).format('YYYY-MM-DD') })

        //   if(res_){
        //     toast.success('MENSAJE')
        //     setTimeout(()=>{
        //       handleClose()
        //     },0)
        //   }
        //   else{
        //     toast.error('Problemas...')
        //   } 
        //   }
        //   catch(e:any){
        //     toast.error(e.response.data)
        //     // console.log(e.response.data)
        //   } 
          
        // }
       
  }



  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Refaccionamiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Formik
              validationSchema={schema}
              onSubmit={(values,e)=>{enviarFormulario(values, e)}}
              initialValues={{
                    uuid_cliente : "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <Form id='my-form' noValidate onSubmit={handleSubmit}>

                {
                  // JSON.stringify(values)
                }


                   <Form.Group className="mb-3" controlId="uuid_cliente">
                    <Form.Label>Rango de Fecha de Reporte</Form.Label>

                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        selectsRange
                        inline
                      />

                  </Form.Group>




                   <Form.Group className="mb-3" controlId="uuid_cliente">
                    <Form.Label>Clientes</Form.Label>
                    <Form.Select required name="uuid_cliente" value={values.uuid_cliente} onChange={(e)=>{ setFieldValue('uuid_cliente', e.target.value ) }} isInvalid={!!errors.uuid_cliente}>
                      <option value="">SELECCIONA UN TIPO</option>
                        {
                          clientes.map((cliente:any,indice_cliente:number)=><option value={cliente.uuid}>{cliente.cliente}</option>)
                        }
                    </Form.Select>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid"> {<>{errors.uuid_cliente}</>} </Form.Control.Feedback>
                  </Form.Group>




                </Form>
              )}
            </Formik>



          {
            // JSON.stringify(clientes)
          }




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <button className="btn btn-success" form='my-form' type="submit" >Ok</button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalReporteRefaccionamiento;