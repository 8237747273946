import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


const ModalReproducirVideo = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, modulo } = params

  return (
    <>
      <Modal show={show} fullscreen={true} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Video Manual</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {
            // modulo
          }


            {
              modulo === 'mis_requisiciones' && (<video controls className="w-100 h-100" style={{ objectFit: 'cover' }} ><source src="videomanuales/mis_requisiciones_.mp4" type="video/mp4" /> Your browser does not support the video tag.</video>)
            }

            {
              modulo === 'requisiciones' && (<video controls className="w-100 h-100" style={{ objectFit: 'cover' }} ><source src="videomanuales/selin_manual_requisiciones.mp4" type="video/mp4" /> Your browser does not support the video tag.</video>)
            }



        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalReproducirVideo;