import React, { useState } from 'react';

import RequestService from "../../services/request.service";



import {Button, Modal } from 'react-bootstrap';
import { Form, Col, Row } from 'react-bootstrap'

import { toast } from 'react-toastify'


import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import Select from 'react-select'

import Dialog from '../../services/Dialogs'


import municipiosListType from '../../interfaces/municipiosListType'



const ModalEditarDatosCliente = ( params : any ) => {

  const { show, handleClose, cliente } = params



  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

        cliente : yup.string().required(mensajes_validacion_generico),
        rfc : yup.string().required(mensajes_validacion_generico),
        razon_social : yup.string().required(mensajes_validacion_generico),
        direccion : yup.string().required(mensajes_validacion_generico),
        cp : yup.string().required(mensajes_validacion_generico),
        telefono : yup.string().required(mensajes_validacion_generico),
        email : yup.string().required(mensajes_validacion_generico),
        nombre_contacto : yup.string().required(mensajes_validacion_generico),
        telefono_contacto : yup.string().required(mensajes_validacion_generico),
        idmunicipio : yup.number().required(mensajes_validacion_generico),

  });



  const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])
  const [ etiquetasOptions, setEtiquetas ] = useState<municipiosListType[]>([]);



const [ envio_server, setSeEnvio ] = useState<boolean>(false)



  const cargarMunicipios = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('todos_los_municipios')
      setEtiquetas(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar todos_los_municipios')
    }
  }



  React.useEffect(() => {
    cargarMunicipios()
  }, []);





const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `cliente/editar_informacion/${cliente.uuid}` , { ...registro })

        if(res_){
          toast.warning('La información de Cliente se actualizó')
          setTimeout(()=>{
            // handleClose()
          },0)
        }
        else{
          toast.error('Problemas al agregar al cliente...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información de Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(cliente)
          }


    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            cliente : cliente.cliente,
            rfc : cliente.rfc,
            razon_social : cliente.razon_social,
            direccion : cliente.direccion,
            cp : cliente.cp,
            telefono : cliente.telefono,
            email : cliente.email,
            nombre_contacto : cliente.nombre_contacto,
            telefono_contacto : cliente.telefono_contacto,
            idmunicipio : 2325,
            // idestado : 4,
            // campo_fecha : new Date(moment(registro_.campo_fecha).toString()),

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

{
  // JSON.stringify(values)
}


            <Form.Group   className="mb-3" controlId="cliente">
              <Form.Label>Nombre del Cliente</Form.Label>
              <Form.Control
                required
                name="cliente"
                value={values.cliente}
                onChange={(e)=>{ setFieldValue('cliente', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.cliente}
              />
              { errors.cliente && ( <><Form.Control.Feedback type="invalid"> {<>{errors.cliente}</>} </Form.Control.Feedback></> ) }
            </Form.Group>


            <Form.Group   className="mb-3" controlId="rfc">
              <Form.Label>R.F.C.</Form.Label>
              <Form.Control
                required
                name="rfc"
                value={values.rfc}
                onChange={(e)=>{ setFieldValue('rfc', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.rfc}
              />
              { errors.rfc && ( <><Form.Control.Feedback type="invalid"> {<>{errors.rfc}</>} </Form.Control.Feedback></> ) }
            </Form.Group>


            <Form.Group   className="mb-3" controlId="razon_social">
              <Form.Label>Razón Social</Form.Label>
              <Form.Control
                required
                name="razon_social"
                value={values.razon_social}
                onChange={(e)=>{ setFieldValue('razon_social', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.razon_social}
              />
              { errors.razon_social && ( <><Form.Control.Feedback type="invalid"> {<>{errors.razon_social}</>} </Form.Control.Feedback></> ) }
            </Form.Group>


            <Form.Group   className="mb-3" controlId="direccion">
              <Form.Label>Dirección del Cliente</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="direccion"
                value={values.direccion}
                onChange={(e)=>{ setFieldValue('direccion', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.direccion}
              />
              { errors.direccion && ( <><Form.Control.Feedback type="invalid"> {<>{errors.direccion}</>} </Form.Control.Feedback></> ) }
            </Form.Group>


 


            <div className="row">
              <div className="col-sm-8">

{
  // JSON.stringify(etiquetas_seleccionadas)
}

                    <Form.Group
                      controlId="ticket_type_id"
                      className="mb-3"
                    >

                      <Form.Label>Municipio</Form.Label>

                            <Select
                              onChange={(opt:any,e:any)=>{ setEtiquetasSeleccionada(opt); setFieldValue('idmunicipio',opt.value) }}
                              isClearable={false}
                              name="colors"
                              options={etiquetasOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                    </Form.Group>



              </div>
              <div className="col-sm-4">

                    <Form.Group className="mb-3" controlId="cp">
                      <Form.Label>Código Postal</Form.Label>
                      <Form.Control
                        required
                        name="cp"
                        value={values.cp}
                        onChange={(e)=>{ setFieldValue('cp', (e.target.value) ) }}
                        isInvalid={!!errors.cp}
                      />
                      { errors.cp && ( <><Form.Control.Feedback type="invalid"> {<>{errors.cp}</>} </Form.Control.Feedback></> ) }
                    </Form.Group>

              </div>
            </div>



            <div className="row">
              <div className="col-sm-6">
                <Form.Group   className="mb-3" controlId="telefono">
                  <Form.Label>Teléfono de Oficinas</Form.Label>
                  <Form.Control
                    required
                    name="telefono"
                    value={values.telefono}
                    onChange={(e)=>{ setFieldValue('telefono', (e.target.value) ) }}
                    isInvalid={!!errors.telefono}
                  />
              <Form.Control.Feedback type="invalid"> {<>{errors.cp}</>} </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-sm-6">
                <Form.Group   className="mb-3" controlId="telefono">
                  <Form.Label>Correo Electrónico</Form.Label>
                  <Form.Control
                    required
                    name="email"
                    value={values.email}
                    onChange={(e)=>{ setFieldValue('email', (e.target.value) ) }}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {<>{errors.email}</>} </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>


            <Form.Group   className="mb-3" controlId="nombre_contacto">
              <Form.Label>Nombre del Contacto con el Cliente</Form.Label>
              <Form.Control
                required
                name="nombre_contacto"
                value={values.nombre_contacto}
                onChange={(e)=>{ setFieldValue('nombre_contacto', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.nombre_contacto}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {<>{errors.nombre_contacto}</>} </Form.Control.Feedback>
            </Form.Group>




            <Form.Group   className="mb-3" controlId="telefono_contacto">
              <Form.Label>Teléfono del Contacto</Form.Label>
              <Form.Control
                required
                name="telefono_contacto"
                value={values.telefono_contacto}
                onChange={(e)=>{ setFieldValue('telefono_contacto', (e.target.value) ) }}
                isInvalid={!!errors.telefono_contacto}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.nombre_contacto}</>} </Form.Control.Feedback>
            </Form.Group>









        </Form>
      )}
    </Formik>




        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarDatosCliente;