import moment from 'moment'

export const FormateaNumero = (n:string) => n.substr(0,3) + '-' + n.substr(3,3) + '-' + n.substr(6,4) 

export const calificaciones = ["","MALA","DEBE MEJORAR","REGULAR","BUENO","EXCELENTE SERVICIO"]


export const obtenerNumeroDiaSemana = (fecha:any) => {
  const diasSemana = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado','Domingo'];
  const fechaObjeto = new Date(fecha);
  const numeroDiaSemana = fechaObjeto.getDay();

  return numeroDiaSemana;
}


export const diasDeLaSemana = () => ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado','Domingo']

export const agregarDias = (fecha:any, dias:number) => {
  const fechaInicial = new Date(fecha);
  const milisegundosPorDia = 24 * 60 * 60 * 1000; // Cantidad de milisegundos en un día
  const fechaFinal = new Date(fechaInicial.getTime() + dias * milisegundosPorDia);
  return fechaFinal.toISOString().split('T')[0];
}


export const  obtenerRangoFechasArreglo = (fechaInicio:any, fechaFin:any) => {
  const fechaActual = (fechaInicio);
  const fechas = [];

  while (fechaActual <= (fechaFin)) {
    fechas.push(fechaActual.toISOString().split('T')[0]);
    fechaActual.setDate(fechaActual.getDate() + 1);
  }

  return fechas;
}



export const ordenarPorTipo = (a:any, b:any) => {
  return a.tipo - b.tipo;
}

export const formatearNumero = (numero:number) => {
  const partes = numero.toFixed(2).toString().split('.');
  partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return partes.join('.');
}


export const sumarMontosTotales = (arreglo:any) => {
  return arreglo.reduce((total:any, item:any) => {
    return total + parseFloat(item.monto_total);
  }, 0);
};

export const sumarMontosTotalesAprobados = (arreglo:any) => {
  return arreglo.reduce((total:any, item:any) => {
    // console.log(item)
    return total + ( [1,3,4,5,6,7,8,99].indexOf(item?.idestatus_elemento?.id) > -1 ? parseFloat(item.monto_total) : 0 );
  }, 0);
};

export const sumarMontosTotalesNoAprobados = (arreglo:any) => {
  return arreglo.reduce((total:any, item:any) => {
    return total + ( [2].indexOf(item?.idestatus_elemento?.id) > -1 ? parseFloat(item.monto_total) : 0 );
    // return total + parseFloat(item.monto_total);
  }, 0);
};


export const formatearFechaHora = (fecha:any) => {
  return moment(fecha).format('DD/MMM/YYYY HH:mm').toUpperCase().replace('.','')
}

export const formatearFechaEstandar = (fecha:any) => {
  return moment(fecha).format('DD/MMM/YYYY').toUpperCase().replace('.','')
}


export const labelUnidadMedida = (idunidad_medida:number,arreglo_tipo_unidad_medidad:any)=>{
  let l = ''
  for(let unidad of arreglo_tipo_unidad_medidad){
    if(idunidad_medida===unidad.id) l = unidad.unidad_medida
  }
  return l
}



export const estadoCuidadoHerramienta = (estado:string)=>{
    let l = ''
    if(estado==="NUEVO") l = 'NUEVO'
    if(estado==="MEDIO_USO") l = 'MEDIO USO'
    if(estado==="MAL_ESTADO") l = 'MAL ESTADO'
    return l
  }



export   const tipoActividadLabel = (tipo:number)=>{
    let l = ''
    if(tipo===0) l = 'MATERIAL'
    if(tipo===1) l = 'MANO DE OBRA'
    if(tipo===2) l = 'HERRAMIENTA'
    if(tipo===3) l = 'SERVICIO'
    if(tipo===4) l = 'GASTO'
    return l
  }



export const condicionesPago = (condicion:string)=>{
  let s = `-`
  if(condicion==='PAGO_CONTADO') s = 'PAGO DE CONTADO'
  if(condicion==='PAGO_CREDITO') s = 'PAGO A CRÉDITO'
    return s
}




export const tipoDeElementoRequisicion = (condicion:string)=>{
  let s = ``
  if(condicion==='pago') s = 'SERVICIOS'
  if(condicion==='compra') s = 'COMPRAS'
  if(condicion==='viaticos') s = 'VIÁTICOS'
  if(condicion==='reembolso') s = 'REEMBOLSOS'
    return s
}


export const obtenerDiasRestantes = (fechaActual:any, fechaInicio:any, fechaConclusion:any) => {

  if(isNaN(fechaConclusion)){
    return "";
  }
  
  if (fechaActual >= fechaInicio && fechaActual <= fechaConclusion) {
    var tiempoRestante = fechaConclusion - fechaActual;
    var diasRestantes = Math.ceil(tiempoRestante / (1000 * 60 * 60 * 24));
    
    return diasRestantes == 1 ? diasRestantes + " día" : diasRestantes + " días";
  } else {
    return "Concluiyó";
  }
}

export const fechaPaso = (fechaComparacion:any, fechaInicio:any, fechaFin:any) => {
  const comparacion = fechaComparacion;
  const inicio = fechaInicio;
  const fin = fechaFin;
  
  if (comparacion >= inicio && comparacion <= fin) {
    return false;
  } else {
    return true;
  }
}


export const calcularDiasPasados = (fechaInicio:any, fechaFin:any) => {
  var unDia = 24 * 60 * 60 * 1000; // Cantidad de milisegundos en un día
  
  // Convertir las fechas a objetos de tipo Date
  var fecha1 = new Date(fechaInicio);
  var fecha2 = new Date(fechaFin);
  
  // Calcular la diferencia en milisegundos entre las dos fechas
  var diferencia = Math.abs(fecha2.getTime() - fecha1.getTime());
  
  // Calcular la cantidad de días redondeando hacia abajo
  var diasPasados = Math.floor(diferencia / unDia);
  
  return diasPasados;
}

export const obtenerNombreDia = (numeroDia:number)=> {
  const nombresDias = [ 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado','Domingo'];
  const indiceDia = numeroDia % 7; // Aseguramos que el índice esté dentro del rango válido

  return nombresDias[indiceDia];
}

export const  obtenerDiaSemana = (fecha:any)=> {
  const diasSemana = [ 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado','Domingo'];
  const dia = new Date(fecha).getDay();
  return diasSemana[dia];
}

export const  obtenerDiaSemanaLetraInicial = (fecha:any)=> {
  const diasSemana = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];
  const dia = new Date(fecha).getDay();
  return diasSemana[dia];
}



export const obtenerFechasSemana = (fecha:any)=> {
  let fechasSemana = [];
  const diaSemana = fecha.getDay(); // Obtener el día de la semana (0 = domingo, 1 = lunes, ...)
  
  // Restar días según el día de la semana para obtener la fecha de inicio de la semana
  const inicioSemana = new Date(fecha.getTime() - diaSemana * 24 * 60 * 60 * 1000);
  
  // Agregar las fechas de la semana a la lista
  for (let i = 0; i < 7; i++) {
    const fechaActual = new Date(inicioSemana.getTime() + i * 24 * 60 * 60 * 1000);
    fechasSemana.push(moment(fechaActual).format('YYYY-MM-DD'));
  }
  
  return fechasSemana;
}


export const  obtenerInicioFinSemana =(fecha:any)=> {
  const inicioSemana = new Date(fecha.getTime());
  const diaSemana = inicioSemana.getDay(); // Obtener el día de la semana (0 = domingo, 1 = lunes, ...)
  
  // Restar días según el día de la semana para obtener la fecha de inicio de la semana
  inicioSemana.setDate(inicioSemana.getDate() - diaSemana);
  
  // Calcular la fecha de fin sumando 6 días a la fecha de inicio
  const finSemana = new Date(inicioSemana.getTime());
  finSemana.setDate(finSemana.getDate() + 6);
  
  return {
    inicio: inicioSemana,
    fin: finSemana
  };
}

export const obtenerInicioFinQuincena=(fecha:any)=> {
  const inicioQuincena = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
  const mitadMes = Math.ceil(fecha.getDate() / 15) * 15;
  const finQuincena = new Date(fecha.getFullYear(), fecha.getMonth(), mitadMes);
  
  // Ajustar la fecha de fin de quincena si se supera el último día del mes
  if (finQuincena.getMonth() !== fecha.getMonth()) {
    finQuincena.setDate(finQuincena.getDate() - 1);
  }
  
  return {
    inicio: inicioQuincena,
    fin: finQuincena
  };
}

export const obtenerInicioFinQuincenaSiguiente =(fecha:any)=> {
  const inicioQuincena = new Date(fecha.getFullYear(), fecha.getMonth(), 16);
  const finQuincena = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);

  return {
    inicio: inicioQuincena,
    fin: finQuincena
  };
}


export const obtenerInicioFinQuincenaAnterior=(fecha:any)=> {
  const fechaInicioMes = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
  const fechaMitadMes = new Date(fecha.getFullYear(), fecha.getMonth(), 15);
  const fechaAnterior = new Date(fechaInicioMes.getTime() - 1);

  const quincenaInicio = new Date(fechaAnterior.getFullYear(), fechaAnterior.getMonth(), fechaMitadMes.getDate() + 1);
  const quincenaFin = fechaMitadMes;

  return {
    inicio: quincenaInicio,
    fin: quincenaFin
  };
}


export const obtenerRangoFechas = ( ) => {
  const ayer = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const hoy = moment().format('YYYY-MM-DD');
  const mañana = moment().add(1, 'day').format('YYYY-MM-DD');

  return [ayer, hoy, mañana];
}




export const diferenciaHoras = (fecha1:any, fecha2:any) =>{
    // Parsea las fechas a objetos Date
    const date1 : any = new Date(fecha1);
    const date2 : any = new Date(fecha2);

    // Calcula la diferencia en milisegundos
    const diferencia = date2 - date1;

    // Convierte la diferencia a minutos
    const minutosTotales = diferencia / (60 * 1000);
    
    // Calcula las horas y minutos separados
    const horas = Math.floor(minutosTotales / 60);
    const minutos = Math.round(minutosTotales % 60);

    return `${horas} hora(s) ${minutos} minuto(s)`;
}




export const sonValoresIguales = (arreglo:any, valorVerificar:any)=> {
    return arreglo.every((elemento:any) => elemento === valorVerificar);
}




export const contarNoBaseODescanso  =(arr:any)=> {
  // Filtramos el arreglo para obtener solo aquellos objetos donde 'idboutique' no es 'BASE' ni 'DESCANSO'
  const filtrado = arr.filter((obj:any) => obj.idboutique !== 'BASE' && obj.idboutique !== 'DESCANSO');

  // Devolvemos la longitud del arreglo filtrado, que es la cantidad de registros que cumplen con la condición
  return filtrado.length;
}


export const tipoPresupuesto = (tipo:string)=>{

  if("PRESUPUESTO_CONSERVACION"===tipo) return "CONSERVACIÓN" 
  if("PRESUPUESTO_REPARACION"===tipo) return "REPARACION" 
  if("PRESUPUESTO_INSTALACION"===tipo) return "INSTALACIÓN" 
  if("PRESUPUESTO_LIMPIEZA"===tipo) return "LIMPIEZA" 
  if("PRESUPUESTO_SUMINISTRO"===tipo) return "SUMINISTRO" 
  if("PRESUPUESTO_SUMINISTRO_INSTALACION"===tipo) return "SUMINISTRO E INSTALACION" 
  if("GASTO_OPERACION"===tipo) return "GASTOS DE OPERACIÓN" 
  if("GASTOS_MOVILIZACION"===tipo) return "GASTOS DE MOVILIZACIÓN" 


  if("POR_DEFINIR"===tipo) return "POR SERVICIO" 


  if("PRESUPUESTO_INSUMOS"===tipo) return "SOLICITUD INSUMOS" 

  return '?'

}




















export const eliminarDuplicados = (arr:any) => {
  const uniqueRecords = [];
  const seen = new Set();

  for (const record of arr) {
    const recordString = JSON.stringify(record);
    if (!seen.has(recordString)) {
      uniqueRecords.push(record);
      seen.add(recordString);
    }
  }

  return uniqueRecords;
}



export const obtenerFechasConTecnico = (arregloSubareas:any)=> {
  const resultado: any = [];
// console.log(arregloSubareas)

  for(let subarea of arregloSubareas){
    for(let tecnico of subarea.tecnicos){
      // resultado.push()
                let backgroundColor = '#000000'
                let textColor = '#ffffff'

// console.log(tecnico)
      for(let fecha of tecnico.fechas){
        if(["DESCANSO","BASE"].indexOf(fecha.idboutique) < 0){
                    backgroundColor = '#86dac5'
                    textColor = '#ffffff'

                resultado.push({
                    backgroundColor : backgroundColor,
                    textColor : textColor,
                    borderColor : backgroundColor,
                    title : `${tecnico.nombres} ${tecnico.paterno} ${tecnico.materno} `,
                    // metadata : fecha,
                    start : moment(fecha.fecha).format('YYYY-MM-DD'),
                    from : moment(fecha.fecha).format('YYYY-MM-DD'),
                    to : moment(fecha.fecha).format('YYYY-MM-DD'),
                    middayCheckout: false
                })
        }
        if(["DESCANSO"].indexOf(fecha.idboutique) > -1){
                    backgroundColor = '#e6e2ad'
                    textColor = '#ffffff'

                resultado.push({
                    backgroundColor : backgroundColor,
                    textColor : textColor,
                    borderColor : backgroundColor,
                    title : `${tecnico.nombres} ${tecnico.paterno} ${tecnico.materno} `,
                    // metadata : fecha,
                    start : moment(fecha.fecha).format('YYYY-MM-DD'),
                    from : moment(fecha.fecha).format('YYYY-MM-DD'),
                    to : moment(fecha.fecha).format('YYYY-MM-DD'),
                    middayCheckout: false
                })
        }
      }

    }
  }

  return resultado;
}




export const calcularTiempoTranscurrido = (fecha:any)=>{
  const fechaConsulta = moment();
  const fechaParametro = moment(fecha);

  // Caso 1: Mismo día
  if (fechaConsulta.isSame(fechaParametro, 'day')) {
    const horasTranscurridas = fechaConsulta.diff(fechaParametro, 'hours');
    const minutosTranscurridos = fechaConsulta.diff(fechaParametro, 'minutes') % 60;

    if (horasTranscurridas > 0) {
      return `Hace ${horasTranscurridas} horas y ${minutosTranscurridos} minutos`;
    } else if (minutosTranscurridos > 0) {
      return `Hace ${minutosTranscurridos} minutos`;
    } else {
      return 'Hace unos momentos';
    }
  }

  // Caso 2: Ayer
  if (fechaConsulta.isSame(fechaParametro.clone().add(1, 'day'), 'day')) {
    return 'Ayer';
  }

  // Caso 3: Más de 2 días
  if (fechaConsulta.diff(fechaParametro, 'months') >= 2) {
    return `Hace ${fechaConsulta.diff(fechaParametro, 'months')} meses`;
  }

  // Caso 4: Más de 12 meses + 1 día
  if (fechaConsulta.diff(fechaParametro, 'years') >= 1) {
    return `Hace ${fechaConsulta.diff(fechaParametro, 'years')} años`;
  }

  // Caso 5: Más de 2 meses pero menos de 12 meses
  const diasTranscurridos = fechaConsulta.diff(fechaParametro, 'days');
  return diasTranscurridos > 0 ? `Hace ${diasTranscurridos} días` : 'Hoy';
}




export const esHoy = (fecha:string)=> {
  const fechaActual = new Date();
  const fechaIngresada = new Date(fecha);

  // Compara el año, mes y día de la fecha actual con la fecha ingresada
  return (
    fechaActual.getFullYear() === fechaIngresada.getFullYear() &&
    fechaActual.getMonth() === fechaIngresada.getMonth() &&
    fechaActual.getDate() === fechaIngresada.getDate()
  );
}




export const formatToTwoDecimals=(num:number)=> {
    return num.toLocaleString('es-MX', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}



export const obtenerInicioFinSemanaActual = () => {
  const fechaActual = new Date();
  const diaSemana = fechaActual.getDay(); // 0 (domingo) a 6 (sábado)

  // Restamos el número de días transcurridos desde el primer día de la semana (domingo) hasta la fecha actual
  const diasDesdePrimerDiaSemana = diaSemana - 0;

  // Obtenemos el primer día de la semana restando los días desde el primer día del mes
  const primerDiaSemana = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate() - diasDesdePrimerDiaSemana);

  // Obtenemos el último día de la semana sumando los días restantes hasta el último día de la semana (sábado)
  const ultimoDiaSemana = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate() + (6 - diasDesdePrimerDiaSemana));

  return {
    inicioSemana: primerDiaSemana,
    finSemana: ultimoDiaSemana,
  };
}




export const  getStartAndEndDates=(dateString:any)=> {
    let date = dateString;

    // Fecha de inicio (primer día del mes)
    let startDate = new Date(date.getFullYear(), date.getMonth(), 1);

    // Fecha de fin (último día del mes)
    let endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return {
        inicioMes: startDate,
        finMes: endDate
    };
}














export const evaluarAtencion = (objeto:any)=> {
    const calificaciones = objeto.lista_caminata_supervision.map((item:any) => item.calificacion);
    const conteo : any = {
        BUENO: 0,
        REGULAR: 0,
        MALO: 0
    };

    // Verificar si hay alguna calificación vacía
    if (calificaciones.includes("")) {
        return "SIN TERMINAR DE EVALUAR";
    }

    // Contar las calificaciones
    calificaciones.forEach((calificacion:any) => {
        conteo[calificacion]++;
    });

    // Comparar las calificaciones
    if (conteo.BUENO > conteo.REGULAR && conteo.BUENO > conteo.MALO) {
        return 'BUENO';
    } else if (conteo.REGULAR >= conteo.BUENO && conteo.REGULAR > conteo.MALO) {
        return 'REGULAR';
    } else {
        return 'MALO';
    }
}

export const evaluarAtencionGlobal = (objeto:any) => {
    const conteo : any = {
        BUENO: 0,
        REGULAR: 0,
        MALO: 0
    };

    // Iteramos por cada área y sus calificaciones
    objeto.areas.forEach((area:any) => {
        const calificaciones = area.lista_caminata_supervision.map((item:any) => item.calificacion);

        // Verificar si hay alguna calificación vacía
        if (calificaciones.includes("")) {
            return "AÚN NO SE TERMINA DE EVALUAR";
        }

        // Contar las calificaciones
        calificaciones.forEach((calificacion:any) => {
            conteo[calificacion]++;
        });
    });

    // Comparar las calificaciones
    if (conteo.BUENO > conteo.REGULAR && conteo.BUENO > conteo.MALO) {
        return 'BUENO';
    } else if (conteo.REGULAR >= conteo.BUENO && conteo.REGULAR > conteo.MALO) {
        return 'REGULAR';
    } else {
        return 'MALO';
    }
}



export const  formatearFecha=(fecha:any)=> {
  const opciones : any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const fechaFormateada : any = new Intl.DateTimeFormat('es-ES', opciones).format(new Date(fecha));
  return fechaFormateada.charAt(0).toUpperCase() + fechaFormateada.slice(1);
}






export const getBase64 = (file:any) => {
  return new Promise((resolve:any, reject:any) => {
    const reader : any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error:any) => reject(error);
  });
};



export const calcularTamanoEnKB = (variable:any) => {
  // Convertir la longitud de la variable en bytes
  const bytes = new Blob([variable]).size;

  // Convertir bytes a kilobytes
  const kilobytes = bytes / 1024;

  return kilobytes;
}







export const obtenerUnidadMedida = (arr:any, id:number) => {
    const elemento = arr.find((elemento:any) => elemento.id === id);
    if (elemento) {
        return elemento.unidad_medida;
    }
    return 'ID no encontrado';
}





export const filtrarElementosUnicos = (arr:any) => {
  const unicos : any = [];

  arr.forEach((item:any) => {
    // Comprobar si ya existe un elemento con las mismas propiedades
    const existe : any = unicos.some(
      (unico:any) =>
        unico.banco === item.banco &&
        unico.cuenta_referencia === item.cuenta_referencia &&
        unico.nombre_razon_social === item.nombre_razon_social
    );

    // Si no existe, agregarlo al arreglo de únicos
    if (!existe) {
      unicos.push(item);
    }
  });

  return unicos;
}




export const sumarPrecios = (arr:any)=> {
  return arr.reduce((total:number, item:any) => {
    return total + (item.precio || 0); // Suma el precio si existe, de lo contrario, suma 0
  }, 0); // Valor inicial de la suma es 0
}




export const ordenarFechas = (fechas:any) => {
  return fechas.sort((a:any, b:any) => {
    // Convertimos las fechas a momentos para evitar problemas con la comparación directa
    const fechaA = moment(a);
    const fechaB = moment(b);
    
    // Comparar las fechas
    if (fechaA.isBefore(fechaB)) {
      return -1; // Si A es antes que B, A va primero
    }
    if (fechaA.isAfter(fechaB)) {
      return 1; // Si A es después que B, B va primero
    }
    return 0; // Si son iguales, no cambiamos su posición
  });
};
