import React, { useState } from "react";
import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { toast } from 'react-toastify'

import moment from 'moment'
import 'moment/locale/es'


import Pagination from "../../services/Pagination";
import { Table, Form, Dropdown } from 'react-bootstrap'


import ModalNuevoCalendarioActividades from './ModalNuevoCalendarioActividades'
import ModalNuevoCalendarioConservacion from './ModalNuevoCalendarioConservacion'







import ModalConfigurarNuevoCalendario from './ModalConfigurarNuevoCalendario'






import ModalCargarAccesosOperacion from './ModalCargarAccesosOperacion'





import ModalNuevaActividad from './ModalNuevaActividad'
import ModalNuevaActividadLibre from './ModalNuevaActividadLibre'

import ModalEditarVisita from './ModalEditarVisita'

import ModalActividadesNoConcluidas from './ModalActividadesNoConcluidas'



import ModalDetalleActividad from './ModalDetalleActividad'



import ModalAsistencias from './ModalAsistencias'


import ModalAsignarPresupuestoVisita from './ModalAsignarPresupuestoVisita'


import ModalReporteEvidenciasVisuales from '../Reportes/ModalReporteEvidenciasVisuales'



import Dialog from '../../services/Dialogs'

import { calcularDiasPasados, obtenerDiaSemana, calificaciones } from '../../services/funcionesPublicas'

import { SERVER_ } from '../../config'

import './configuracion.scss'





const Operacion = () =>{


  const user = AuthService.getCurrentUser()


  const dialog = new Dialog();



      React.useEffect(() => {
        busqueda()
      }, []);

  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 40
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`operacion/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar cargar tus resultados')
    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    // setCurrentPage(1)
  }




  const [ show_ModalNuevoCalendarioActividades, setModalNuevoCalendarioActividades ] = useState<boolean>(false)
  const handleShow_ModalNuevoCalendarioActividades = () => {
    setModalNuevoCalendarioActividades(true)
  }
  const handleClose_ModalNuevoCalendarioActividades = () => {
    busqueda()
    setModalNuevoCalendarioActividades(false)
  }

 const [ arreglo_estatus, setEstatus ] = useState<any>([true, true, true, true, true, true, true, true, true])


  const todos = () =>{
    setEstatus([true, true, true, true, true, true, true, true, true])
  }
  const ninguno = () =>{
    setEstatus([false, false, false, false, false, false, false, false, false])
  }



  const [ show_ModalNuevaActividad, setModalNuevaActividad ] = useState<boolean>(false)
  const handleShow_ModalNuevaActividad = () => {
    setModalNuevaActividad(true)
  }
  const handleClose_ModalNuevaActividad = () => {
        busqueda()
    setModalNuevaActividad(false)
  }


  const [ show_ModalEditarTareaMantenimiento, setModalEditarTareaMantenimiento ] = useState<boolean>(false)
  const [ tarea_seleccionada, setTareaSeleccionada ] = useState<any>({})
  const handleShow_ModalEditarTareaMantenimiento = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalEditarTareaMantenimiento(true)
  }
  const handleClose_ModalEditarTareaMantenimiento = () => {
        busqueda()
    setModalEditarTareaMantenimiento(false)
  }



  const [ show_ModalNuevaActividadLibre, setModalNuevaActividadLibre ] = useState<boolean>(false)
  const handleShow_ModalNuevaActividadLibre = () => {
    setModalNuevaActividadLibre(true)
  }
  const handleClose_ModalNuevaActividadLibre = () => {
        busqueda()
    setModalNuevaActividadLibre(false)
  }





  const [ show_ModalActividadesNoConcluidas, setModalActividadesNoConcluidas ] = useState<boolean>(false)
  const handleShow_ModalActividadesNoConcluidas = () => {
    setModalActividadesNoConcluidas(true)
  }
  const handleClose_ModalActividadesNoConcluidas = () => {
    setModalActividadesNoConcluidas(false)
  }



  const [ show_ModalDetalleActividad, setModalDetalleActividad ] = useState<boolean>(false)
  const handleShow_ModalDetalleActividad = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalDetalleActividad(true)
  }
  const handleClose_ModalDetalleActividad = () => {
        busqueda()
    setModalDetalleActividad(false)
  }




  const [ show_ModalNuevoCalendarioConservacion, setModalNuevoCalendarioConservacion ] = useState<boolean>(false)
  const handleShow_ModalNuevoCalendarioConservacion = () => {
    setModalNuevoCalendarioConservacion(true)
  }
  const handleClose_ModalNuevoCalendarioConservacion = () => {
    setModalNuevoCalendarioConservacion(false)
  }




  const [ show_ModalConfigurarNuevoCalendario, setModalConfigurarNuevoCalendario ] = useState<boolean>(false)
  const handleShow_ModalConfigurarNuevoCalendario = () => {
    setModalConfigurarNuevoCalendario(true)
  }
  const handleClose_ModalConfigurarNuevoCalendario = () => {
    setModalConfigurarNuevoCalendario(false)
  }






  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const eliminarActividad = async (event:any,uuid_actividad:string) =>{

        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: `<p><small>La información se perderá para siempre</small>`
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.delete( `calendario/eliminar_actividad/${uuid_actividad}`)

          if(res_){
            toast.warning('La visita se eliminó correctamente', { icon : "🗑️" })
            setTimeout(()=>{
              busqueda()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }







  const [ show_ModalAsistencias, setModalAsistencias ] = useState<boolean>(false)
  const handleShow_ModalAsistencias = () => {
    setModalAsistencias(true)
  }
  const handleClose_ModalAsistencias = () => {
    setModalAsistencias(false)
  }





  const [ visita_seleccionada, setVisitaSeleccionada ] = useState<any>(null)

  const [ show_ModalCargarAccesos, setModalCargarAccesos ] = useState<boolean>(false)
  const handleShow_ModalCargarAccesos = (visita:any) => {
    setVisitaSeleccionada(visita)
    setModalCargarAccesos(true)
  }
  const handleClose_ModalCargarAccesos = () => {
        busqueda()
    setModalCargarAccesos(false)
  }





  const [ show_ModalAsignarPresupuestoVisita, setModalAsignarPresupuestoVisita ] = useState<boolean>(false)
  const handleShow_ModalAsignarPresupuestoVisita = (visita:any) => {
    setVisitaSeleccionada(visita)
    setModalAsignarPresupuestoVisita(true)
  }
  const handleClose_ModalAsignarPresupuestoVisita = () => {
        busqueda()
    setModalAsignarPresupuestoVisita(false)
  }




  const [ show_ModalReporteEvidenciasVisuales, setModalReporteEvidenciasVisuales ] = useState<boolean>(false)
  const handleShow_ModalReporteEvidenciasVisuales = (tarea:any) => {
    setTareaSeleccionada(tarea)
    setModalReporteEvidenciasVisuales(true)
  }
  const handleClose_ModalReporteEvidenciasVisuales = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalReporteEvidenciasVisuales(false)
  }


 


const enviarFormulario = async (event:any,visita:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<p class="nm">¿Desea marcar la salida por parte del responsable de atenciòn?</p>
          <small>Se marcará la hora actual para la salida y la localización de la entrada</small>
          <p class="nm"><b>Folio: </b>${visita.folio}</p>
          <p class="nm"><b>Atendió: </b>${visita.idtecnico.nombres} ${visita.idtecnico.paterno} ${visita.idtecnico.materno} </p>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `servicio/pasar_a_reporte/${visita.uuid_servicio}` , { })

        if(res_){
          toast.success('El Servicio cambió el estatus')
          setTimeout(()=>{
            cargarMiReporte(currentPage===0?0:currentPage-1)
          },0)
        }
        else{
          toast.error('No pudimos realizar esa acción...')
        } 
        }
        catch(e:any){

          if (e.response?.status) {
            switch (e.response.status) {
              case 404:
                toast.error("No pudimos realizar la acción")
                break;
              default:
                toast.error(e.response?.data)
            }
          }
          else{
            toast.error("Problemas con la busqueda")
          }
          setSeEnvio(false)

        }
        
      }
     
}








      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Gestión de la Operación </h4>
                    <hr/>





        <div className="row">
        
            <div className="mb-2">
                <label className="form-label">Búsqueda</label>
                <div className="hstack gap-3">
                  <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                  <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                  <div className="vr"></div>
                  <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                </div>
            </div>
            
            <div className="mb-2">


                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="PROGRAMADA" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_0`} />
                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="EN PROCESO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ATENDIDA" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="REPORTE FINAL" checked={arreglo_estatus[3]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[3] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="ESPERA DE APROBACIÓN" checked={arreglo_estatus[4]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[4] = e.target.checked;  setEstatus(ne);}} id={`opciones_4`} />
                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="SERVICIO SUSPENDIDO" checked={arreglo_estatus[5]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[5] = e.target.checked;  setEstatus(ne);}} id={`opciones_5`} />
                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="FINALIZADO" checked={arreglo_estatus[6]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[6] = e.target.checked;  setEstatus(ne);}} id={`opciones_6`} />
                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="LOCAL CERRADO" checked={arreglo_estatus[7]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[7] = e.target.checked;  setEstatus(ne);}} id={`opciones_7`} />
                <Form.Check style={{fontSize:10}} inline name="group1" type="checkbox" label="REAGENDAR CITA" checked={arreglo_estatus[8]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[8] = e.target.checked;  setEstatus(ne);}} id={`opciones_8`} />

                <small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small>


            </div>
          
            <ul className="list-inline">

              <li className="list-inline-item"><button onClick={handleShow_ModalNuevaActividadLibre} type="button" className="btn btn-link">Nueva Actividad</button></li>
              
                {
                  // ["ROLE_RESPONSABLE_OPERACION","ROLE_ADMIN"].indexOf(user.rol) > -1 && (<li className="list-inline-item"><button onClick={handleShow_ModalConfigurarNuevoCalendario} type="button" className="btn btn-dark">Configurar Calendario</button></li>  )
                }

                {
                  ["ROLE_RESPONSABLE_OPERACION","ROLE_ADMIN","ROLE_RESPONSABLE_TECNICOS","ROLE_RESPONSABLE_LIMPIEZA"].indexOf(user.rol) > -1 && (<li className="list-inline-item"><button onClick={handleShow_ModalNuevaActividad} type="button" className="btn btn-link">Nueva Actividad Presupuestada</button></li>  )
                }

              <li className="list-inline-item"><button onClick={handleShow_ModalAsistencias} type="button" className="btn btn-link">Asistencias</button></li>


                {
                  ["ROLE_RESPONSABLE_OPERACION","ROLE_ADMIN"].indexOf(user.rol) > -1 && (<li className="list-inline-item"><a target="_blank" href={`${SERVER_}servicios/archivo_auditoria`} className="btn btn-link">Auditoria de Servicios <i className="fa fa-file-excel-o"/></a></li>  )
                }


            </ul>

        </div>


                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                         <h5>Resultados</h5>


                                          <table className="table table-sm">
                                            <thead>
                                              <tr>
                                                <th scope="col">Folio / Estatus</th> 
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Fecha</th>
                                                <th scope="col">Indicaciones</th>
                                                <th scope="col">Atiende</th>
                                                <th scope="col">Responsable</th>
                                                <th scope="col">Boutique / Sub Zona</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                              </tr>
                                            </thead>
                                            <tbody>{
                                                    resultados.map((visita:any)=>

                                                          <tr key={ Math.random().toString(36).substr(2, 9) }>
                                                            <td onClick={(e:any)=>{handleShow_ModalDetalleActividad(visita)}} className={`v pointer estatus_atencion_${visita.idestatus_atencion.id}`} style={{width:140}}>


                                                              <p className="nm"><b>{visita.folio}</b></p>
                                                              <p className="nm"><small>{visita.idestatus_atencion.estatus} { visita.firma_responsable && ( <i className="fa fa-check"/> ) }</small></p>

                                                              {
                                                                // JSON.stringify(visita.firma_responsable)
                                                              }


                                                            </td> 
                                                            <td onClick={(e:any)=>{ if(!visita.idpresupuesto){ handleShow_ModalAsignarPresupuestoVisita(visita) } }} className={`v tipo_orden_${visita.idtipo_orden.id}`} style={{width:160}}>
                                                              <p className="nm">
                                                              {
                                                                visita.idpresupuesto && (<i className="fa fa-money"/>)
                                                              }
                                                               {' '}
                                                              <small>{visita.idtipo_orden.tipo_orden}</small></p>
                                                            </td>

                                                            <td className="v" style={{width:200}}>
                                                              <p className="nm"><b><small>{moment(visita.fecha_programada).format('DD [DE] MMMM [DE] YYYY').toUpperCase()}</small></b></p>
                                                              <small>{obtenerDiaSemana(visita.fecha_programada).toUpperCase()}</small>
                                                            </td>
                                                            <td className="v" >
                                                              <p className="nm" style={{fontSize:10}}>{visita.indicaciones}</p>
                                                              {
                                                                // JSON.stringify(visita.calificacion_boutique)
                                                              }
                                                              {
                                                                visita.calificacion_boutique && (<span className={`calificacion_servicio_${visita.calificacion_boutique}`} style={{fontSize:11}}>{calificaciones[visita.calificacion_boutique]}</span>)
                                                              }
                                                              {' '}

                                                              {
                                                                // JSON.stringify(visita.idestatus_atencion.id)
                                                              }

                                                              {
                                                                [1].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                                    <i onClick={(e:any)=>{eliminarActividad(e,visita.uuid_servicio)}} className="fa fa-trash"/>
                                                                  )
                                                              }

                                                              
                                                            </td>

                                                            <td className="v"  style={{width:200}} >
                                                              <b>{visita.idtecnico.nombres}</b>
                                                              <p className="nm"><small> {visita.idtecnico.paterno} {visita.idtecnico.materno}</small></p>
                                                            </td>
                                                            <td className="v" style={{width:200}}>
                                                              { visita.idresponsable  && (<p className="nm"><b>{visita.idresponsable.nombres}</b> <br/><small> {visita.idresponsable.paterno} {visita.idresponsable.materno}</small></p>)}
                                                            </td>
                                                            <td className="v" style={{width:250}}>
                                                              <p className="nm">{visita.idboutique.nombre_boutique}</p>
                                                              <p className="nm"><small>{visita.idboutique.idsubarea.subarea}</small></p>
                                                            </td>
                                                            <td className="v" style={{width:40}}>
                                                            {
                                                              visita.idestatus_atencion.id === 1 && (
                                                                  <button onClick={(e:any)=>{handleShow_ModalEditarTareaMantenimiento(visita)}} type="button" className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                                )
                                                            }

                                                            {
                                                              visita.idestatus_atencion.id === 7 && (
                                                                    <Dropdown>
                                                                      <Dropdown.Toggle variant="primary" size="sm" id="dropdown-basic">
                                                                        <i className="fa fa-gear"/>
                                                                      </Dropdown.Toggle>

                                                                      <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={(e:any)=>{ window.open(`${SERVER_}reporte/hoja_servicio/${visita.uuid_servicio}`) }}>Reporte de Servicio <i className="fa fa-link"/></Dropdown.Item> 
                                                                        <Dropdown.Item onClick={(e:any)=>{handleShow_ModalReporteEvidenciasVisuales(visita)}} >Evidencias Visuales <i className="fa fa-cloud"/></Dropdown.Item> 
                                                                      </Dropdown.Menu>
                                                                    </Dropdown>
                                                                )
                                                            }

                                                            {
                                                              // moment().diff( moment(visita.fecha_programada) , 'days')
                                                            }
                                                            
                                                            {
                                                              ([2].indexOf(visita.idestatus_atencion.id) > -1 && moment().diff( moment(visita.fecha_programada) , 'days') >= 2) && (
                                                                  <button onClick={(e:any)=>{enviarFormulario(e,visita)}} type="button" className="btn btn-sm btn-light"><i className="fa fa-unlock"/></button>
                                                                )
                                                            }

                                                            {
                                                              // JSON.stringify(visita.fecha_fin_servicio)
                                                            }

                                                            {
                                                              ([3].indexOf(visita.idestatus_atencion.id) > -1 && moment().diff( moment(visita.fecha_programada) , 'days') >= 2 && !visita.fecha_fin_servicio) && (
                                                                  <button onClick={(e:any)=>{enviarFormulario(e,visita)}} type="button" className="btn btn-sm btn-primary"><i className="fa fa-unlock"/></button>
                                                                )
                                                            }



                                                            </td>
                                                            <td className="v" style={{width:40}}>
                                                                {
                                                                  [1,2].indexOf(visita.idestatus_atencion.id) > -1 && (
                                                                      <button onClick={(e:any)=>{handleShow_ModalCargarAccesos(visita)}} className="btn btn-link btn-sm"><small><i className="fa fa-cloud-upload"/></small></button>
                                                                    )
                                                                }
                                                            </td>
                                                          </tr>


                                                      )

                                                  }
                                            </tbody>
                                          </table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                    
                </div>
              </div>

            </div>






  {
    show_ModalActividadesNoConcluidas && (
      <ModalActividadesNoConcluidas show={show_ModalActividadesNoConcluidas} handleClose={handleClose_ModalActividadesNoConcluidas}  />
      )

  }







  {
    show_ModalNuevoCalendarioActividades && (
      <ModalNuevoCalendarioActividades show={show_ModalNuevoCalendarioActividades} handleClose={handleClose_ModalNuevoCalendarioActividades}  />
      )

  }


  {
    show_ModalNuevoCalendarioConservacion && (
      <ModalNuevoCalendarioConservacion show={show_ModalNuevoCalendarioConservacion} handleClose={handleClose_ModalNuevoCalendarioConservacion}  />
      )

  }








  {
    show_ModalDetalleActividad && (
      <ModalDetalleActividad show={show_ModalDetalleActividad} handleClose={handleClose_ModalDetalleActividad} tarea={tarea_seleccionada} />
      )

  }



  {
    show_ModalNuevaActividad && (
      <ModalNuevaActividad show={show_ModalNuevaActividad} handleClose={handleClose_ModalNuevaActividad}  />
      )

  }

  {
    show_ModalNuevaActividadLibre && (
      <ModalNuevaActividadLibre show={show_ModalNuevaActividadLibre} handleClose={handleClose_ModalNuevaActividadLibre}  />
      )

  }





  {
    show_ModalEditarTareaMantenimiento && (
      <ModalEditarVisita show={show_ModalEditarTareaMantenimiento} handleClose={handleClose_ModalEditarTareaMantenimiento} tarea={tarea_seleccionada} />
      )

  }









  {
    show_ModalConfigurarNuevoCalendario && (
      <ModalConfigurarNuevoCalendario show={show_ModalConfigurarNuevoCalendario} handleClose={handleClose_ModalConfigurarNuevoCalendario}  />
      )

  }






  {
    show_ModalAsistencias && (
      <ModalAsistencias show={show_ModalAsistencias} handleClose={handleClose_ModalAsistencias}  />
      )

  }




  {
    show_ModalCargarAccesos && (
      <ModalCargarAccesosOperacion show={show_ModalCargarAccesos} handleClose={handleClose_ModalCargarAccesos} visita={visita_seleccionada} />
      )

  }





  {
    show_ModalAsignarPresupuestoVisita && (
      <ModalAsignarPresupuestoVisita show={show_ModalAsignarPresupuestoVisita} handleClose={handleClose_ModalAsignarPresupuestoVisita} visita={visita_seleccionada}  />
      )

  }





  {
    show_ModalReporteEvidenciasVisuales && (
      <ModalReporteEvidenciasVisuales show={show_ModalReporteEvidenciasVisuales} handleClose={handleClose_ModalReporteEvidenciasVisuales} registro={tarea_seleccionada} tipo="REPORTE_VISITA" />
      )

  }




          </>

      );
  

}

export default Operacion
