import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'


import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';

import Select from 'react-select';


import moment from 'moment'
import 'moment/locale/es'


import { labelType } from '../../features/interfaces'

import { getStartAndEndDates, obtenerInicioFinSemanaActual, formatToTwoDecimals, calcularTiempoTranscurrido } from '../../services/funcionesPublicas'


import ModalNuevoIngreso from './ModalNuevoIngreso'
import ModalNuevoEgreso from './ModalNuevoEgreso'


import ModalAdministracionIngresoEgreso from './ModalAdministracionIngresoEgreso'

import ModalCuentasBancarias from './ModalCuentasBancarias'


import Dialog from '../../services/Dialogs'


import { SERVER_ } from  '../../config';



import AuthService from "../../features/auth/auth.service";



import ModalEditarRegistroIngreso from './ModalEditarRegistroIngreso'



registerLocale('es', es)

const Contabilidad = () =>{

  const dialog = new Dialog();

      const currentUser  = AuthService.getCurrentUser();



  // const [ sucursales, setSucursales ] = useState<any>([])
  // const cargarSucursales = async()=>{
  //   try{
      
  //     let response : any = await RequestService.getEndPoint('sucursales')
  //     // let suc : any = response.data
  //     let sucursales : any = response.data.map( (sucursal:any) =>{ return  { value :  sucursal.uuid, label : sucursal.nombre_sucursal } })
  //     setSucursales(sucursales)
  //   }
  //   catch(e){
  //     toast.error('Problemas al intentar cargar sucursales')
  //   }
  // }

  const [ egresos, setEgresos ] = useState<any>([])
  const [ ingresos, setIngresos ] = useState<any>([])
  const cargarIngresos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('catalogo_ingresos')
      let res : any = response.data.map( (reg:any) =>{ return  { value :  reg.uuid, label : reg.descripcion } })
      setIngresos(res)
    }
    catch(e){
      toast.error('Problemas al intentar cargar ingresos')
    }
  }
  const cargarEgresos = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint('catalogo_egresos')
      let res : any = response.data.map( (reg:any) =>{ return  { value :  reg.uuid, label : reg.descripcion } })
      setEgresos(res)
    }
    catch(e){
      toast.error('Problemas al intentar cargar egresos')
    }
  }


  // const [ departamentos, setDepartamentos] = useState<any>([])

  // const cargarDepartamentos = async() => {

  //   try{
  //     let response = await RequestService.getEndPoint('lista_deptos')
  //     let res : any = response.data.map( (reg:any) =>{ return  { value :  reg.uuid, label : reg.departamento } })
  //     setDepartamentos(res)
  //   }
  //   catch(e){
  //     toast.error('Tuvimos problemas al cargar')
  //   }

  // }



  const [ cuentas_bancarias, setCuentasBancarias] = useState<any>([])

  const cargarCuentasBancarias = async() => {

    try{
      let response = await RequestService.getEndPoint('cuentas_bancarias')
      let res : any = response.data.map( (reg:any) =>{ return  { value :  reg.uuid, label : `${reg.banco} ${reg.descripcion}` } })
      setCuentasBancarias(res)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }


  const [ formas_pago, setFormasPago] = useState<any>([])

  const cargarFormasPago = async() => {

    try{
      let response = await RequestService.getEndPoint('formas_pago')
      let res : any = response.data.map( (reg:any) =>{ return  { value :  reg.forma_pago, label : reg.forma_pago } })
      setFormasPago(res)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar')
    }

  }


      React.useEffect(() => {
        // cargarSucursales()
        cargarIngresos()
        cargarEgresos()
        // cargarDepartamentos()
        cargarMiReporte()

        cargarCuentasBancarias()
        cargarFormasPago()



      }, []);


const { inicioMes, finMes } = getStartAndEndDates(new Date());

      const defaultValue : labelType[] = []
      const defaultValueIngresos : labelType[] = []
      const defaultValueEgresos : labelType[] = []
      const defaultValueDepatamentos : labelType[] = []
      const defaultValueCuentasBancarias : labelType[] = []
      const defaultValueFormasPagos : labelType[] = []

      const [ etiquetas_seleccionadas, setEtiquetasSeleccionada ] = useState<any>([])
      const [ ingresos_seleccionadas, setIngresosSeleccionadas ] = useState<any>([])
      const [ egresos_seleccionadas, setEgresosSeleccionadas ] = useState<any>([])
      const [ departamentos_seleccionados, setDepartamentosSeleccionados ] = useState<any>([])
      const [ formas_pago_seleccionados, setFormasPagoSeleccionados ] = useState<any>([])
      const [ cuentas_bancarias_seleccionados, setCuentasBancariasSeleccionados ] = useState<any>([])


  const [ cargando_, setCargando ] = useState( true )


  const [ reporte, setReporte ] = useState<any>({
    listado : [],
    acumulado_diario : [],
  })

  const cargarMiReporte = async()=>{
    try{
      setCargando(true)
      // var uuid_sucursal = "mmm"
      let uuids_sucursales = etiquetas_seleccionadas.map( (sucursal:any)=> sucursal.value )
      let uuids_departamentos = departamentos_seleccionados.map( (sucursal:any)=> sucursal.value )
      let ingresos = ingresos_seleccionadas.map( (sucursal:any)=> sucursal.value )
      let egresos = egresos_seleccionadas.map( (sucursal:any)=> sucursal.value )

      let cuentas_bancarias = cuentas_bancarias_seleccionados.map( (sucursal:any)=> sucursal.value )
      let formas_pago = formas_pago_seleccionados.map( (sucursal:any)=> sucursal.value )

      var ED = endDate ? moment(endDate).format('YYYY-MM-DD') : null
      let response = await RequestService.getEndPointPost('reporte-ingresos-egresos/'+moment(startDate).format('YYYY-MM-DD')+'/'+ ED, { 
                                                                                                                                        sucursales : uuids_sucursales, 
                                                                                                                                        tipo : [].concat(ingresos,egresos), 
                                                                                                                                        departamentos : uuids_departamentos,
                                                                                                                                        formas_pago : formas_pago,
                                                                                                                                        cuentas_bancarias : cuentas_bancarias,
                                                                                                                                      } )
      setReporte(response.data)
      setCargando(false)

      setStartDate_(startDate)
      setEndDate_(endDate)
      // setEtiquetasSeleccionada_([...etiquetas_seleccionadas])
      // toast.success('La lista de Tramites del día '+fecha+' fue cargada')
    }
    catch(e){
      setCargando(false)
      toast.error('Problemas al intentar generar tu reporte. Contacta con los administradores del sistema')
    }
  }


  const [startDate, setStartDate] = useState(inicioMes);
  const [endDate, setEndDate] = useState(new Date());


  const [startDate_, setStartDate_] = useState(inicioMes);
  const [endDate_, setEndDate_] = useState(new Date());


  const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    // cargarMiReporte()
  };




  const [ show_ModalNuevoIngreso, setModalNuevoIngreso ] = useState<boolean>(false)
  const handleShow_ModalNuevoIngreso = () => {
    setModalNuevoIngreso(true)
  }
  const handleClose_ModalNuevoIngreso = () => {
    cargarMiReporte()
    setModalNuevoIngreso(false)
  }


  const [ show_ModalNuevoEgreso, setModalNuevoEgreso ] = useState<boolean>(false)
  const handleShow_ModalNuevoEgreso = () => {
    setModalNuevoEgreso(true)
  }
  const handleClose_ModalNuevoEgreso = () => {
    cargarMiReporte()
    setModalNuevoEgreso(false)
  }

const sumarMontosExcluyendoTipo = (arreglo:any, tipoExcluido:string)=> {
    return arreglo.reduce((acumulador:any, registro:any) => {
        if (registro.tipo === tipoExcluido) {
            return acumulador + registro.monto;
        }
        return acumulador;
    }, 0);
}




const confirmareliminarRegistro = async(e:any, uuid_registro:any) => {
    try{

        await dialog.open({
          accept: 'SI',
          cancel: 'NO',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: e.target,
          template: ``
        })
        let dialogo : any = await dialog.waitForUser()
        
        if(dialogo){
          let res = await RequestService.delete(`contabilidad/eliminar_registro/${uuid_registro}`)
          if(res.data){
            toast.success('Se actualizo el estatus del Trámite')
            cargarMiReporte()
          }
          
        }

    }
    catch(e){
      toast.error('Tuvimos problemas al intentar eliminar')
    }

  }






  const descargarReporte = async() => {
    //   let uuids_sucursales = etiquetas_seleccionadas.map( (sucursal:any)=> sucursal.value )
    //   let uuids_departamentos = departamentos_seleccionados.map( (sucursal:any)=> sucursal.value )
    //   let ingresos = ingresos_seleccionadas.map( (sucursal:any)=> sucursal.value )
    //   let egresos = egresos_seleccionadas.map( (sucursal:any)=> sucursal.value )
    //   let formas = formas_pago_seleccionados.map( (sucursal:any)=> sucursal.value )
    //   let cuentas = cuentas_bancarias_seleccionados.map( (sucursal:any)=> sucursal.value )
    //   var ED = endDate ? moment(endDate).format('YYYY-MM-DD') : null
    // let res = await RequestService.downloadFilePost('reporte-ingresos-egresos-excel/'+moment(startDate).format('YYYY-MM-DD')+'/'+ ED, { 
    //                                                                                                                                     sucursales : uuids_sucursales, 
    //                                                                                                                                     tipo : [].concat(ingresos,egresos), 
    //                                                                                                                                     departamentos : uuids_departamentos,
    //                                                                                                                                     formas_pago : formas,
    //                                                                                                                                     cuentas_bancarias : cuentas,
    //                                                                                                                                   }  )
    // const url = window.URL.createObjectURL(new Blob([res.data]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', `${Date.now()}.xls`);
    // document.body.appendChild(link);
    // link.click();
  }



  const [ vista_reporte, setVistaReporte ] = useState<string>('REPORTE_REGISTROS')
  const [ visualizacion, setVisualizacion ] = useState<string>('TODOS')




  const [ show_ModalAdministracionIngresoEgreso, setModalAdministracionIngresoEgreso ] = useState<boolean>(false)
  const handleShow_ModalAdministracionIngresoEgreso = () => {
    setModalAdministracionIngresoEgreso(true)
  }
  const handleClose_ModalAdministracionIngresoEgreso = () => {
        cargarIngresos()
        cargarEgresos()
    setModalAdministracionIngresoEgreso(false)
  }




  const [ show_ModalCuentasBancarias, setModalCuentasBancarias ] = useState<boolean>(false)
  const handleShow_ModalCuentasBancarias = () => {
    setModalCuentasBancarias(true)
  }
  const handleClose_ModalCuentasBancarias = () => {
        cargarCuentasBancarias()
    setModalCuentasBancarias(false)
  }





  const [ show_ModalEditarRegistroIngreso, setModalEditarRegistroIngreso ] = useState<boolean>(false)
  const [ registro_seleccionado, setRegisto ] = useState<any>(null)
  const handleShow_ModalEditarRegistroIngreso = (registro:any) => {
    setRegisto(registro)
    setModalEditarRegistroIngreso(true)
  }
  const handleClose_ModalEditarRegistroIngreso = () => {
            cargarMiReporte()
    setModalEditarRegistroIngreso(false)
  }




      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-12">

                    <div className="row">
                      <div className="col-6">
                          <h4>Finanzas</h4>
                      </div>
                      <div className="col-6">
                        <h4 className="text-right">INGRESOS / EGRESOS</h4>
                      </div>
                    </div>
                    <hr/>



                    <div className="row">
                      <div className="col-2">
                        
                            <div className="mb-3">
                              <label className="form-label">Rango de Fecha del Filtro</label>
                              <DatePicker
                              className="form-control"
                                onChange={onChangeRango}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                maxDate={new Date()}
                                dateFormat="dd-MMM-yyyy"
                              />
                            </div>




                            <div className="mb-3">
                              <label className="form-label">Filtro por Ingreso </label>
                                    <Select
                                      isMulti
                                      defaultValue={defaultValueIngresos}
                                      onChange={(opt:any,e:any)=>{ setIngresosSeleccionadas(opt) }}
                                      isClearable={true}
                                      name="colors"
                                      options={ingresos}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                    />
                            </div>


                            <div className="mb-3">
                              <label className="form-label">Filtro por Egreso </label>
                                  <Select
                                    isMulti
                                    defaultValue={defaultValueEgresos}
                                    onChange={(opt:any,e:any)=>{ setEgresosSeleccionadas(opt) }}
                                    isClearable={true}
                                    name="colors"
                                    options={egresos}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                            </div>




                            <div className="mb-3">
                              <label className="form-label">Cuentas Bancarias </label>
                                  <Select
                                    isMulti
                                    defaultValue={defaultValueCuentasBancarias}
                                    onChange={(opt:any,e:any)=>{ setCuentasBancariasSeleccionados(opt) }}
                                    isClearable={true}
                                    name="colors"
                                    options={cuentas_bancarias}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                            </div>



                            <div className="mb-3">
                              <label className="form-label">Forma de Pago </label>
                                  <Select
                                    isMulti
                                    defaultValue={defaultValueFormasPagos}
                                    onChange={(opt:any,e:any)=>{ setFormasPagoSeleccionados(opt) }}
                                    isClearable={true}
                                    name="colors"
                                    options={formas_pago}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                            </div>


                            <div className="mb-3">
                              <button type="button" onClick={(e:any)=>{cargarMiReporte()}} className="btn btn-secondary">Cargar</button>
                              {' '}
                              <button type="button" onClick={(e:any)=>{descargarReporte()}} className="btn btn-primary"><i className="fa fa-file-excel-o"/></button>
                            </div>



                            <h5>Opciones</h5>

                            <ul className="list-inline">
                              <li className="list-inline-item"><button onClick={handleShow_ModalNuevoEgreso} type="button" className="btn btn-link">Registrar Egreso</button></li>
                              <li className="list-inline-item"><button onClick={handleShow_ModalNuevoIngreso} type="button" className="btn btn-link">Registrar Ingreso</button></li>
                              {
                                ["ROLE_ADMIN"].indexOf(currentUser.rol) > -1 && (
                                      <React.Fragment>
                                        <li className="list-inline-item"><button onClick={handleShow_ModalAdministracionIngresoEgreso} type="button" className="btn btn-link"><i className="fa fa-gear"/>  Ingresos / Egresos</button></li>
                                        <li className="list-inline-item"><button onClick={handleShow_ModalCuentasBancarias} type="button" className="btn btn-link"><i className="fa fa-gear"/>  Cuentas Bancarias</button></li>
                                      </React.Fragment>
                                  )
                              }
                            </ul>



                      </div>
                      <div className="col-10">




                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                          {  (!cargando_) && (

                                <>


                                    <h4 className="mt-4-">
                                      {
                                        moment(startDate_).format('YYYY-MM-DD') !== moment(endDate_).format('YYYY-MM-DD') && ('REPORTE ENTRE '+ moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() + ' Y EL ' + moment(endDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase() )
                                      }
                                      {
                                        moment(startDate_).format('YYYY-MM-DD') === moment(endDate_).format('YYYY-MM-DD') && ('REPORTE PARA EL '+moment(startDate_).format('DD [DE] MMMM [DEL] YYYY').toUpperCase())
                                      }
                                    </h4>


                {
                  // JSON.stringify(reporte)
                }

                                {
                                  reporte.listado.length === 0 && (
                                        <React.Fragment>
                                          <p><i className="fa fa-warning"/> No tienes registros para ese filtro</p>
                                        </React.Fragment>
                                    )
                                }



                                {
                                  reporte.listado.length > 0 && (

                                    <React.Fragment>

                                          <div className="row">
                                            <div className="col-6">

                                                  <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Reporte</label>

                                                    <div className="form-check form-check-inline">
                                                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="REPORTE_REGISTROS" checked={vista_reporte==='REPORTE_REGISTROS'} onChange={(e:any)=>{setVistaReporte('REPORTE_REGISTROS')}} />
                                                      <label className="form-check-label" htmlFor="REPORTE_REGISTROS">Vista como Listado</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="REPORTE_ACUMULADOS" checked={vista_reporte==='REPORTE_ACUMULADOS'} onChange={(e:any)=>{setVistaReporte('REPORTE_ACUMULADOS')}} />
                                                      <label className="form-check-label" htmlFor="REPORTE_ACUMULADOS">Acumulados Diarios</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="REPORTE_ACUMULADO_TOTAL" checked={vista_reporte==='REPORTE_ACUMULADO_TOTAL'} onChange={(e:any)=>{setVistaReporte('REPORTE_ACUMULADO_TOTAL')}} />
                                                      <label className="form-check-label" htmlFor="REPORTE_ACUMULADO_TOTAL">Acumulados Total</label>
                                                    </div>

                                                    </div>

                                            </div>
                                            <div className="col-6">
                                              
                                                  <div className="mb-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Visualización</label>

                                                    <div className="form-check form-check-inline">
                                                      <input className="form-check-input" type="radio" name="visualizacion" id="TODOS" checked={visualizacion==='TODOS'} onChange={(e:any)=>{setVisualizacion('TODOS')}} />
                                                      <label className="form-check-label" htmlFor="TODOS">Ingresos y Egresos</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                      <input className="form-check-input" type="radio" name="visualizacion" id="INGRESOS" checked={visualizacion==='INGRESOS'} onChange={(e:any)=>{setVisualizacion('INGRESOS')}} />
                                                      <label className="form-check-label" htmlFor="INGRESOS">Ingresos</label>
                                                    </div>

                                                    <div className="form-check form-check-inline">
                                                      <input className="form-check-input" type="radio" name="visualizacion" id="EGRESOS" checked={visualizacion==='EGRESOS'} onChange={(e:any)=>{setVisualizacion('EGRESOS')}} />
                                                      <label className="form-check-label" htmlFor="EGRESOS">Egresos</label>
                                                    </div>  
                                              </div>

                                          </div>

                                      </div>

                                      {
                                        // visualizacion
                                      }

                                      {
                                        vista_reporte === 'REPORTE_ACUMULADO_TOTAL' && (
                                            <React.Fragment>

                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Concepto </th>
                                                      <th scope="col">Tipo </th>
                                                      <th scope="col">Cantidad</th>
                                                      {
                                                        (["TODOS","INGRESOS"].indexOf(visualizacion) > -1  ) && (
                                                            <th scope="col">Ingresos</th>
                                                          )
                                                      }
                                                      {
                                                        (["TODOS","EGRESOS"].indexOf(visualizacion) > -1   ) && (
                                                            <th scope="col">Egresos</th>
                                                          )
                                                      }

                                                    </tr>
                                                  </thead>
                                                  <tbody>

                                                    <tr>
                                                      <td className="v text-right" colSpan={ ["TODOS"].indexOf(visualizacion) > -1 ? 3 : 3 }>
                                                        <small><b>Total</b></small>
                                                      </td> 



                                                      {
                                                        ["TODOS","INGRESOS"].indexOf(visualizacion) > -1   && (
                                                            <React.Fragment>
                                                              <td>
                                                                <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.acumulado_diario,"INGRESO"))}</small></p>
                                                              </td>
                                                            </React.Fragment>
                                                          )
                                                      }
                                                      {
                                                        ["TODOS","EGRESOS"].indexOf(visualizacion) > -1 && (
                                                            <React.Fragment>
                                                              <td>
                                                                <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.acumulado_diario,"EGRESO"))}</small></p>
                                                              </td>
                                                            </React.Fragment>
                                                          )
                                                      }
                                                    </tr>

                                                    {
                                                      reporte.acumulado_total.map((registro:any,indice_acumulado_total:number)=>
                                                          <React.Fragment key={ indice_acumulado_total }>
                                                            

                                                                {
                                                                  (["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && registro.tipo=='INGRESO' ) && (


                                                                        <tr   style={{fontSize:12}}>
                                                                          <td  >
                                                                            <p className="nm"><small>{registro.tipo_descripcion}</small></p>
                                                                          </td>
                                                                          <td className="v" style={{width:250}}>
                                                                            <p className="nm">{registro.tipo}</p>
                                                                          </td>
                                                                          <td className="v" style={{width:150}}>
                                                                            <p className="nm">{registro.cantidad}</p>
                                                                          </td>
                                                                            {
                                                                              registro.tipo=='INGRESO' && (
                                                                                  <React.Fragment>
                                                                                      <td className="v" style={{width:140}}>
                                                                                        <p className="nm"><small>$ {formatToTwoDecimals(registro.monto)}</small></p> 
                                                                                      </td>
                                                                                      {
                                                                                        (["TODOS"].indexOf(visualizacion) > -1  ) && (
                                                                                            <td className="v" style={{width:140}}></td>
                                                                                          )
                                                                                      }
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        </tr>

                                                                    )
                                                                }

                                                                {
                                                                  (["TODOS","EGRESOS"].indexOf(visualizacion) > -1 && registro.tipo=='EGRESO' ) && (


                                                                        <tr   style={{fontSize:12}}>
                                                                          <td  >
                                                                            <p className="nm"><small>{registro.tipo_descripcion}</small></p>
                                                                          </td>
                                                                          <td className="v" style={{width:250}}>
                                                                            <p className="nm">{registro.tipo}</p>
                                                                          </td>
                                                                          <td className="v" style={{width:150}}>
                                                                            <p className="nm">{registro.cantidad}</p>
                                                                          </td>
                                                                            {
                                                                              registro.tipo=='EGRESO' && (
                                                                                  <React.Fragment>
                                                                                      {
                                                                                        (["TODOS"].indexOf(visualizacion) > -1  ) && (
                                                                                            <td className="v" style={{width:140}}></td>
                                                                                          )
                                                                                      }
                                                                                      <td className="v" style={{width:140}}>
                                                                                          <p className="nm"><small>$ {formatToTwoDecimals(registro.monto)}</small></p> 
                                                                                      </td>
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                        </tr>

                                                                    )
                                                                }

                                                          </React.Fragment>

                                                        )
                                                    }


                                                    <tr>
                                                      <td className="v text-right" colSpan={ ["TODOS"].indexOf(visualizacion) > -1 ? 3 : 3 }>
                                                        <small><b>Total</b></small>
                                                      </td> 



                                                      {
                                                        ["TODOS","INGRESOS"].indexOf(visualizacion) > -1   && (
                                                            <React.Fragment>
                                                              <td>
                                                                <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.acumulado_diario,"INGRESO"))}</small></p>
                                                              </td>
                                                            </React.Fragment>
                                                          )
                                                      }
                                                      {
                                                        ["TODOS","EGRESOS"].indexOf(visualizacion) > -1 && (
                                                            <React.Fragment>
                                                              <td>
                                                                <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.acumulado_diario,"EGRESO"))}</small></p>
                                                              </td>
                                                            </React.Fragment>
                                                          )
                                                      }
                                                    </tr>



                                                  </tbody>
                                                </table>


                                            </React.Fragment>
                                          )
                                      }




                                      {
                                        vista_reporte === 'REPORTE_ACUMULADOS' && (
                                            <React.Fragment>

                                                <table className="table">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">Fecha </th>
                                                      <th scope="col">Tipo </th>
                                                      <th scope="col">Cantidad</th>
                                                      {
                                                        ["TODOS","INGRESOS"].indexOf(visualizacion) > -1   && (
                                                            <th scope="col">Ingresos</th>
                                                          )
                                                      }
                                                      {
                                                        ["TODOS","EGRESOS"].indexOf(visualizacion) > -1 && (
                                                            <th scope="col">Egresos</th>
                                                          )
                                                      }
                                                      
                                                      
                                                    </tr>
                                                  </thead>
                                                  <tbody>

                                                    <tr>
                                                      <td className="v text-right" colSpan={3}>
                                                        <small><b>Total</b></small>
                                                      </td> 
                                                      {
                                                        ["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && (
                                                            <td>
                                                              <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.acumulado_diario,"INGRESO"))}</small></p>
                                                            </td>
                                                          )
                                                      }
                                                      {
                                                        ["TODOS","EGRESOS"].indexOf(visualizacion) > -1   && (
                                                            <td>
                                                              <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.acumulado_diario,"EGRESO"))}</small></p>
                                                            </td>
                                                          )
                                                      }
                                                    </tr>

                                                    {
                                                      reporte.acumulado_diario.map((registro:any,indice_acumlado_diario:number)=>
                                                          <React.Fragment key={ indice_acumlado_diario }>
                                                            
                                                                {
                                                                  (["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && registro.tipo=='INGRESO' ) && (

                                                                      <tr style={{fontSize:12}}>
                                                                        <td className="v" style={{width:250}} >
                                                                          <p className="nm"><b>{moment(registro.fecha_movimiento).format('YYYY-MM-DD')}</b></p>
                                                                          {
                                                                            // JSON.stringify(registro)
                                                                          }
                                                                        </td>
                                                                        <td  >
                                                                          <p className="nm"><small>{registro.tipo_descripcion}</small></p>
                                                                        </td>
                                                                        <td className="v" style={{width:250}}>
                                                                          <p className="nm">{registro.cantidad}</p>
                                                                        </td>
                                                                          {
                                                                            ["TODOS","INGRESOS"].indexOf(visualizacion) > -1   && (
                                                                                <React.Fragment>
                                                                                  <td className="v" style={{width:140}}>
                                                                                    {
                                                                                      registro.tipo=='INGRESO' && (
                                                                                          <p className="nm"><small>$ {formatToTwoDecimals(registro.monto)}</small></p>
                                                                                        )
                                                                                    }
                                                                                  </td>
                                                                                  {
                                                                                    (["TODOS"].indexOf(visualizacion) > -1  ) && (
                                                                                        <td className="v" style={{width:140}}></td>
                                                                                      )
                                                                                  }
                                                                                </React.Fragment>
                                                                              )
                                                                          }
                                                                      </tr>

                                                                    )
                                                                }

                                                                {
                                                                  (["TODOS","EGRESOS"].indexOf(visualizacion) > -1 && registro.tipo=='EGRESO' ) && (

                                                                      <tr style={{fontSize:12}}>
                                                                        <td className="v" style={{width:250}} >
                                                                          <p className="nm"><b>{moment(registro.fecha_movimiento).format('YYYY-MM-DD')}</b></p>
                                                                          {
                                                                            // JSON.stringify(registro)
                                                                          }
                                                                        </td>
                                                                        <td  >
                                                                          <p className="nm"><small>{registro.tipo_descripcion}</small></p>
                                                                        </td>
                                                                        <td className="v" style={{width:250}}>
                                                                          <p className="nm">{registro.cantidad}</p>
                                                                        </td>
                                                                          {
                                                                            ["TODOS","EGRESOS"].indexOf(visualizacion) > -1 && (
                                                                                <React.Fragment>
                                                                                  {
                                                                                    (["TODOS"].indexOf(visualizacion) > -1  ) && (
                                                                                        <td className="v" style={{width:140}}></td>
                                                                                      )
                                                                                  }
                                                                                  <td className="v" style={{width:140}}>
                                                                                    {
                                                                                      registro.tipo=='EGRESO' && (
                                                                                          <p className="nm"><small>$ {formatToTwoDecimals(registro.monto)}</small></p>
                                                                                        )
                                                                                    }
                                                                                  </td>
                                                                                </React.Fragment>
                                                                              )
                                                                          }
                                                                      </tr>

                                                                    )
                                                                }


                                                          </React.Fragment>

                                                        )
                                                    }

                                                    <tr>
                                                      <td className="v text-right" colSpan={3}>
                                                        <small><b>Total</b></small>
                                                      </td> 
                                                      {
                                                        ["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && (
                                                            <td>
                                                              <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.acumulado_diario,"INGRESO"))}</small></p>
                                                            </td>
                                                          )
                                                      }
                                                      {
                                                        ["TODOS","EGRESOS"].indexOf(visualizacion) > -1   && (
                                                            <td>
                                                              <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.acumulado_diario,"EGRESO"))}</small></p>
                                                            </td>
                                                          )
                                                      }
                                                    </tr>

                                                  </tbody>
                                                </table>


                                            </React.Fragment>
                                          )
                                      }






                                      {
                                        vista_reporte === 'REPORTE_REGISTROS' && (
                                            <React.Fragment>
                                              
                                                <table className="table table-bordered-">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col" style={{fontSize:9}}>Fecha de Movimiento / Descripción</th>
                                                      <th scope="col"></th>
                                                      <th scope="col" style={{fontSize:9}}>Referencia del Movimiento </th>
                                                      <th scope="col" style={{fontSize:9}}>Número de Pedido </th>
                                                      <th scope="col" style={{fontSize:9}}>Contra Recibo</th>
                                                      <th scope="col" style={{fontSize:9}}>Fecha de Pedido </th>
                                                      <th scope="col" style={{fontSize:9}}>Número de Factura</th>
                                                      <th scope="col">Tipo</th>
                                                      <th scope="col">Boutique / Período</th>
                                                      <th scope="col">Banco / Forma de Pago</th>
                                                      {
                                                        ["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && (
                                                            <th scope="col">Ingresos</th>
                                                          )
                                                      }
                                                      {
                                                        ["TODOS","EGRESOS"].indexOf(visualizacion) > -1   && (
                                                            <th scope="col">Egresos</th>
                                                          )
                                                      }
                                                    </tr>
                                                  </thead>
                                                  <tbody>

                                                    <tr>
                                                      <td className="v text-right" colSpan={ ["TODOS"].indexOf(visualizacion) > -1 ? 10 : 11 }>
                                                        <small><b>Total</b></small>
                                                      </td> 
                                                      {
                                                        ["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && (
                                                            <td>
                                                              <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.listado,"INGRESO"))}</small></p>
                                                            </td>
                                                          )
                                                      }
                                                      {
                                                        ["TODOS","EGRESOS"].indexOf(visualizacion) > -1   && (
                                                            <td>
                                                              <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.listado,"EGRESO"))}</small></p>
                                                            </td>
                                                          )
                                                      }
                                                      <td></td>
                                                      <td></td>
                                                    </tr>

                                                    {
                                                      reporte.listado.map((registro:any)=>
                                                        <React.Fragment key={ Math.random().toString(36).substr(2, 9) }>

                                                            {
                                                                  (["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && registro.tipo=='INGRESO' ) && (
                                                                  <React.Fragment>

                                                                      <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                                        <td className="v" style={{width:250}} >
                                                                          <p className="nm"><b>{moment(registro.fecha_movimiento).format('YYYY-MM-DD')}</b> </p>
                                                                          <small>Creado {calcularTiempoTranscurrido(registro.fecha_creacion)}</small>
                                                                          <p className="nm"><small>{registro.descripcion_registro}</small></p>
                                                                          {
                                                                            // JSON.stringify(registro)
                                                                          }
                                                                        </td>
                                                                        <td className="v" style={{width:40}}>
                                                                        {
                                                                          // JSON.stringify(registro)
                                                                        }
                                                                        {
                                                                          (registro.archivo !== null && registro.archivo !== '') && (
                                                                          <a target="_blank" href={`${SERVER_}contabilidad/archivo/${registro.uuid}`}><i className="fa fa-file-o"/></a>
                                                                          )
                                                                        }
                                                                        </td>
                                                                        <td className="v" style={{width:70}}>
                                                                          <p className="nm" style={{fontSize:10}}>{registro.referencia_movimiento}</p>
                                                                        </td>
                                                                        <td className="v" style={{width:70}}>
                                                                          <p className="nm" style={{fontSize:10}}>{registro.numero_pedido}</p>
                                                                        </td>
                                                                        <td className="v" style={{width:70}}>
                                                                          <p className="nm" style={{fontSize:10}}>{registro.contra_recibo}</p>
                                                                        </td>
                                                                        <td className="v" style={{width:70}}>
                                                                          <p className="nm" style={{fontSize:10}}>{registro.fecha_pedido === "0000-00-00" ? '' : moment(registro.fecha_pedido).format('DD-MM-YYYY')}</p>

                                                                          {
                                                                            // JSON.stringify(registro)
                                                                          }
                                                                        </td>
                                                                        <td className="v" style={{width:70}}>
                                                                          <p className="nm" style={{fontSize:10}}>{registro.numero_factura}</p>
                                                                        </td>
                                                                        <td className="v" >
                                                                          <p className="nm"><b>{registro.tipo}</b></p>
                                                                          <p className="nm"><small>{registro.tipo_descripcion}</small></p>
                                                                        </td>
                                                                        <td className="v" style={{width:300}}>
                                                                          {
                                                                            registro.idboutique && (<p className="nm"><small>{registro.nombre_boutique}</small></p>)
                                                                          }
                                                                          <p className="nm"><small>DEL {moment(registro.fecha_inicio).format('DD/MMM/YYYY').replace('.','').toUpperCase()} AL {moment(registro.fecha_fin).format('DD/MMM/YYYY').replace('.','').toUpperCase()}</small></p>

                                                                        </td>
                                                                        <td className="v" style={{width:250}}>
                                                                          <p className="nm">{registro.banco} - {registro.descripcion_cuenta}</p>
                                                                          <p className="nm"><small><b>{registro.forma_pago}</b></small></p>
                                                                        </td>

                                                                              {
                                                                                ["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && (
                                                                                                <td className="v" style={{width:140}}>
                                                                                                  {
                                                                                                    registro.tipo=='INGRESO' && (
                                                                                                        <p className="nm"><small>$ {formatToTwoDecimals(registro.monto)}</small></p>
                                                                                                      )
                                                                                                  }
                                                                                                </td>
                                                                                  )
                                                                              }
                                                                              {
                                                                                ["TODOS","EGRESOS"].indexOf(visualizacion) > -1   && (
                                                                                                <td className="v" style={{width:140}}>
                                                                                                  {
                                                                                                    registro.tipo=='EGRESO' && (
                                                                                                        <p className="nm"><small>$ {formatToTwoDecimals(registro.monto)}</small></p>
                                                                                                      )
                                                                                                  }
                                                                                                </td>
                                                                                  )
                                                                              }



                                                                        <td className="v" style={{width:40}}>
                                                                            <button onClick={(e:any)=>{confirmareliminarRegistro(e,registro.uuid)}} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                                                        </td>
                                                                        <td className="v" style={{width:40}}>
                                                                            <button onClick={(e:any)=>{handleShow_ModalEditarRegistroIngreso(registro)}} className="btn btn-sm btn-outline-warning"><i className="fa fa-edit"/></button>
                                                                        </td>
                                                                      </tr>

                                                                  </React.Fragment> 
                                                                )
                                                            }
                                                            {
                                                                  (["TODOS","EGRESOS"].indexOf(visualizacion) > -1 && registro.tipo=='EGRESO' ) && (
                                                                  <React.Fragment>

                                                                      <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                                        <td className="v" style={{width:250}} >
                                                                          <p className="nm"><b>{moment(registro.fecha_movimiento).format('YYYY-MM-DD')}</b> <small>{calcularTiempoTranscurrido(registro.fecha_creacion)}</small></p>
                                                                          <p className="nm"><small>{registro.descripcion_registro}</small></p>
                                                                          {
                                                                            // JSON.stringify(registro)
                                                                          }
                                                                        </td>
                                                                        <td className="v" colSpan={5}  >
                                                                        </td>
                                                                        <td className="v" style={{width:40}}>
                                                                        {
                                                                          (registro.archivo !== null && registro.archivo !== '') && (
                                                                          <a target="_blank" href={`${SERVER_}contabilidad/archivo/${registro.uuid}`}><i className="fa fa-file-o"/></a>
                                                                          )
                                                                        }
                                                                        </td>
                                                                        <td className="v" >
                                                                          <p className="nm"><b>{registro.tipo}</b></p>
                                                                          <p className="nm"><small>{registro.tipo_descripcion}</small></p>
                                                                        </td>
                                                                        <td className="v" style={{width:300}}>
                                                                          {
                                                                            registro.idboutique && (<p className="nm"><small>{registro.nombre_boutique}</small></p>)
                                                                          }
                                                                          <p className="nm"><small>DEL {moment(registro.fecha_inicio).format('DD/MMM/YYYY').replace('.','').toUpperCase()} AL {moment(registro.fecha_fin).format('DD/MMM/YYYY').replace('.','').toUpperCase()}</small></p>

                                                                        </td>
                                                                        <td className="v" style={{width:250}}>
                                                                          <p className="nm">{registro.banco} - {registro.descripcion_cuenta}</p>
                                                                          <p className="nm"><small><b>{registro.forma_pago}</b></small></p>
                                                                        </td>

                                                                              {
                                                                                ["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && (
                                                                                                <td className="v" style={{width:140}}>
                                                                                                  {
                                                                                                    registro.tipo=='INGRESO' && (
                                                                                                        <p className="nm"><small>$ {formatToTwoDecimals(registro.monto)}</small></p>
                                                                                                      )
                                                                                                  }
                                                                                                </td>
                                                                                  )
                                                                              }
                                                                              {
                                                                                ["TODOS","EGRESOS"].indexOf(visualizacion) > -1   && (
                                                                                                <td className="v" style={{width:140}}>
                                                                                                  {
                                                                                                    registro.tipo=='EGRESO' && (
                                                                                                        <p className="nm"><small>$ {formatToTwoDecimals(registro.monto)}</small></p>
                                                                                                      )
                                                                                                  }
                                                                                                </td>
                                                                                  )
                                                                              }



                                                                        <td className="v" style={{width:40}}>
                                                                            <button onClick={(e:any)=>{confirmareliminarRegistro(e,registro.uuid)}} className="btn btn-sm btn-danger"><i className="fa fa-trash"/></button>
                                                                        </td>
                                                                        <td className="v" style={{width:40}}>

                                                                        </td>
                                                                      </tr>

                                                                  </React.Fragment> 
                                                                )
                                                            }

                                                        </React.Fragment>

                                                        )
                                                    }

                                                    <tr>
                                                      <td className="v text-right" colSpan={ ["TODOS"].indexOf(visualizacion) > -1 ? 10 : 11 }>
                                                        <small><b>Total</b></small>
                                                      </td> 
                                                      {
                                                        ["TODOS","INGRESOS"].indexOf(visualizacion) > -1 && (
                                                            <td>
                                                              <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.listado,"INGRESO"))}</small></p>
                                                            </td>
                                                          )
                                                      }
                                                      {
                                                        ["TODOS","EGRESOS"].indexOf(visualizacion) > -1   && (
                                                            <td>
                                                              <p className="nm"><small>$ {formatToTwoDecimals(sumarMontosExcluyendoTipo(reporte.listado,"EGRESO"))}</small></p>
                                                            </td>
                                                          )
                                                      }
                                                      <td></td>
                                                      <td></td>
                                                    </tr>



                                                  </tbody>
                                                </table>


                                            </React.Fragment>
                                          )
                                      }


                                    </React.Fragment>


                                    )
                                }




                                </>

                             )
                        }




                        
                      </div>
                    </div>
























                    
                </div>
              </div>

            </div>





  {
    show_ModalNuevoIngreso && (
      <ModalNuevoIngreso show={show_ModalNuevoIngreso} handleClose={handleClose_ModalNuevoIngreso}  />
      )

  }



  {
    show_ModalNuevoEgreso && (
      <ModalNuevoEgreso show={show_ModalNuevoEgreso} handleClose={handleClose_ModalNuevoEgreso}  />
      )

  }



  {
    show_ModalAdministracionIngresoEgreso && (
      <ModalAdministracionIngresoEgreso show={show_ModalAdministracionIngresoEgreso} handleClose={handleClose_ModalAdministracionIngresoEgreso}  />
      )

  }


  {
    show_ModalCuentasBancarias && (
      <ModalCuentasBancarias show={show_ModalCuentasBancarias} handleClose={handleClose_ModalCuentasBancarias}  />
      )

  }





  {
    show_ModalEditarRegistroIngreso && (
      <ModalEditarRegistroIngreso show={show_ModalEditarRegistroIngreso} handleClose={handleClose_ModalEditarRegistroIngreso} registro={registro_seleccionado} />
      )

  }



          </>


      );
  

}

export default Contabilidad
