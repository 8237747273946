import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal } from 'react-bootstrap';
import { Form, Col, Row } from 'react-bootstrap'

import { Formik, Field, FieldArray  } from 'formik';
import * as yup from 'yup';


import moment from 'moment'

import { toast } from 'react-toastify'


import SignatureCanvas from 'react-signature-canvas'

import { sonValoresIguales, obtenerDiaSemana, ordenarPorTipo, tipoActividadLabel, labelUnidadMedida, formatearNumero, calcularTamanoEnKB } from '../../services/funcionesPublicas'

import Dialog from '../../services/Dialogs'

import './canvas.scss'





 

const AgregarMaterialesActividades = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, actividad, material_actividades, setMaterialActividades } = params


  const [ materiales, setMateriales ] = useState<any>([...material_actividades])


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          descripcion : yup.string().required(mensajes_validacion_generico),
          idunidad_medida : yup.string().required(mensajes_validacion_generico),
          cantidad : yup.number().required(mensajes_validacion_generico),
  });




  React.useEffect(() => {
    cargarTipo_unidad_medida()
  }, []);



  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }


  const eliminar = (indice:number) =>{
    let a = [...materiales]; 
    a.splice(indice,1); 
    setMateriales(a)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Materiales Usados en el Servicio</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {
            // JSON.stringify(materiales)
          }



                    <p className="nm"><small>Materiales Utilizados en el Servicio</small></p>


                  {
                    materiales.length > 0 && (
                      <table className="table table-sm mt-2" style={{fontSize:12}}>
                        <thead>
                          <tr>
                            <th scope="col">Descripción</th>
                            <th scope="col">Unidad</th>
                            <th scope="col">Cantidad</th>
                            <th scope="col"> </th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          [...materiales].sort(ordenarPorTipo).map((material:any,index_material:number)=>


                          <tr key={ index_material }> 
                            <td>
                              <p className="nm"><small>{material.descripcion}</small></p>
                            </td>
                            <td className="v" style={{width:120}}>
                              <p className="nm"><small>{labelUnidadMedida(parseInt(material.idunidad_medida),tipo_unidad_medida)}</small></p>
                            </td>
                            <td className="v" style={{width:80}}>
                              <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                            </td> 
                            <td className="v" style={{width:30}}>
                                <i onClick={(e:any)=>{ eliminar(index_material) }} className="fa fa-trash pointer"/>
                            </td> 
                          </tr>

                            )
                        }


                        </tbody>
                      </table>
                      )
                  }

                  {
                    materiales.length === 0 && (
                          <div className="alert alert-light">
                            No has agregado materiales usados en esta actividad
                          </div>
                      )
                  }






                          
    <Formik
      validationSchema={schema}
      onSubmit={(values,{ resetForm })=>{
        setMateriales([...materiales, {...values} ])
        resetForm()

      }}
      initialValues={{
            descripcion : "",
            idunidad_medida : "",
            cantidad : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form-y' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="descripcion">
              <Form.Label>Descripcion del Material</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="descripcion"
                value={values.descripcion}
                onChange={(e)=>{ setFieldValue('descripcion', e.target.value ) }}
                onBlur={(e)=>{ setFieldValue('descripcion', e.target.value.toUpperCase() ) }}
                style={{textTransform:'uppercase'}}
                isInvalid={!!errors.descripcion}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.descripcion}</>} </Form.Control.Feedback>
            </Form.Group>

 
 


            <div className="row">
                <Form.Group as={Col} sm={8} className="mb-3" controlId="idunidad_medida">
                  <Form.Label>Unidad Medida</Form.Label>
                  <Form.Select value={values.idunidad_medida} required onChange={(e)=>{ setFieldValue('idunidad_medida', e.target.value ) }}  isInvalid={!!errors.idunidad_medida}>
                    <option value={''} >Selecciona una unidad de medida</option>
                          {
                            tipo_unidad_medida.map( (unidad_medida:any,indice_tipo_unidad_medida:number) =>
                              <React.Fragment key={indice_tipo_unidad_medida}>
                                        
                                        {
                                          unidad_medida.tipo === 0 && (<option selected={unidad_medida.id===values.idunidad_medida} value={unidad_medida.id}> {unidad_medida.unidad_medida}</option>)
                                        }
                                        {
                                          actividad.tipo===3 && (<option selected={unidad_medida.id===values.idunidad_medida} value={unidad_medida.id}> {unidad_medida.unidad_medida}</option>)
                                        }
                              </React.Fragment>
                            )
                          }
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{<>{errors.idunidad_medida}</>} </Form.Control.Feedback>
                </Form.Group>

              <Form.Group as={Col} sm={4} className="mb-3" controlId="cantidad">
                <Form.Label>Cantidad</Form.Label>
                <Form.Control 
                  required
                  name="cantidad"
                  value={values.cantidad}
                  onChange={(e)=>{ setFieldValue('cantidad', parseFloat(e.target.value) ) }}
                  isInvalid={!!errors.cantidad}
                />
                <Form.Control.Feedback type="invalid"> {<>{errors.cantidad}</>} </Form.Control.Feedback>
              </Form.Group>
            </div>
 
 



          
            <Form.Group className="mb-3" controlId="actividad">
                <Button variant="success" form='my-form-y' type="submit"><i className="fa fa-plus"/></Button>
            </Form.Group>



        </Form>
      )}
    </Formik>





        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={(e:any)=>{ setMaterialActividades(materiales); handleClose()  }} type="button">Ok</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
 



const AgregarActividadesRealizadas = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, actividades, setActividades } = params


  const [ actividades_form, setActividadesForm ] = useState<any>([...actividades])


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          actividad : yup.string().required(mensajes_validacion_generico),
  });

  const eliminar = (indice:number) =>{
    let a = [...actividades_form]; 
    a.splice(indice,1); 
    setActividadesForm(a)
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Agregar Actividades Realizadas</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(actividades_form)
          }

          <small>Actividades</small>

          {
            actividades_form.length > 0 && (
                <ol>
                  {
                    actividades_form.map((actividad:any, indice_actividad:number)=><li key={indice_actividad}>{actividad}  <i onClick={(e:any)=>{ eliminar(indice_actividad) }} className="fa fa-trash pointer"/></li>)
                  }
                </ol>
              )
          }





    <Formik
      validationSchema={schema}
      onSubmit={(values,{ resetForm })=>{
        setActividadesForm([...actividades_form, values.actividad.trim() ])
        resetForm()

      }}
      initialValues={{
            actividad : ""
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        resetForm,
      }) => (
        <Form id='my-form-x' noValidate onSubmit={handleSubmit}>


            <Form.Group className="mb-3" controlId="actividad">
              <Form.Label>Actividad para Agregar</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="actividad"
                value={values.actividad}
                onChange={(e)=>{ setFieldValue('actividad', e.target.value ) }}
                onBlur={(e)=>{ setFieldValue('actividad', e.target.value.toUpperCase() ) }}
                style={{textTransform:'uppercase'}}
                isInvalid={!!errors.actividad}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.actividad}</>} </Form.Control.Feedback>
            </Form.Group>

          
            <Form.Group className="mb-3" controlId="actividad">
                <Button variant="success" form='my-form-x' type="submit">Agregar Actividad <i className="fa fa-save"/></Button>
            </Form.Group>



        </Form>
      )}
    </Formik>






        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" onClick={(e:any)=>{setActividades(actividades_form);handleClose()}} type="button">Agregar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}








const ModalReporteSalidaActividad = ( params : any ) => {



  const user = AuthService.getCurrentUser()

  const { show, handleClose, handleCloseRefresh,  tarea, setActualizaLista } = params


  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
    detalles_atencion : yup.string() ,
    detalles_boutique : yup.string() ,
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: '<p>Reportar la entrega y finalización del servicio</p>'
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{


            let size_kb_min = 3.2
              // console.log(calcularTamanoEnKB(canvas.toDataURL()))

            if( calcularTamanoEnKB(canvas.toDataURL()) < size_kb_min ){

              toast.error('Verifica la firma de salida. Es importante dejar una firma legible.',{ icon : '🚨' })
              return 

            }

            // if( actividades.length === 0 ){
            //   toast.error('Debes agregar por lo menos una actividad al reporte de entrega.',{ icon : '🚨' })
            //   return 
            // }

              // return 

            let actividades_realizadas = []


            // let actividades_realizadas = tareas_trabajadas

            // if([1,2,3].indexOf(tarea.idtipo_orden.id) > -1 ){
            //   actividades_realizadas = {
            //     equipos_criticos : tarea.idboutique.equipos_criticos,
            //     equipos_atendidos : tareas_trabajadas.mantenimiento
            //   }
            // }



            if([1,2,3,8].indexOf(tarea.idtipo_orden.id) > -1){
              let equipos = []
              for(let equipo of equipos_criticos){
                if(equipo.seleccionado) equipos.push(equipo)
              }
              actividades_realizadas = equipos
            }



            if([4,5,6,9].indexOf(tarea.idtipo_orden.id) > -1){
              // let equipos = []
              // for(let equipo of equipos_criticos){
              //   if(equipo.seleccionado) equipos.push(equipo)
              // }
              actividades_realizadas = tarea.material_actividades
            }




          setSeEnvio(true)
          let res_ = await RequestService.create( `servicio/reportar_salida/${tarea.uuid}` , { ...registro, firma_salida : canvas.toDataURL(), actividades_realizadas : actividades_realizadas, actividades_reporte : actividades, material_actividades : material_actividades })




          if(res_){
            toast.success('La Salida se reportó correctamente')
            setActualizaLista(true)
            // setTimeout(()=>{
              handleCloseRefresh()
              setSeEnvio(false)
            // },500)
          }
          else{
            toast.error('Problemas...')
          } 


          }
          catch(e:any){
              setSeEnvio(false)
            toast.error(e.response.data)
            // toast.error(e.response.data)
            console.log(e)
            // console.log(e.response.data)
          } 
          
        }
       
  }



  const [ canvas, sigCanvas ] = useState<any>({})
  const [ tiene_algo, setTieneAlgo ] = useState(false)

  const borrar = () =>{
    canvas.clear()
    setTieneAlgo(false)
  }



const generarArregloTrue = (longitud:number)=> {
    return new Array(longitud).fill(true);
}

  const returnActividadDelDia = ()=>{
    let act = { realizada : false, actividad : "-" }
    // if(obtenerDiaSemana(tarea.fecha_programada)==='Lunes') act.actividad = tarea.idboutique.conservacion_instrucciones_por_dia.lunes
    // if(obtenerDiaSemana(tarea.fecha_programada)==='Martes') act.actividad = tarea.idboutique.conservacion_instrucciones_por_dia.martes
    // if(obtenerDiaSemana(tarea.fecha_programada)==='Miércoles') act.actividad = tarea.idboutique.conservacion_instrucciones_por_dia.miercoles
    // if(obtenerDiaSemana(tarea.fecha_programada)==='Jueves') act.actividad = tarea.idboutique.conservacion_instrucciones_por_dia.jueves
    // if(obtenerDiaSemana(tarea.fecha_programada)==='Viernes') act.actividad = tarea.idboutique.conservacion_instrucciones_por_dia.viernes
    // if(obtenerDiaSemana(tarea.fecha_programada)==='Sábado') act.actividad = tarea.idboutique.conservacion_instrucciones_por_dia.sabado
    // if(obtenerDiaSemana(tarea.fecha_programada)==='Domingo') act.actividad = tarea.idboutique.conservacion_instrucciones_por_dia.domingo
    return act
  }




  // const [ actividades_realizadas ]

  const [ tipo_unidad_medida, setTipo_unidad_medida ] = useState<any>([])
  const cargarTipo_unidad_medida = async()=>{
    try{
      
      let response : any = await RequestService.getEndPoint(`lista/tipo_unidad_medida`)
      setTipo_unidad_medida(response.data)
    }
    catch(e){
      toast.error('Problemas al intentar cargar')
    }
  }

 



  const [ ficha_para_reporte, setFichaReporte ] = useState<any>([])
  const cargarReporte_evidencia_visual = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`ficha_para_reporte/${tarea.uuid}`)
      setFichaReporte(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarReporte_evidencia_visual()
    cargarTipo_unidad_medida()
  }, []);



  const [ se_convirtio_lista, setSeConvitio ] = useState<boolean>(false)
  const [ la_lista, setLaLista ] = useState<any>([])







  // const [ equipos_criticos, setEquiposCriticos ] = useState<any>([])
  const [ equipos_criticos, setEquiposCriticos ] = useState<any>( tarea.idboutique.equipos_criticos.map((equipo:any)=>{  return  { ...equipo, comentarios : "", seleccionado : false, check_list_bitacoras : equipo.check_list_bitacoras , instrucciones_mantenimiento : equipo.instrucciones_mantenimiento.map((instruccion:any)=>{ return { seleccionado : false, instruccion : instruccion } }) }   }) )



  const [ mostrar_solo_selccionadas, setMostrarSoloSeleccionada ] = useState<any>(true)





  const [ tareas_trabajadas, setTareasTrabajadas ] = useState<any>({
    // solicitadas : generarArregloTrue(tarea.idpresupuesto ? tarea.idpresupuesto.actividades_propuestas.length : 0),
    // mantenimiento : generarArregloTrue(tarea.idboutique.equipos_criticos.length),
    limpieza : [],
    mantenimiento : [],
    conservacion : [],
    // limpieza : [ returnActividadDelDia(), ...tarea.idboutique.limpieza_instrucciones.map((actividad:any)=>{ return { realizada : false, actividad : actividad } }) ]
  })






  const [ actividades, setActividades ] = useState<any>([])

  const [ show_AgregarActividadesRealizadas, setAgregarActividadesRealizadas ] = useState<boolean>(false)
  const handleShow_AgregarActividadesRealizadas = () => {
    setAgregarActividadesRealizadas(true)
  }
  const handleClose_AgregarActividadesRealizadas = () => {
    setAgregarActividadesRealizadas(false)
  }





  const [ material_actividades, setMaterialActividades ] = useState<any>([])




  const [ show_AgregarMaterialesActividades, setAgregarMaterialesActividades ] = useState<boolean>(false)
  const handleShow_AgregarMaterialesActividades = () => {
    setAgregarMaterialesActividades(true)
  }
  const handleClose_AgregarMaterialesActividades = () => {
    setAgregarMaterialesActividades(false)
  }

  const hayAlgunSeleccionado = (arreglo:any) => {
    return arreglo.some((objeto:any) => objeto.seleccionado === true);
  }

  const desabilitarBoton = () =>{
    let desabilitar = false

    if([1,2,3,8].indexOf(tarea.idtipo_orden.id) > -1){
      if(equipos_criticos.length === 0) desabilitar = true
      if(actividades.length === 0) desabilitar = true
      if(!hayAlgunSeleccionado(equipos_criticos)) desabilitar = true
    }
    if([4,5,6,9].indexOf(tarea.idtipo_orden.id) > -1){
     if(actividades.length === 0) desabilitar = true
    }

    return desabilitar
  }



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header className={`tipo_orden_${tarea.idtipo_orden.id}`} closeButton>
          <Modal.Title>Reporte de Salida</Modal.Title>
        </Modal.Header>

            <div className={`  text-center tipo_orden_${tarea.idtipo_orden.id}`}>
              <small>Tipo de Servicio</small>
              <h5>{tarea.idtipo_orden.tipo_orden}</h5>
            </div>

        <Modal.Body>



 

          <div className="row">
            <div className="col-sm-6">
              <small>Folio</small>
              <h6>{tarea.folio}</h6>
            </div>
            <div className="col-sm-6">
                <small>Fecha</small>
                <h6>{moment(tarea.fecha_programada).utc().format('DD [de] MMMM [de] YYYY').toUpperCase()}</h6>
            </div>
          </div>


          <div className="row">
            <div className="col-sm-12">
              <small>Boutique</small>
              <h5>{tarea.idboutique.nombre_boutique}</h5>
              <p>{tarea.idboutique.direccion}</p>
            </div>
          </div>


              <small>Indicaciones para la Actividad</small>
              <h5>{tarea.indicaciones}</h5>


              {
                // user.rol
              }



              {
                tarea.idboutique.conservacion_instrucciones_por_dia !== null && (
                    <React.Fragment>
                      
                        <small className="b">Instrucciones para hoy: </small>
                        <p className="nm">
                              { moment().day() === 1 && (tarea.idboutique.conservacion_instrucciones_por_dia.lunes||'Realizar actividades normales') }
                              { moment().day() === 2 && (tarea.idboutique.conservacion_instrucciones_por_dia.martes||'Realizar actividades normales') }
                              { moment().day() === 3 && (tarea.idboutique.conservacion_instrucciones_por_dia.miercoles||'Realizar actividades normales') }
                              { moment().day() === 4 && (tarea.idboutique.conservacion_instrucciones_por_dia.jueves||'Realizar actividades normales') }
                              { moment().day() === 5 && (tarea.idboutique.conservacion_instrucciones_por_dia.viernes||'Realizar actividades normales') }
                              { moment().day() === 6 && (tarea.idboutique.conservacion_instrucciones_por_dia.sabado||'Realizar actividades normales') }
                              { moment().day() === 7 && (tarea.idboutique.conservacion_instrucciones_por_dia.domingo||'Realizar actividades normales') }

                        </p>

                        {
                          // JSON.stringify(boutique.idboutique.conservacion_instrucciones_por_dia)
                        }

                    </React.Fragment>
                  )
              }


                              {
                                // tarea.idpresupuesto && (
                                //     <>

                                //       {
                                //         // JSON.stringify(repo.material_actividades)
                                //       }
                                //       {
                                //         // JSON.stringify(repo.idestatus_atencion)
                                //       }
                                //     </>
                                //   )
                              }




                          {  envio_server && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}



                          {  (!envio_server) && (

                              <>


                  {
                    // JSON.stringify(equipos_criticos)
                  }

                                  {
                                    [1,2,3,8].indexOf(tarea.idtipo_orden.id) > -1 && (
                                          <React.Fragment>

                                              

                                              {
                                                equipos_criticos.length > 0 && (
                                                      <Form.Check type="switch" id="custom-switch" checked={mostrar_solo_selccionadas} onChange={(e:any)=>{setMostrarSoloSeleccionada(e.target.checked)}} label="Ver todos los equipos" />
                                                  )
                                              }

                                              {
                                                equipos_criticos.length === 0 && (
                                                      <div className="alert alert-danger">
                                                          Esta boutique no tiene configurado equipos para atención. Es importante configurar los equipos para poder avanzar.
                                                      </div>
                                                  )
                                              }

                                            {
                                              // <p className="nm">CUANDO ES MANTENIMIENTO</p>
                                            }

                                            {
                                              // JSON.stringify(ficha_para_reporte)
                                            }


                                            {
                                              equipos_criticos.map((equipo_critico:any,index:number)=>

                                                    <React.Fragment key={index}>

                                                        {
                                                          (mostrar_solo_selccionadas) && (

                                                                <div className="card" style={{marginBottom:5}}>

                                                                    <div className="card-header d-flex align-items-center">
                                                                        <input type="checkbox" className="me-3" id={`index_check_${index}`} checked={equipo_critico.seleccionado} onChange={(e:any)=>{ let a = [...equipos_criticos]; a[index].seleccionado = e.target.checked; setEquiposCriticos(a) }} />
                                                                        <div>
                                                                            <label htmlFor={`index_check_${index}`}>
                                                                              <h5 className="card-title mb-0">{equipo_critico.marca} <small>{equipo_critico.modelo}</small></h5>
                                                                              <small className="card-subtitle text-muted">{ equipo_critico.detalles }</small>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                      equipo_critico.seleccionado && (
                                                                            <div className="list-group list-group-flush">
                                                                            {
                                                                              // JSON.stringify(equipo_critico)
                                                                            }
                                                                              {
                                                                                equipo_critico.instrucciones_mantenimiento.map((instruccion:any,index_instruccion:number)=>
                                                                                    <React.Fragment key={index_instruccion}>
                                                                                      <div className="list-group-item" >
                                                                                          <div className="form-check">
                                                                                              <input className="form-check-input" type="checkbox" id={`check1_${index}_${index_instruccion}`} checked={instruccion.seleccionado} onChange={(e:any)=>{ let a = [...equipos_criticos]; a[index].instrucciones_mantenimiento[index_instruccion].seleccionado = e.target.checked; setEquiposCriticos(a)   }} />
                                                                                              <label className="form-check-label" htmlFor={`check1_${index}_${index_instruccion}`}><small>{instruccion.instruccion}</small></label>
                                                                                          </div>
                                                                                      </div>
                                                                                    </React.Fragment>
                                                                                  )
                                                                              }


                                                                              <div className="mt-2">
                                                                                <label className="form-label">Comentarios</label>
                                                                                <textarea className="form-control" value={equipo_critico.comentarios}
                                                                                onChange={(e:any)=>{ let a = [...equipos_criticos]; a[index].comentarios = e.target.value; setEquiposCriticos(a)  }}
                                                                                onBlur={(e:any)=>{ let a = [...equipos_criticos]; a[index].comentarios = e.target.value.toUpperCase(); setEquiposCriticos(a)  }}
                                                                                style={{textTransform:'uppercase'}}
                                                                                rows={3}></textarea>
                                                                              </div>

                                                                            </div>
                                                                        )
                                                                    }


                                                                </div>


                                                            )
                                                        }


                                                        {
                                                          (!mostrar_solo_selccionadas && equipo_critico.seleccionado ) && (

                                                                <div className="card" style={{marginBottom:5}}>

                                                                    <div className="card-header d-flex align-items-center">
                                                                        <div>
                                                                            <label htmlFor={`index_check_${index}`}>
                                                                              <h5 className="card-title mb-0">{equipo_critico.marca} <small>{equipo_critico.modelo}</small></h5>
                                                                              <small className="card-subtitle text-muted">{ equipo_critico.detalles }</small>
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                    {
                                                                      equipo_critico.seleccionado && (
                                                                            <React.Fragment>
                                                                              <div className="list-group list-group-flush">
                                                                                {
                                                                                  equipo_critico.instrucciones_mantenimiento.map((instruccion:any,index_instruccion:number)=>
                                                                                      <React.Fragment key={index_instruccion}>
                                                                                          {
                                                                                            instruccion.seleccionado && (
                                                                                                <div className="list-group-item" >
                                                                                                    <p className="nm"> ✅ <small>{instruccion.instruccion}</small></p>
                                                                                                </div>
                                                                                              )
                                                                                          }
                                                                                      </React.Fragment>
                                                                                    )
                                                                                }
                                                                                {
                                                                                  equipo_critico.comentarios !== '' && (
                                                                                      <div className="list-group-item" >
                                                                                        <p className="nm"><small>{equipo_critico.comentarios}</small></p>
                                                                                      </div>
                                                                                    )
                                                                                }

                                                                              </div>
                                                                            </React.Fragment>
                                                                        )
                                                                    }


                                                                </div>


                                                            )
                                                        }



                                                    </React.Fragment>

                                                )
                                            }










                                          </React.Fragment>

                                      )
                                  }






                                  {
                                    [4,5,6,9].indexOf(tarea.idtipo_orden.id) > -1 && (
                                          <React.Fragment>

 

                                          </React.Fragment>

                                      )
                                  }



            {
              [1,2,3,4,5,6,8,9,10,11,99].indexOf(tarea.idtipo_orden.id) > -1 && (
                  <React.Fragment>


                      <div className="mt-4">


                        {
                          <p className="nm"><small>Actividades Realizadas</small></p>
                        }



                                {
                                  actividades.length === 0 && (
                                        <div className="alert alert-warning mt-2" role="alert">
                                          <p className="nm">No reportaste actividades para este Servicio</p>
                                        </div>
                                    )
                                }


                                {
                                  actividades.length > 0 && (
                                      <ol>
                                        {
                                          actividades.map((actividad:any, indice_actividad:number)=><li key={indice_actividad}>{actividad}</li>)
                                        }
                                      </ol>
                                    )
                                }

                        <div className="mb-3">
                            <button className="btn btn-sm btn-link" onClick={handleShow_AgregarActividadesRealizadas}>Agregar o Cambiar actividades Realizadas</button>
                        </div>

                        {
                          // JSON.stringify(actividades)
                        }


                      </div>




                      <div className="mt-4">


                          <p className="nm"><small>Materiales Utilizados en el Servicio</small></p>
 



                        {
                          // JSON.stringify(tarea.idpresupuesto)
                        }





                           {
                            material_actividades.length > 0 && (
                              <table className="table table-sm mt-2" style={{fontSize:12}}>
                                <thead>
                                  <tr>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Unidad</th>
                                    <th scope="col">Cantidad</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {
                                  [...material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                                  <tr key={ Math.random().toString(36).substr(2, 9) }> 
                                    <td>
                                      <p className="nm"><small>{material.descripcion}</small></p>
                                    </td>
                                    <td className="v" style={{width:120}}>
                                      <p className="nm"><small>{labelUnidadMedida(parseInt(material.idunidad_medida),tipo_unidad_medida)}</small></p>
                                    </td>
                                    <td className="v" style={{width:80}}>
                                      <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                    </td>  
                                  </tr>

                                    )
                                }


                                </tbody>
                              </table>
                              )
                          }

                          {
                            material_actividades.length === 0 && (
                                  <div className="alert alert-light">
                                    No has agregado materiales usados en esta actividad
                                  </div>
                              )
                          }

 

                        <div className="mb-3">
                            <button className="btn btn-sm btn-link" onClick={handleShow_AgregarMaterialesActividades}>Agregar o Cambiar materiales utilizados</button>
                        </div>

                      </div>
                      

                  </React.Fragment>
                )
            }







    {
      !tarea.idpresupuesto && (
          <>

              {
                // <p className="nm">CUANDO NO HAY PRESUPUESTO</p>
              }




                {
                  [1,2,3,8].indexOf(tarea.idtipo_orden.id) > -1 && (
                        <React.Fragment>

                          {
                            // cuando es mantnimieot
                          }


                          {
                            // JSON.stringify(check_actividades_realizadas)
                          }






                        </React.Fragment>

                    )
                }



        </>

     )}



























    {
      tarea.idpresupuesto && (
          <>

              {
                // <p className="nm">CUANDO HAY PRESUPUESTO</p>
              }


{
  //·························································
}




                {
                  [1,2,3,8].indexOf(tarea.idtipo_orden.id) > -1 && (
                        <React.Fragment>


                          {
                            tarea.material_actividades.length > 0 && (
                                <React.Fragment>

                                    <small>Actividades y Materiales Presupuestadas</small>

                                    <table className="table table-sm" style={{fontSize:12}}>
                                      <thead>
                                        <tr>
                                          <th scope="col">Tipo</th>
                                          <th scope="col">Descripción</th>
                                          <th scope="col">Unidad</th>
                                          <th scope="col">Cantidad</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          {
                                            [...tarea.material_actividades].sort(ordenarPorTipo).map((material:any,index_material:number)=>


                                            <tr key={index_material}>
                                              <td className="v" style={{width:120}}>
                                                <p className="nm">
                                                {
                                                  // JSON.stringify(material.idunidad_medida)
                                                }
                                                <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                                                </p>
                                              </td>
                                              <td>
                                                <p className="nm"><small>{material.descripcion}</small></p>
                                              </td>
                                              <td className="v" style={{width:120}}>
                                                <p className="nm"><small>{material.idunidad_medida ? material.idunidad_medida.unidad_medida : ''}</small></p>
                                              </td>
                                              <td className="v" style={{width:80}}>
                                                <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                              </td>
                                              
                                            </tr>

                                              )
                                          }
                                      </tbody>
                                    </table>


                                </React.Fragment>
                              )
                          }




                        </React.Fragment>

                    )
                }






                {
                  [4,5,6,9].indexOf(tarea.idtipo_orden.id) > -1 && (
                        <React.Fragment>

                          {
                            // cuando es conservacion
                          }

                          {
                            tarea.material_actividades.length > 0 && (
                                <React.Fragment>

                                    <small>Actividades y Materiales Presupuestadas</small>

                                    <table className="table table-sm" style={{fontSize:12}}>
                                      <thead>
                                        <tr>
                                          <th scope="col">Tipo</th>
                                          <th scope="col">Descripción</th>
                                          <th scope="col">Unidad</th>
                                          <th scope="col">Cantidad</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          {
                                            [...tarea.material_actividades].sort(ordenarPorTipo).map((material:any,index_material:number)=>


                                            <tr key={index_material}>
                                              <td className="v" style={{width:120}}>
                                                <p className="nm">
                                                {
                                                  // JSON.stringify(material.idunidad_medida)
                                                }
                                                <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                                                </p>
                                              </td>
                                              <td>
                                                <p className="nm"><small>{material.descripcion}</small></p>
                                              </td>
                                              <td className="v" style={{width:120}}>
                                                <p className="nm"><small>{(material.idunidad_medida.unidad_medida)}</small></p>
                                              </td>
                                              <td className="v" style={{width:80}}>
                                                <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                              </td>
                                              
                                            </tr>

                                              )
                                          }
                                      </tbody>
                                    </table>


                                </React.Fragment>
                              )
                          }




                        </React.Fragment>

                    )
                }






                {
                  [10,11].indexOf(tarea.idtipo_orden.id) > -1 && (
                        <React.Fragment>
                            <small>Lista de Artículos de Suministro</small>


                            <table className="table table-sm" style={{fontSize:12}}>
                              <thead>
                                <tr>
                                  <th scope="col">Tipo</th>
                                  <th scope="col">Descripción</th>
                                  <th scope="col">Unidad</th>
                                  <th scope="col">Cantidad</th>
                                </tr>
                              </thead>
                              <tbody>
                                  {
                                    [...tarea.material_actividades].sort(ordenarPorTipo).map((material:any,index_material:number)=>


                                    <tr key={index_material}>
                                      <td className="v" style={{width:120}}>
                                        <p className="nm">
                                        {
                                          // JSON.stringify(material.idunidad_medida)
                                        }
                                        <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                                        </p>
                                      </td>
                                      <td>
                                        <p className="nm"><small>{material.descripcion}</small></p>
                                      </td>
                                      <td className="v" style={{width:120}}>
                                        <p className="nm"><small>{labelUnidadMedida(material.idunidad_medida,tipo_unidad_medida)}</small></p>
                                      </td>
                                      <td className="v" style={{width:80}}>
                                        <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                                      </td>
                                      
                                    </tr>

                                      )
                                  }
                              </tbody>
                            </table>





                        </React.Fragment>

                    )
                }








{
  //·························································
}







          </>
        )
    }
























    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{
            detalles_boutique : "",
            detalles_atencion : "",
      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


{
  // JSON.stringify(user.rol)
}
            {
              // [7].indexOf(tarea.idtipo_orden.id) > -1 && (
              //     <React.Fragment>
              //       {
              //         // JSON.stringify(tareas_trabajadas.limpieza)
              //       }

              //         <Form.Group className="mb-3" controlId="">
              //           <Form.Label>Tareas Realizadas en el servicio</Form.Label>
              //                 {
              //                   tareas_trabajadas.limpieza.map((actividad:any,index:number)=>
              //                       <Form.Check type={'checkbox'} label={`${actividad.actividad}`} checked={actividad.realizada} onChange={(e:any)=>{ let a = {...tareas_trabajadas}; a.limpieza[index].realizada = e.target.checked; setTareasTrabajadas(a)  }} id={`disabled-default-${index}`} />
              //                     )
              //                 }
                              
              //         </Form.Group>

              //     </React.Fragment>
              //   )
            }

            {
  // const [ se_convirtio_lista, setSeConvitior ] = useState<boolean>(false)
  // const [ la_lista, setLaLista ] = useState<any>([])


              !se_convirtio_lista && (
                  <>
                      <Form.Group className="mb-3" controlId="detalles_atencion">
                        <Form.Label>Observaciones del { ["ROLE_LIMPIEZA"].indexOf(user.rol) > -1 ? 'Operador' : 'Técnico' }</Form.Label>
                        <Form.Control
                          as="textarea"
                          required
                          name="detalles_atencion"
                          rows={5}
                          value={values.detalles_atencion}
                          onChange={(e)=>{ setFieldValue('detalles_atencion', e.target.value ) }}
                          onBlur={(e)=>{ setFieldValue('detalles_atencion', e.target.value.toUpperCase() ) }}
                          style={{textTransform:'uppercase'}}
                          isInvalid={!!errors.detalles_atencion}
                        />
                        <Form.Control.Feedback type="invalid"> {<>{errors.detalles_atencion}</>} </Form.Control.Feedback>
                        {
                          // <small onClick={(e:any)=>{ setLaLista(values.detalles_atencion.split('\n')); setSeConvitio(true);  }}>Convertir a lista</small>
                        }
                        <small>Esta parte es llenada por el Técnico</small>
                      </Form.Group>
                  </>
                )

            }
            {
              se_convirtio_lista && (
                  <>
                    {
                      // JSON.stringify(la_lista)
                    }
                    <h6>Actividades Realizadas por el Técnico</h6>
                    <ol className="nm">
                      { la_lista.map((elemento:any, indice_elemento:number)=> <li key={indice_elemento}>{elemento}</li>)}
                    </ol>
                        <small onClick={(e:any)=>{ setSeConvitio(false);  }}>Editar lista</small>
                  </>
                )

            }



            <Form.Group className="mb-3" controlId="detalles_boutique">
              <Form.Label>Observaciones del Servicio</Form.Label>
              <Form.Control
                as="textarea"
                required
                name="detalles_boutique"
                rows={5}
                value={values.detalles_boutique}
                onChange={(e)=>{ setFieldValue('detalles_boutique', e.target.value ) }}
                onBlur={(e)=>{ setFieldValue('detalles_boutique', e.target.value.toUpperCase() ) }}
                style={{textTransform:'uppercase'}}
                isInvalid={!!errors.detalles_boutique}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.detalles_boutique}</>} </Form.Control.Feedback>
                        <small>Esta parte es llenado por el responsable de boutique</small>
            </Form.Group>


            <p className="text-center">
                        <Form.Label>Firma de la Boutique</Form.Label>
              <SignatureCanvas penColor='#000000' canvasProps={{width: 300, height: 300, className: 'sigCanvas'}} ref={(ref:any) => { sigCanvas(ref) }} onEnd={(a:any)=>{setTieneAlgo(true); }} />
            </p>

            <p>
              <small onClick={borrar} className="pointer" style={{color:'red'}}>Borrar <i className="fa fa-eraser"/></small>
            </p>


        </Form>
      )}
    </Formik>




                              </>

                           )}



{
  material_actividades.length == 0
}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" disabled={ desabilitarBoton() } form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>










 

  {
    show_AgregarActividadesRealizadas && (
      <AgregarActividadesRealizadas show={show_AgregarActividadesRealizadas} handleClose={handleClose_AgregarActividadesRealizadas} actividades={actividades} setActividades={setActividades} />
      )

  }


  {
    show_AgregarMaterialesActividades && (
      <AgregarMaterialesActividades show={show_AgregarMaterialesActividades} handleClose={handleClose_AgregarMaterialesActividades} actividad={tarea} material_actividades={material_actividades} setMaterialActividades={setMaterialActividades} />
      )

  }



    </>
  );
}


export default ModalReporteSalidaActividad;