import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal } from 'react-bootstrap';

import { toast } from 'react-toastify'


import Pagination from "../../services/Pagination";
import { Table, Form } from 'react-bootstrap'



import ModalBoutiquesPendienteEditar from './ModalBoutiquesPendienteEditar'
import ModalBoutiquesPendienteAgregar from './ModalBoutiquesPendienteAgregar'


import ModalBoutiquesPendienteEstatusCambio from './ModalBoutiquesPendienteEstatusCambio'


import { formatearFechaHora } from '../../services/funcionesPublicas'


import { SERVER_ } from '../../config'


const ModalBoutiquesPendientes = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, boutique } = params




  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 20
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ pendientes, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*sessionsPerPage);
      let response : any = await RequestService.getEndPoint(`boutique/lista_pendientes/${boutique.uuid}/${arreglo_estatus}/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }

  const [ arreglo_estatus, setEstatus ] = useState<any>([false,false,false])


  const todos = () =>{
    setEstatus([true,true,true])
  }
  const ninguno = () =>{
    setEstatus([false,false,false])
  }


      React.useEffect(() => {
        busqueda()
      }, []);





  const [ pendiente_seleccionado, setPendienteSeleccionado ] = useState<any>(null)
  const [ show_ModalBoutiquesPendienteEditar, setModalBoutiquesPendienteEditar ] = useState<boolean>(false)
  const handleShow_ModalBoutiquesPendienteEditar = (pendiente:any) => {
    setPendienteSeleccionado(pendiente)
    setModalBoutiquesPendienteEditar(true)
  }
  const handleClose_ModalBoutiquesPendienteEditar = () => {
        busqueda()
    setModalBoutiquesPendienteEditar(false)
  }



  const [ show_ModalBoutiquesPendienteAgregar, setModalBoutiquesPendienteAgregar ] = useState<boolean>(false)
  const handleShow_ModalBoutiquesPendienteAgregar = () => {
    setModalBoutiquesPendienteAgregar(true)
  }
  const handleClose_ModalBoutiquesPendienteAgregar = () => {
        busqueda()
    setModalBoutiquesPendienteAgregar(false)
  }





  const [ show_ModalBoutiquesPendienteEstatusCambio, setModalBoutiquesPendienteEstatusCambio ] = useState<boolean>(false)
  const handleShow_ModalBoutiquesPendienteEstatusCambio = (pendiente:any) => {
    setPendienteSeleccionado(pendiente)
    setModalBoutiquesPendienteEstatusCambio(true)
  }
  const handleClose_ModalBoutiquesPendienteEstatusCambio = () => {
        busqueda()
    setModalBoutiquesPendienteEstatusCambio(false)
  }


  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Pendientes de Boutique</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5>{boutique.nombre_boutique}</h5> 
          <p>{boutique.acronimo_folio}</p>



                <div className="row">
                
                    <div className="mb-2">
                        <label className="form-label">Búsqueda</label>
                        <div className="hstack gap-3">
                          <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                          <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                          <div className="vr"></div>
                          <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                        </div>
                    </div>
                    
                    <div>
                      <Form.Check inline name="group1" type="checkbox" label="PENDIENTE" checked={arreglo_estatus[0]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[0] = e.target.checked;  setEstatus(ne);}} id={`opciones_1`} />
                      <Form.Check inline name="group1" type="checkbox" label="ATENDIDO" checked={arreglo_estatus[1]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[1] = e.target.checked;  setEstatus(ne);}} id={`opciones_2`} />
                      <Form.Check inline name="group1" type="checkbox" label="CANCELADO" checked={arreglo_estatus[2]} onChange={(e)=>{ var ne = [...arreglo_estatus]; ne[2] = e.target.checked;  setEstatus(ne);}} id={`opciones_3`} />
                    </div>
                    
                  <ul className="list-inline">
                    <li className="list-inline-item"><small className="pointer" onClick={()=>{todos()}}>Todos</small> / <small className="pointer" onClick={()=>{ninguno()}}>Ninguno</small></li>
                    <li className="list-inline-item"><small className="pointer" onClick={()=>{handleShow_ModalBoutiquesPendienteAgregar()}}>Nuevo Pendiente</small></li>
                    <li className="list-inline-item"><small className="pointer" onClick={()=>{window.open(`${SERVER_}boutique/pendientes/reporte_excel/${boutique.uuid}`)}}>Descarga</small></li>
                  </ul>

                </div>







                          {  (!cargando_) && (

                              <>

                                   {
                                     pendientes.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              pendientes.map( (pendiente:any,indice_pendiente:number) =>
                                                  <tr key={indice_pendiente} style={{fontSize:12}}>
                                                    <td className="v text-left" >
                                                        <p className="nm"><small><b>{pendiente.folio}</b></small></p>
                                                        <p className="nm text-justify"><small>{pendiente.descripcion}</small></p>
                                                        <small><b>{formatearFechaHora(pendiente.fecha_creacion)} HRS</b></small>
                                                    </td>
                                                    <td className={`v text-center ${pendiente.estado}`} style={{"width":"140px"}}>
                                                        <p className="nm"><small>{pendiente.estado}</small></p>
                                                        {
                                                          pendiente.idservicio && (
                                                              <>
                                                                <b>{pendiente.idservicio?.folio}</b>
                                                              </>
                                                            )
                                                        }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      {
                                                        ["PENDIENTE"].indexOf(pendiente.estado) > -1 && (<button onClick={(e:any)=>{handleShow_ModalBoutiquesPendienteEstatusCambio(pendiente)}} className="btn btn-link btn-xs">🚧</button>)
                                                      }
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                      
                                                      {
                                                        ["PENDIENTE"].indexOf(pendiente.estado) > -1 && (<button onClick={(e:any)=>{handleShow_ModalBoutiquesPendienteEditar(pendiente)}} className="btn btn-warning btn-xs"><i className="fa fa-edit"/></button>)
                                                      }
                                                    </td>

                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}






  {
    show_ModalBoutiquesPendienteEditar && (
      <ModalBoutiquesPendienteEditar show={show_ModalBoutiquesPendienteEditar} handleClose={handleClose_ModalBoutiquesPendienteEditar} pendiente={pendiente_seleccionado} />
      )

  }

  {
    show_ModalBoutiquesPendienteAgregar && (
      <ModalBoutiquesPendienteAgregar show={show_ModalBoutiquesPendienteAgregar} handleClose={handleClose_ModalBoutiquesPendienteAgregar} boutique={boutique} />
      )

  }



  {
    show_ModalBoutiquesPendienteEstatusCambio && (
      <ModalBoutiquesPendienteEstatusCambio show={show_ModalBoutiquesPendienteEstatusCambio} handleClose={handleClose_ModalBoutiquesPendienteEstatusCambio} pendiente={pendiente_seleccionado} />
      )

  }

          {
            // JSON.stringify(boutique)
          }
        </Modal.Body>
      </Modal>
    </>
  );
}


export default ModalBoutiquesPendientes;