import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Tabs, Tab } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import { diferenciaHoras, obtenerDiaSemana, tipoActividadLabel, formatearNumero, ordenarPorTipo } from '../../services/funcionesPublicas'

import moment from 'moment'

import Dialog from '../../services/Dialogs'

const ModalReporteDeActividad = ( params : any ) => {

  const user = AuthService.getCurrentUser()

  const { show, handleClose, handleCloseRefresh, tarea } = params

  const dialog = new Dialog();

  const [ reporte_tareas, setReporte_tareas ] = useState<any>({

  })
  const [ envio_server, setSeEnvio ] = useState<boolean>(false)
  const cargarReporte_tareas = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`operarios/reporte_tarea/${tarea.uuid}`)
      setReporte_tareas(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    // cargarReporte_tareas()
  }, []);



  const [ detalles_visita, setDetalles_visita ] = useState<any>(null)
  const cargarDetalles_visita = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`ficha_servicio/${tarea.uuid_servicio}`)
      setDetalles_visita(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar')
    }
  }


  React.useEffect(() => {
    cargarDetalles_visita()
    // cargarTipo_unidad_medida()
  }, []);



const empezarFormulario = async (data_form:any,event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `operarios/empezar_reporte/${tarea.uuid}` , data_form )

        if(res_){
          toast.success('Consulta_correcta')
          setTimeout(()=>{
            cargarReporte_tareas()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          dias_extras_solicitud : yup.string().when('requiere_dias_extras',
                                            (requiere_dias_extras,schema)=>{
                                                console.log('value business : ',requiere_dias_extras);
                                                if(!requiere_dias_extras){
                                                   return schema;
                                                }else{
                                                   return schema.required(mensajes_validacion_generico);
                                                }
                                            }),

 //.required(mensajes_validacion_generico),
          numero_dias_solicitados : yup.number().when('requiere_dias_extras',
                                            (requiere_dias_extras,schema)=>{
                                                console.log('value business : ',requiere_dias_extras);
                                                if(!requiere_dias_extras){
                                                   return schema;
                                                }else{
                                                   return schema.required(mensajes_validacion_generico);
                                                }
                                            }),
          requiere_dias_extras : yup.boolean(),

  });



  const schemaReporte = yup.object().shape({
          campo1 : yup.string().required(mensajes_validacion_generico),
  });


const terminarReporte = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: `<small>Al finalizar el reporte no podrás subir imagenes o editar la información de este.</small>`
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `serivico/terminar/${tarea.uuid}` , { detalles_atencion : tarea_.detalles_atencion })

        if(res_){
          toast.success('El Reporte de la Visita concluyó')
          setTimeout(()=>{
            handleCloseRefresh()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}




  const [ suma_subtotal, setSuma ] = useState<number>(0)

  const sumarSubtotales = () =>{
    let s = 0
    // console.log(presupuesto.material_actividades)
    for(let actividad of tarea.material_actividades){
      s += ( actividad.costo * actividad.cantidad )
    }
    setSuma(s)

  }




  const [ actividades_reporte, setActividadesReporte ] = useState<any>( tarea.actividades_reporte )



const actualizarReporte = async (event:any) =>{

      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.update( `actualizar_ficha_reporte/${tarea.uuid_servicio}` , { actividades_realizadas : tarea_.actividades_realizadas, detalles_atencion : tarea_.detalles_atencion })

        if(res_){
          toast.success('Se guardaron los cambios en el reporte')
          // setTimeout(()=>{
          //   cargar()
          // },0)
        }
        else{
          toast.error('No pudimos contactar con el servidor',{ icon : '📵' })
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}


  const [ tarea_, setTarea ] = useState<any>({...tarea})


  return (
    <>
      <Modal show={show} size={'lg'} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Reporte de Actividad</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            {
              // JSON.stringify(tarea.idtipo_orden)
            }

          <div className="row">
            <div className="col-sm-6">
              <small>Folio</small>
              <h5>{tarea.folio}</h5>
            </div>
            <div className="col-sm-6">
              <small>Fecha</small>
              <h6 className="nm"><small>{obtenerDiaSemana(tarea.fecha_programada).toUpperCase()}</small>  {moment(tarea.fecha_programada).format('DD/MMM/YYYY').toUpperCase().replace('.','')}</h6>
              <small></small>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <small>Boutique</small>
              <h5>{tarea.idboutique.nombre_boutique}</h5>
              <p>{tarea.idboutique.direccion}</p>
            </div>
          </div>

          <div className="row mb-2">
            <div className={`col-sm-6 estatus_atencion_${tarea.idestatus_atencion.id}`}>
              <small>Estatus</small>
              <h5>{tarea.idestatus_atencion.estatus}</h5>
            </div>
            <div className={`col-sm-6 tipo_orden_${tarea.idtipo_orden.id}`}>
              <small>Tipo de Servicio</small>
              <h5>{tarea.idtipo_orden.tipo_orden}</h5>
            </div>
          </div>


              <small>Indicaciones para la Actividad</small>
              <h5>{tarea.indicaciones}</h5>



                          {
                            ([4,7].indexOf(tarea.idestatus_atencion.id) > -1 && detalles_visita ) && (
                                <React.Fragment>

                                  {
                                    detalles_visita.actividades_reporte && (
                                        <React.Fragment>



                                          {
                                            detalles_visita.actividades_reporte.length > 0 && (
                                              <React.Fragment>
                                                <small>Actividades Realizadas y Reportadas en la salida</small>
                                                  <ol>
                                                    {
                                                      detalles_visita.actividades_reporte.map((actividad:any,indice_actividad:number)=><li key={indice_actividad}>{actividad}</li>)
                                                    }
                                                  </ol>
                                              </React.Fragment>
                                              )
                                          }

                                        </React.Fragment>
                                      )
                                  }
                                  


                                </React.Fragment>
                                  )
                          }




            <div className="mb-3">
              <label className="form-label">Detalles del Servicio</label>
              <textarea className="form-control" rows={5} value={tarea_.detalles_atencion||''} 
                  style={{textTransform:'uppercase'}}
                  onChange={(e:any)=>{ let t = {...tarea_}; t.detalles_atencion= e.target.value; setTarea(t); }}
                  onBlur={(e:any)=>{ let t = {...tarea_}; t.detalles_atencion= e.target.value; setTarea(t); }}
                  ></textarea>
            </div>


{
  // JSON.stringify(tarea.actividades_reporte)
}





                {
                  [1,2,3,8].indexOf(tarea.idtipo_orden.id) > -1 && (
                      <React.Fragment>

                      {
                        tarea_.actividades_realizadas && (
                            <React.Fragment>

                          {
                            tarea_.actividades_realizadas.map((equipo:any,index_actividad:number)=>

                              <React.Fragment key={index_actividad}>



                                    <div className="card bg-light">
                                      <div className="card-header">
                                            <div className="row">
                                              <div className="col-4">
                                                <small>Marca</small>
                                                <p className="nm"><b>{equipo.marca}</b></p>
                                              </div>
                                              <div className="col-4">
                                                <small>Modelo</small>
                                                <p className="nm"><b>{equipo.modelo}</b></p>
                                              </div>
                                              <div className="col-4">
                                                <small>Tipo</small>
                                                <p className="nm"><b>{equipo.tipo_equipo}</b></p>
                                              </div>
                                            </div>


                                                <small>Detalles del Equipo</small>
                                                <p className="nm"><b>{equipo.detalles}</b></p>

                                      </div>

                                      <div className="card-body">



                                          <div className="mb-3">
                                            <label className="form-label">Detalles en el equipo</label>
                                            <textarea className="form-control" rows={3} value={equipo.detalles_equipo||''} 
                                                                        onChange={(e:any)=>{
                                                                                            let t = {...tarea_}
                                                                                            t.actividades_realizadas[index_actividad].detalles_equipo= e.target.value.toUpperCase()
                                                                                            setTarea(t)

                                                                                          }}  ></textarea>
                                          </div>


                                      {
                                        // JSON.stringify(equipo.tipo_equipo)
                                      }


                                              <Tabs
                                                defaultActiveKey="formulario_reporte"
                                                id="fill-tab-example"
                                                className="mb-3"
                                                fill
                                              >
                                                <Tab eventKey="formulario_reporte" title="Bitácora de Reporte">



                                                    <p>Reporte de Equipos Críticos Atendidos en la visita:</p>

                                                    <ul className="list-group list-group-flush">
                                                      {
                                                        equipo.check_list_bitacoras.map((check:any,index_check:number)=>
                                                            <React.Fragment key={index_check}>


                                                              <li className="list-group-item">
                                                              {
                                                                // JSON.stringify(check)
                                                              }


                                                                {
                                                                  check.tipo === 0 && (
                                                                      <React.Fragment>

                                                                          <div className="row align-items-center">
                                                                            <div className="col-sm-6">
                                                                                <label className="form-label nm">{check.descripcion}</label>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                    {
                                                                                      check.opciones.map((opt:any,index_opt:number)=>
                                                                                            <React.Fragment key={index_opt}>
                                                                                                <div className="form-check form-check-inline">
                                                                                                    <input className="form-check-input" type="radio" name={`opt_${index_actividad}_${index_check}_${index_opt}`} id={`opt_${index_actividad}_${index_check}_${index_opt}`} checked={opt===check.detalles} onChange={(e:any)=>{
                                                                                                                                                                        let t = {...tarea_}
                                                                                                                                                                        t.actividades_realizadas[index_actividad].check_list_bitacoras[index_check].detalles = opt
                                                                                                                                                                        setTarea(t)

                                                                                                                                                                      }}   />
                                                                                                    <label className="form-check-label delgadita" htmlFor={`opt_${index_actividad}_${index_check}_${index_opt}`}>{opt}</label>
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    }
                                                                            </div>
                                                                          </div>


                                                                      </React.Fragment>
                                                                    )
                                                                }

                                                                {
                                                                  check.tipo === 1 && (
                                                                      <React.Fragment>



                                                                          <div className="row  align-items-center">
                                                                            <div className="col-sm-6">
                                                                              <label className="form-label nm">{check.descripcion}</label>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                              <input type="text" className="form-control " value={check.detalles} onChange={(e:any)=>{
                                                                                                                                                                        let t = {...tarea_}
                                                                                                                                                                        t.actividades_realizadas[index_actividad].check_list_bitacoras[index_check].detalles = e.target.value.toUpperCase()
                                                                                                                                                                        setTarea(t)

                                                                                                                                                                      }} />
                                                                            </div>
                                                                          </div>

                                                                      </React.Fragment>
                                                                    )
                                                                }


                                                              </li>
                                                            </React.Fragment>
                                                          )
                                                      }
                                                    </ul>




                                                      <div className="row">
                                                        <div className="col-12">
                                                          <button type="button" onClick={actualizarReporte} className="btn btn-primary">Guardar Reporte <i className="fa fa-save"/></button>
                                                        </div>
                                                      </div>



                                                </Tab>
                                                <Tab eventKey="actividades_salida" title="Actividades Realizadas">


                                                    <p>Listado de actividades notificadas en la salida:</p>


                                                    <ul className="list-group list-group-flush mb-3">
                                                      {
                                                        equipo.instrucciones_mantenimiento.map((actividad:any, index_act:number)=><li key={index_act} className="list-group-item">{ actividad.seleccionado ? '✅' : '⚠️' } <small>{actividad.instruccion}</small></li>)
                                                      }
                                                      <li className="list-group-item">{equipo.comentarios}</li>
                                                    </ul>


                                                </Tab>
                                              </Tabs>




                                      </div>


                                    </div>
                                  {
                                    // tarea.detalles
                                  }


                                    {
                                      // JSON.stringify(equipo.check_list_bitacoras)
                                    }




                                </React.Fragment>

                              )
                          }


                            </React.Fragment>
                          )
                      }


                      </React.Fragment>
                    )
                }







{
  [4,5,6,9].indexOf(tarea.idtipo_orden.id) > -1 && (
      <React.Fragment>
        {
          // JSON.stringify(tarea)
        }
      </React.Fragment>
    )
}














            {
              [10].indexOf(tarea.idtipo_orden.id) > -1 && (
                  <React.Fragment>
                    
                  
                    {
                      // JSON.stringify(tarea)
                    }


                        <table className="table table-sm" style={{fontSize:12}}>
                          <thead>
                            <tr>
                              <th scope="col">Tipo</th>
                              <th scope="col">Descripción</th>
                              <th scope="col">Unidad</th>
                              <th scope="col">Cantidad</th>
                            </tr>
                          </thead>
                          <tbody>
                          {
                            [...tarea.material_actividades].sort(ordenarPorTipo).map((material:any,index:number)=>


                            <tr>
                              <td className="v" style={{width:120}}>
                                <p className="nm">
                                {
                                  // JSON.stringify(material.idunidad_medida)
                                }
                                <small><b>{tipoActividadLabel(material.tipo)}</b></small>
                                </p>
                              </td>
                              <td>
                                <p className="nm"><small>{material.descripcion}</small></p>
                              </td>
                              <td className="v" style={{width:120}}>
                                {material.idunidad_medida&&(<p className="nm"><small>{material.idunidad_medida.unidad_medida}</small></p>)}
                              </td>
                              <td className="v" style={{width:80}}>
                                <p className="nm text-right">{formatearNumero(parseFloat(material.cantidad)||0)}</p>
                              </td>
                            </tr>

                              )
                          }




                          </tbody>
                        </table>


                  </React.Fragment>
                )
            }


            {
              // [10].indexOf(tarea.idtipo_orden.id) > -1 && (
              //     <React.Fragment>
                    

              //       {
              //         // JSON.stringify(tarea)
              //       }

              //     </React.Fragment>
              //   )
            }


        </Modal.Body>



            {
              // [10].indexOf(tarea.idtipo_orden.id) === -1 && (
              //     <React.Fragment>
                    

              //             <Modal.Footer>
              //               <Button variant="secondary" onClick={handleClose}>
              //                 Cerrar
              //               </Button>
              //               <Button variant="info" form='my-form' type="submit">Comenzar Reporte</Button>
              //             </Modal.Footer>

                    
              //     </React.Fragment>
              //   )
            }



                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              Cerrar
                            </Button>
                            <Button variant="info" form='my-form' onClick={terminarReporte} type="button">Finalizar Reporte</Button>
                          </Modal.Footer>



            {
              // [10].indexOf(tarea.idtipo_orden.id) > -1 && (
              //     <React.Fragment>
                    

              //       {
              //         // reporte_tareas.requiere_dias_extras === null && (

              //         //     <Modal.Footer>
              //         //       <Button variant="secondary" onClick={handleClose}>
              //         //         Cerrar
              //         //       </Button>
              //         //       <Button variant="info" form='my-form' type="submit">Comenzar Reporte</Button>
              //         //     </Modal.Footer>

              //         //   )
              //       }


              //       {
              //         // reporte_tareas.requiere_dias_extras !== null && (
                        
              //             <Modal.Footer>
              //               <Button variant="secondary" onClick={handleClose}>
              //                 Cerrar
              //               </Button>
              //               <Button variant="success" onClick={(e:any)=>{terminarReporte(e)}} type="button">Terminar Reporte</Button>
              //             </Modal.Footer>

              //           // )
              //       }



                    
              //     </React.Fragment>
              //   )
            }









      </Modal>
    </>
  );
}


export default ModalReporteDeActividad;